import {
  MISC_INIT_DATA,
  MISC_CONNECTION_ENABLED,
  MISC_CONNECTION_DISABLED,
} from '@/store/action_types/misc';

// Refactoring position calculate for vue-progressbar
// See more: https://bwaffiliates.atlassian.net/secure/RapidBoard.jspa?rapidView=26&projectKey=ACTECH&modal=detail&selectedIssue=ACTECH-3

export default (app) => {
  let requestsCount = 0;
  const errorsPull = [];

  app.$eventBus.$on('request-start', () => {
    if (requestsCount === 0) {
      app.$Progress.start();
    }
    requestsCount += 1;
  });

  app.$eventBus.$on('request-fail', (error) => {
    app.$Progress.fail();
    requestsCount -= 1;
    if (requestsCount > 0) {
      app.$Progress.start();
    }
    errorsPull.push(error);
  });

  app.$eventBus.$on('response-error', (error) => {
    app.$Progress.fail();
    requestsCount -= 1;
    if (requestsCount > 0) {
      app.$Progress.start();
    }
    errorsPull.push(error);
  });

  app.$eventBus.$on('response-success', () => {
    requestsCount -= 1;
    if (requestsCount === 0) {
      app.$Progress.finish();
    }
  });

  app.$eventBus.$on('connection-enabled', () => {
    if (!app.$store.getters['misc/countersEnabled']) {
      app.$store.dispatch(`misc/${MISC_INIT_DATA}`);
    }
    app.$store.commit(`misc/${MISC_CONNECTION_ENABLED}`);
  });

  app.$eventBus.$on('connection-lost', () => {
    app.$store.commit(`misc/${MISC_CONNECTION_DISABLED}`);
  });
};
