<template>
  <div
    v-loading="isDataLoading"
    class="affiliate-kpi-bonus"
  >
    <affiliate-kpi-bonus-popup
      v-show="isPopupOpen"
      :is-open="isPopupOpen"
      :user-id="userId"
      :data="currentBonus"
      @close="onClosePopup"
    />
    <ui-confirm
      ref="confirm"
      :width="480"
      type="warning"
      :action-name="`${this.$t(`affiliates.affiliateKpiBonuses.popup.delete_button`)}`"
      action="Decline"
      action-fa-icon="trash-alt"
      @save="deleteBonus"
    >
      <div
        slot="title"
        v-html="confirm.title"
      />
      <div class="body__wrap">
        <i class="warning fas fa-exclamation-triangle" />
        <div
          class="body__msg"
          v-html="confirm.msg"
        />
      </div>
    </ui-confirm>
    <div class="wrapper">
      <div class="controls">
        <div class="section">
          <span class="title" :class="{ general: !userId }">{{ $t(`affiliates.cardView.nav.kpi-bonus`) }}</span>
          <affiliates-kpi-bonus-filter
            v-if="!userId"
            ref="filter"
            v-model="filters"
            class="btn ui-filter-new"
            :props-errors.sync="errors"
            @submit="applyFilterKpiBonuses"
          />
          <el-select
            v-else
            v-model="kpiType"
            :placeholder="$t('affiliates.affiliateKpiBonuses.popup.kpiType')"
            filterable
            clearable
            class="select"
          >
            <el-option
              v-for="(item) in kpiTypeOptions"
              :key="item.key"
              :label="item.text"
              :value="item.key"
            />
          </el-select>
        </div>
        <div class="section">
          <div class="pagination">
            <ui-pagination
              :page="page"
              :page-size="limit"
              :count="count"
              show-size-select
              @page-change="pageChange"
            />
          </div>
          <ui-button
            v-if="userId && isEditKpiBonus"
            icon="plus"
            lib="fa"
            filled
            substyle="fas"
            color="green"
            class="btn pin-button"
            @click="openKpiBonusPopup()"
          >
            {{ $t(`affiliates.affiliateKpiBonuses.add_kpi`) }}
          </ui-button>
        </div>
      </div>
      <div class="info">
        <ui-table
          v-loading="isDataLoading"
          :fields="fields"
          :data="bonuses"
          :rows-count="limit"
          :sort="{
            prop: sortProp,
            order: sortOrder,
          }"
          lazy-loading
          class="table kpi-table"
          i18n-path="affiliates.affiliateKpiBonuses"
          element-loading-custom-class="data-loader-spinner"
          @sort="handleSort"
        >
          <template
            v-if="isEditKpiBonus"
            slot="append"
          >
            <el-table-column
              :width="74"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <div class="row-value actions">
                  <ActionIcon :tooltip="$t('affiliates.affiliateKpiBonuses.edit_kpi_bonus')">
                    <div
                      class="action-ico"
                      :class="{ disabled: ['canceled', 'completed', 'failed'].includes(scope.row.status) }"
                      @click="openKpiBonusPopup(scope.row)"
                    >
                      <ui-icon
                        name="pen"
                        :color="$theme.accentColor"
                        lib="fa"
                      />
                    </div>
                  </ActionIcon>
                  <ActionIcon :tooltip="$t('affiliates.affiliateKpiBonuses.delete_kpi_bonus')">
                    <div
                      :class="{ disabled: ['canceled', 'completed', 'failed'].includes(scope.row.status) }"
                      class="action-ico"
                      @click="checkSensitiveAction('handleDeleteBonus', scope.row)"
                    >
                      <ui-icon
                        name="times"
                        :color="$theme.dangerColor"
                        lib="fa"
                      />
                    </div>
                  </ActionIcon>
                </div>
              </template>
            </el-table-column>
          </template>
        </ui-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral';
import AffiliateKpiBonusPopup from '@/components/Popups/AffiliateKpiBonusPopup.vue';
import AffiliatesKpiBonusFilter from './components/AffiliatesKpiBonusFilter.vue';
import { pageSizeMixin, resolvePageSize } from '@/service/pageSize';
import updateUrl from '@/service/updateUrl';

const sizes = {
  1080: 20,
  1440: 30,
};

const viewName = 'Affiliates/kpi-bonus/limit';
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});
export default {
  name: 'AffiliateKpiBonuses',
  components: {
    AffiliateKpiBonusPopup,
    AffiliatesKpiBonusFilter,
  },
  mixins: [pageSizeMixin],
  props: {
    permissions: {
      type: Object,
      default: () => ({

      }),
    },
  },
  data() {
    return {
      kpiType: '',
      viewName,
      isPopupOpen: false,
      isDataLoading: false,
      sortProp: 'created_at',
      sortOrder: 'desc',
      count: 0,
      page: 1,
      limit: pageSize,
      errors: {},
      filters: {},

      bonuses: [],
      currentBonus: {},
      confirm: {
        title: '',
        msg: '',
      },
    };
  },
  computed: {
    kpiTypeOptions() {
      return this.$store.state.affiliate.kpiBonusTypes.map(el => ({
        key: el.type,
        text: el.title,
      }));
    },
    kpiTypeOptionMap() {
      return this.$store.state.affiliate.kpiBonusTypes.reduce((acc, el) => {
        acc[el.type] = el.title;
        return acc;
      }, {});
    },
    fields() {
      return [
        {
          name: 'incrementing_id',
          align: 'left',
          headerAlign: 'left',
          width: '40',
          class: 'text',
        },
        {
          name: 'status',
          width: '90',
          class: 'text',
          computedClass: val => (this.statusMap[val] || {}).class,
          renderValue: (val) => {
            const { label = '' } = this.statusMap[val] || {};
            return label;
          },
        },
        (!this.userId && {
          name: 'affiliate_email',
          align: 'left',
          headerAlign: 'left',
          width: '192',
          class: 'text',
          link: this.getAffiliateLink,
        }),
        {
          name: 'type',
          width: '192',
          class: 'text',
          renderValue: (val => this.kpiTypeOptionMap[val]),
        },
        {
          name: 'goal',
          headerAlign: 'right',
          align: 'right',
          class: 'text',
          width: '100',
          format: 'number',
        },
        {
          name: 'kpi_rate',
          headerAlign: 'right',
          align: 'right',
          class: 'text',
          width: '110',
          renderValue: (val, row) => {
            if (row.bonus_payout_rate) {
              return `${row.bonus_payout_rate}%`;
            }

            return `${this.getCurrencyLabel(row.bonus_payout_currency_code)}${numeral(row.bonus_payout_sum).format('0,0.00')}`;
          },
        },
        {
          name: 'progress',
          headerAlign: 'right',
          align: 'right',
          class: 'text',
          width: '100',
          renderValue: val => `${numeral(val).format('0,0')}%`,
        },
        {
          name: 'period',
          sortable: 'custom',
          class: 'text',
          minWidth: '164',
          renderValue: (_, row) => `${this.formatDate(row.period_start)} &ndash; ${this.formatDate(row.period_end)}`,
        },
        {
          name: 'created_at',
          width: '134',
          align: 'right',
          class: 'text',
          headerAlign: 'right',
          sortable: 'custom',
          format: 'date-time',
        },
      ].filter(el => !!el);
    },
    statusMap() {
      return {
        in_progress: {
          label: this.$t('affiliates.affiliateKpiBonuses.statuses.in_progress'),
          class: 'row-value--in_progress',
        },
        pending: {
          label: this.$t('affiliates.affiliateKpiBonuses.statuses.pending'),
        },
        failed: {
          label: this.$t('affiliates.affiliateKpiBonuses.statuses.failed'),
          class: 'row-value--inactive',
        },
        completed: {
          label: this.$t('affiliates.affiliateKpiBonuses.statuses.completed'),
          class: 'row-value--active',
        },
        canceled: {
          label: this.$t('affiliates.affiliateKpiBonuses.statuses.canceled'),
          class: 'row-value--inactive',
        },
      };
    },
    accountStatus() {
      return this.$route.params.account_status;
    },
    isEditKpiBonus() {
      return !['blocked', 'pending', 'rejected'].includes(this.accountStatus) && (this.currentAcl.affiliates_kpi_bonuses_edit === 'allow' || this.adminAcl.is_superuser);
    },

    userId: {
      get() {
        return this.$route.params.id;
      },
    },
    ...mapGetters({
      currentAcl: 'auth/currentAcl',
      adminAcl: 'auth/adminAcl',
      currencies: 'misc/currencies',
    }),
  },
  watch: {
    '$i18n.locale': {
      immediate: true,
      async handler() {
        await this.$store.dispatch('affiliate/kpiBonusTypes');
      },
    },
    kpiType() {
      this.getKpiBonuses();
    },
    sortProp(newVal) {
      this.$ls.set('Affiliates/kpi-bonus/sort_column', newVal || 'created_at');
    },
    sortOrder(newVal) {
      this.$ls.set('Affiliates/kpi-bonus/sort_dir', newVal || 'desc');
    },
  },
  created() {
    const _urlData = updateUrl.getParseParams(this.$route.hash);

    if (!this.$_.isEmpty(_urlData)) {
      this.limit = Number(this.$_.get(_urlData, ['limit'])) || pageSize;
      this.page = Number(this.$_.get(_urlData, ['page'])) || 1;
      this.sortProp = this.$_.get(_urlData, ['sort_column']) || 'created_at';
      this.sortOrder = this.$_.get(_urlData, ['sort_dir']) || 'desc';
    } else {
      this.limit = +this.$ls.get('Affiliates/kpi-bonus/limit') || pageSize;
      this.sortProp = this.$ls.get('Affiliates/kpi-bonus/sort_column') ? this.$ls.get('Affiliates/kpi-bonus/sort_column') : 'created_at';
      this.sortOrder = this.$ls.get('Affiliates/kpi-bonus/sort_dir') ? this.$ls.get('Affiliates/kpi-bonus/sort_dir') : 'desc';
    }

    this.$eventBus.$on('handleDeleteBonus', this.handleDeleteBonus);
  },
  beforeDestroy() {
    this.$eventBus.$off('handleDeleteBonus', this.handleDeleteBonus);
  },
  mounted() {
    this.getKpiBonuses();
  },
  methods: {
    getCurrencyLabel(code) {
      const currency = this.currencies.find((el => el.code === code));

      return currency?.symbol || `${code} `;
    },
    applyFilterKpiBonuses(data) {
      this.filters = data;
      this.getKpiBonuses();
    },
    getAffiliateLink(row) {
      return `/affiliates/${row.affiliate_id}/info`;
    },
    formatDate(value) {
      return this.$moment(value).locale(this.$i18n.locale).format('DD MMM YYYY');
    },
    _query() {
      return {
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
        limit: this.limit,
        offset: (this.limit * this.page) - this.limit,
        type: this.kpiType && this.kpiType,
        ...(this.userId && { affiliate_id: this.userId }),
        ...this.filters,
      };
    },
    checkSensitiveAction(action, row) {
      this.$eventBus.$emit('checkSensitiveAction', action, row);
    },

    handleDeleteBonus(row) {
      if (['canceled', 'completed', 'failed'].includes(row.status)) return;

      this.currentBonus = row;
      this.confirm.title = this.$t('affiliates.affiliateKpiBonuses.popup.delete_title');
      this.confirm.msg = this.$t('affiliates.affiliateKpiBonuses.popup.delete_text');
      this.$refs.confirm.open();
    },
    deleteBonus() {
      this.isDataLoading = true;
      this.$api.cancelAffiliateKpiBonuses(this.currentBonus.id)
        .then(() => {
          this.getKpiBonuses();
          this.$refs.confirm.close();
        })
        .catch(() => {
          this.isDataLoading = false;
        });
    },
    openKpiBonusPopup(row) {
      if (row && ['canceled', 'completed', 'failed'].includes(row.status)) return;

      this.isPopupOpen = true;
      this.currentBonus = row;
    },
    onClosePopup(v) {
      this.isPopupOpen = false;
      if (v === 'reload') {
        this.getKpiBonuses();
      }
    },
    getKpiBonuses() {
      this.errors = {};
      this._completedFilterUrl();
      const _filter = this._query();

      this.$api
        .getAffiliateKpiBonuses(_filter)
        .then((response) => {
          this.bonuses = this.$_.cloneDeep(response.data.payload);
          this.count = this.$_.cloneDeep(response.data.misc.count);
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },
    _completedFilterUrl() {
      const _dataFilters = {
        limit: this.limit,
        page: this.page,
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
        affiliate_id: this.userId,
      };
      updateUrl.updateGetParams(_dataFilters);
    },
    handleSort({ prop, order }) {
      if (!this.fields.some(item => item.name === prop && item.sortable === 'custom')) {
        return;
      }

      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }
      this.$nextTick(() => {
        this.getKpiBonuses();
      });
    },
    pageChange(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;
        this.getKpiBonuses();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .affiliate-kpi-bonus {
    .wrapper {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      min-width: 1140px;
      width: 90%;
    }

    /deep/ {
      .el-input__suffix-inner {
        display: flex;
        height: 100%;
      }

      .el-select.select .el-input .el-select__caret:not(.el-icon-circle-close) {
        color: #7d7d7d;
        font-family: 'Font Awesome 6 Pro' !important;
        &::before {
          position: relative;
          top: -1px;
          content: '\f106';
        }
        &.is-reverse::before {
          top: -3px;
        }
      }
    }
  }
  .action-ico {
    &.disabled {
      opacity: 0.5;
    }
  }
</style>
