import requestFinish from '@/helpers/check-all-request-fInish';
import app from '@/main';

import {
  AUTH_LOGOUT,
  AUTH_2FA_RESET,
  CHANGE_ENOUGH_PERMISSIONS,
} from '@/store/action_types/auth';

/**
 * метод игнорирует ошибки,
 * и не выводит notification error
 */
const ignoreErrorNotifyCountRequest = (error) => {
  const splitUrl = error.config.url.split('/');

  if (splitUrl[splitUrl.length - 1] === 'count') {
    return true;
  }

  return false;
};

/**
 * получает message от первой ошибки в списке ошибок
 */
const getFirstErrorFromList = errors => errors?.[
  Object.keys(errors)[0]
]?.[0]?.message;

export default {
  requestSuccessInterceptor(config) {
    if (config.url === '/dashboards/stats') {
      requestFinish(config.url, 'set');
    }

    app.$eventBus.$emit('request-start');
    return config;
  },
  requestErrorInterceptor(error) {
    app.$eventBus.$emit('request-fail');
    return Promise.reject(error);
  },
  responseSuccessInterceptor(response) {
    if (response.config.url === '/dashboards/stats') {
      requestFinish(response.config.url, 'delete');
    }

    app.$eventBus.$emit('connection-enabled');
    app.$eventBus.$emit('response-success');
    return response;
  },
  responseErrorInterceptor(error) {
    const queryUrl = error.config.url;
    if (queryUrl === '/dashboards/stats') {
      requestFinish(queryUrl, 'delete');
    }

    app.$eventBus.$emit('response-error', error);
    app.$eventBus.$emit('connection-lost');

    const errorMessage = error.response.data.message;

    try {
      if (error.response) {
        switch (error.response.status) {
        case 401:
          if (error.response.data.code === 'AUTH_INVALID_CREDENTIALS_ERROR') {
            app.$noty.error(errorMessage);
            app.$store.commit(`auth/${AUTH_LOGOUT}`);
            app.$store.dispatch('session/clearTimers');
            if (app.$route.path !== '/login/auth') {
              app.$router.push('/login/auth');
            }
            break;
          }

          if (error.response.data.code === 'AUTH_ACCOUNT_INACTIVE_ERROR') {
            app.$noty.error(errorMessage);
            break;
          }

          if (error.response.data.code === 'AUTH_CREDENTIALS_NOT_PROVIDED_ERROR') {
            app.$noty.error(errorMessage);
            app.$store.commit(`auth/${AUTH_LOGOUT}`);
            app.$store.dispatch('session/clearTimers');
            app.$router.replace('/login/auth');
            break;
          }

          if (error.response.data.errors === null) {
            app.$noty.error(errorMessage);
          }
          break;

        case 423:
          app.$noty.error(errorMessage);
          if (error.response.data.code === 'TOTP_LOCKED_ERROR') {
            app.$eventBus.$emit('checkSensitiveAction', '');
            break;
          }

          app.$store.dispatch(`auth/${AUTH_2FA_RESET}`)
            .then(() => {
              app.$router.push('/login/2fa');
            });
          break;

        case 403:
          app.$noty.error(errorMessage);
          break;
        case 404:
          app.$noty.error(errorMessage);
          app.$router.push(app.$route.matched[0]);
          break;
        case 405:
          if (ignoreErrorNotifyCountRequest(error)) break;
          if (error.response.data.code === 'NOT_ENOUGH_PERMISSIONS_ERROR' && app.$route.path !== '/profile') {
            app.$store.dispatch(`auth/${CHANGE_ENOUGH_PERMISSIONS}`);
            app.$router.push('/profile');
          }
          app.$noty.error(errorMessage);
          break;
        case 422:
          if (error.response?.data?.errors?.hasOwnProperty('recaptcha_token')) {
            break;
          }

          if (queryUrl === '/domains-management/platform-betting-domains') {
            const errorMessageFirstFromList = getFirstErrorFromList(error.response?.data?.errors);
            app.$noty.error(errorMessageFirstFromList);
            break;
          }
          app.$noty.error(errorMessage);
          break;
        default:
          if (error.response.status.toString()[0] === '5') {
            app.$noty.error(app.$t('validation.errors[2]'));
          }
          break;
        }
      }
    } catch (e) {
      window.console.error('Unknown response error: ', e);
      app.$noty.error('UNKNOWN ERROR, PLEASE RETRY');
    }
    app.$eventBus.$emit('connection-enabled');
    return Promise.reject(error.response);
  },
};
