<template>
  <div class="affiliate-sub-popup post-blacklist-popup">
    <div
      class="popup"
    >
      <div class="head">
        <span class="name">{{ $t('mediaBuying.blacklistWallets.popup.title') }}</span>
        <ui-icon
          :size="18"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <ui-table
        ref="tablePopup"
        v-loading="isDataLoading"
        :select-row="true"
        :fields="fields"
        :data="blacklist"
        :offset-column="2"
        :sort="{
          prop: sortProp,
          order: sortOrder,
        }"
        checked-row
        checked-row-id="key"
        i18n-path="mediaBuying.blacklist"
        element-loading-custom-class="data-loader-spinner"
        class="table"
        is-static
        :default-selected-rows="defaultSelectedRows"
        @checkedRows="checkedRows"
      />
      <div class="foot">
        <div>
          <ui-button
            color="red"
            lib="fa"
            substyle="fas"
            icon="times"
            @click.native="close"
          >
            {{ $t('crm.buttons.cancel') }}
          </ui-button>
          <ui-button
            :color="$theme.mainColor"
            :disabled="selectBlacklist.length === 0"
            filled
            @click="save"
          >
            <i class="fas fa-check" />
            {{ $t(`crm.buttons.confirm`) }}
          </ui-button>
        </div>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>

<script>

import errorHandleMixin from '@/views/mixins/error-hadle';

export default {
  name: 'PostBlacklistPopup',
  mixins: [errorHandleMixin],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    campaignId: {
      type: String,
      default: '',
    },

  },
  data() {
    return {
      popup: {
        title: '',
        msg: '',
        type: 'success',
        action: '',
        actionName: '',
        data: {},
      },
      blacklist: [],
      defaultSelectedRows: [],
      selectBlacklist: [],
      onSelect: Function,
      sortProp: 'created_at',
      sortOrder: 'asc',
      fields: [
        {
          name: 'site_payment_method_name',
          sortable: false,
        },
        {
          name: 'payment_wallet',
          sortable: false,
          class: 'text',
        },
      ],
      fetchDataPopup: Function,
      isDataLoading: false,
    };
  },
  computed: {
    queryPopup() {
      return {
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
      };
    },
  },
  watch: {
    isOpen(v) {
      if (v) {
        this.selectBlacklist = [];
        this.blacklist = [];
        this.selectBlacklist = [];
        this.defaultSelectedRows = [];

        this.fetchDataPopup();
      }
    },
  },

  mounted() {
    this.fetchDataPopup = this.$_.debounce(this.fetchDataPopupD, 50);
    window.addEventListener('keyup', (e) => {
      if (e.key === 'Escape' && this.isOpen) {
        this.close();
      }
    });
  },
  methods: {
    checkedRows(id, ids) {
      this.selectBlacklist = ids.filter((el) => {
        const [site_payment_method_id, payment_wallet] = el.split(':');

        return !this.blacklist.find(bl => bl.site_payment_method_id === site_payment_method_id && bl.payment_wallet === payment_wallet).is_blacklisted;
      });
    },
    fetchDataPopupD() {
      this.isDataLoading = true;
      this.$api.getMediaBuyingUsedWalletsById(this.campaignId).then((res) => {
        this.defaultSelectedRows = res.data.payload
          .filter(el => el.is_blacklisted)
          .map(el => `${el.site_payment_method_id}:${el.payment_wallet}`);

        this.blacklist = res.data.payload.map(el => ({
          ...el,
          disabled: el.is_blacklisted,
          key: `${el.site_payment_method_id}:${el.payment_wallet}`,
        }));
      }).finally(() => {
        this.isDataLoading = false;
      });
    },

    close() {
      this.$emit('close');
    },
    save() {
      const blacklist = this.selectBlacklist
        .map(key => this.blacklist
          .find(el => el.key === key))
        .map(el => ({
          media_buying_payment_method_id: el.site_payment_method_id,
          payment_wallet: el.payment_wallet,
        }));

      this.$api.postMediaBuyingBlacklist({ blacklist }).then(() => {
        this.close();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.post-blacklist-popup {
  .popup {
    width: 650px;
    height: 60vh;
  }

  .foot {
    justify-content: flex-end!important;
    align-items: center;
  }

  .field-error {
    margin-bottom: 0;
  }

  .table {
    /deep/ {
      > div {
        height: 100%;

        > div {
          height: 100%;
        }
      }
    }
  }
}
</style>
