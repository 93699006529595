<template>
  <div
    v-loading="isDataLoadingComputed"
    class="affiliate-info"
  >
    <!-- <AffiliateRotationGroupsPopup
      v-if="(currentAcl.affiliates_rotation_groups_edit === 'allow' && currentAcl.service_rotation_groups_view === 'allow' ) || adminAcl.is_superuser"
      :is-open="isOpenRotationGroupsPopup"
      :inital-data="rotationGroups"
      @close="closeRotationGroupsPopup"
      @save="updateRotationGroupsForAffiliate"
    /> -->
    <EditPublicProfile :id="id" ref="editPublicProfile" @update-affiliate="fetchAffiliateData({ isSilent: true })" />
    <ui-confirm
      ref="editBls"
      :width="480"
      :type="editBls.type"
      :action="editBls.action"
      :action-name="editBls.actionName"
      :action-fa-icon="editBls.actionFaIcon"
      :action-disabled="balance.sum === '' || balance.note === ''"
      class="confirm-edit-balance"
      @close="cancelBls"
      @save="saveBls"
    >
      <div
        slot="title"
        v-html="editBls.title"
      />
      <div
        class="body__msg"
        v-html="editBls.msg"
      />
      <div class="body__wrap">
        <div class="body__first-line">
          <div class="body__radio-wr">
            <span class="label">{{ $t('affiliates.affiliatesInfo.operation') }}</span>
            <el-radio-group
              v-model="balance.operation"
            >
              <el-radio-button label="deposit">
                {{ $t('affiliates.affiliatesInfo.deposit') }}
              </el-radio-button>
              <el-radio-button label="withdraw">
                {{ $t('affiliates.affiliatesInfo.withdraw') }}
              </el-radio-button>
            </el-radio-group>
          </div>
          <ui-input
            v-model="balance.sum"
            :placeholder="$t('affiliates.affiliatesInfo.inp_sum')"
            :label="$t('affiliates.affiliatesInfo.sum')"
            :error="errors.amount"
            class="body__input edit-balance"
            :post-text="currencyPrefix"
            size="big"
          />
        </div>
        <div class="body__textarea-wr">
          <span class="label">{{ $t('affiliates.affiliatesInfo.note') }}</span>
          <el-input
            v-model="balance.note"
            :maxlength="225"
            :autosize="{ minRows: 4, maxRows: 7}"
            :placeholder="$t('affiliates.affiliatesInfo.note_about_user')"
            type="textarea"
          />
        </div>
      </div>
    </ui-confirm>
    <ui-confirm
      ref="confirm"
      :width="480"
      :type="confirmType"
      :action-name="actionName"
      :action="confirmAction"
      :action-icon="actionIcon"
      :action-fa-icon="actionFaIcon"
      :action-disabled="isLoading"
      @save="onConfirm"
      @close="confirmClose"
    >
      <div
        slot="title"
        v-html="confirmTitle"
      />
      <div class="body__wrap">
        <i
          v-if="confirmAction === 'Approve'"
          :class="confirmType"
          class="fas fa-check"
        />
        <i
          v-else-if="confirmAction === 'createPayment'"
          :class="confirmType"
          class="fas fa-money-bill-wave-alt"
        />
        <i
          v-else-if="confirmAction === 'confirmPayment' && infoCreatePayment.payment_created"
          :class="confirmType"
          class="fas fa-check"
        />
        <i
          v-else-if="confirmAction === 'confirmPayment' && !infoCreatePayment.payment_created"
          :class="confirmType"
          class="fas fa-exclamation-triangle"
        />
        <i
          v-else-if="confirmAction === 'Decline'"
          :class="confirmType"
          class="fas fa-times"
        />
        <i
          v-else-if="confirmAction === 'Decline w/'"
          :class="confirmType"
          class="fas fa-bell"
        />
        <i
          v-else-if="confirmAction === 'Block'"
          :class="confirmType"
          class="fas fa-lock"
        />
        <i
          v-else-if="confirmAction === 'Unblock'"
          :class="confirmType"
          class="fas fa-unlock"
        />
        <i
          v-else-if="confirmAction === 'deleteNote'"
          :class="confirmType"
          class="fas fa-trash-alt"
        />
        <i
          v-else-if="confirmAction === 'deletePublicProfile'"
          :class="confirmType"
          class="fas fa-times"
        />
        <i
          v-else
          :class="confirmType"
          class="fas fa-exclamation-triangle"
        />
        <div
          class="body__msg"
          v-html="confirmMsg"
        />
      </div>
    </ui-confirm>
    <ui-confirm
      ref="confirmEditPayment"
      :width="480"
      :type="confirmEditPayment.type"
      :action-name="confirmEditPayment.actionName"
      :action="confirmEditPayment.action"
      :action-fa-icon="'check'"
      class="confirm-edit-payment"
      @close="cancelPayment"
      @save="savePayment"
    >
      <div
        slot="title"
        v-html="confirmEditPayment.title"
      />
      <div class="body__wrap">
        <div class="title">
          <span>{{ $t(`affiliates.cardView.info.payments_enabled`) }}</span>
          <el-switch
            v-model="payment.payments_enabled"
            :active-color="activeColor"
            :inactive-color="inactiveColor"
          />
        </div>
        <div
          class="body__msg"
          v-html="confirmEditPayment.msg"
        />
        <div class="select-wrap">
          <div class="select-block pm-select">
            <span class="label">{{ $t(`affiliates.cardView.info.paymentMethod`) }}</span>
            <el-select
              v-model="payment.site_payment_method_id"
              :class="$_.isArray(errors.site_payment_method_id) && 'error'"
              :placeholder="$t(`affiliates.cardView.info.paymentMethod`)"
              filterable
              :filter-method="filterMethod"
              @visible-change="filterValue = ''"
              @change="payment.payment_wallet = ''"
            >
              <el-option
                v-for="item in paymentMethodsWithEmpty"
                :key="item.id"
                class="payment-select-item"
                :label="item.name"
                :value="item.id"
                :disabled="item.is_disabled"
              />
            </el-select>
            <div
              v-if="$_.isArray(errors.site_payment_method_id)"
              class="input-error"
            >
              {{ $t(`${errors.site_payment_method_id[0].message}`) }}
            </div>
          </div>
          <div class="select-block">
            <span class="label">{{ $t(`affiliates.cardView.info.paymentPeriod`) }}</span>
            <el-select
              v-model="payment.payments_net"
              :placeholder="$t(`affiliates.cardView.info.paymentPeriod`)"
            >
              <el-option
                v-for="item in paymentPeriods"
                :key="item"
                :label="item | uppercase"
                :value="item"
              />
            </el-select>
          </div>
        </div>
        <ui-input
          v-model="payment.payment_wallet"
          :disabled="payment.site_payment_method_id === null"
          :placeholder="placeholderWallet || ''"
          :label="$t(`affiliates.cardView.info.wallet`)"
          :error="errors.payment_wallet"
          class="wallet-p"
          autosize
        />
        <div class="checkbox-block">
          <el-checkbox
            v-model="payment.payments_negative_carryover"
            class="checkbox"
          >
            {{ $t(`affiliates.cardView.info.negative_carryover`) }}
          </el-checkbox>
        </div>
        <div class="body__textarea-wr">
          <span class="label">{{ $t('affiliates.affiliatesInfo.reason') }}</span>
          <el-input
            v-model="payment.reason"
            :maxlength="225"
            :autosize="{ minRows: 4, maxRows: 7}"
            :placeholder="$t('affiliates.affiliatesInfo.reason')"
            type="textarea"
          />
        </div>
      </div>
    </ui-confirm>
    <ui-confirm
      ref="editCurrency"
      :width="480"
      type="success"
      action-fa-icon="check"
      :action-name="$t('crm.buttons.save')"
      class="confirm-edit-payment"
      @save="saveCurrency"
    >
      <div slot="title">
        {{ $t('affiliates.affiliatesInfo.currency.popup.title') }}
      </div>
      <div class="body__wrap">
        <div class="select-wrap">
          <div class="select-block select-block__full">
            <span class="label">{{ $t('affiliates.affiliatesInfo.currency.label') }}</span>
            <el-select
              v-model="currentCurrency"
              :class="getError('error') && 'error'"
              :placeholder="$t(`affiliates.cardView.info.paymentMethod`)"
              filterable
              :filter-method="customCurrencyFilter"
            >
              <el-option
                v-for="item in filteredCurrencyOptions"
                :key="item.code"
                class="payment-select-item currency-option"
                :label="item.name"
                :value="item.code"
              />
            </el-select>
            <div
              v-if="getError('country_code')"
              class="input-error"
            >
              {{ getError('country_code') }}
            </div>
          </div>
        </div>
      </div>
    </ui-confirm>
    <ui-confirm
      ref="confirmEditLimits"
      :width="650"
      :type="confirmEditLimits.type"
      :action-name="confirmEditLimits.actionName"
      :action="confirmEditLimits.action"
      :action-fa-icon="'check'"
      class="confirm-edit-payment"
      @close="cancelLimits"
      @save="saveLimits"
    >
      <div
        slot="title"
        v-html="confirmEditLimits.title"
      />
      <div class="body__wrap">
        <div class="select-wrap">
          <div class="select-block">
            <ui-input
              v-model="limits.max_api_keys"
              :placeholder="$t(`affiliates.affiliatesInfo.max_api_keys`)"
              :label="$t(`affiliates.affiliatesInfo.max_api_keys`)"
              :error="errors.max_api_keys"
              size="big"
              autosize
            />
          </div>
          <div class="select-block">
            <ui-input
              v-model="limits.max_promo_codes"
              :placeholder="$t(`affiliates.affiliatesInfo.max_promo_codes`)"
              :label="$t(`affiliates.affiliatesInfo.max_promo_codes`)"
              :error="errors.max_promo_codes"
              size="big"
              autosize
            />
          </div>
        </div>
        <div class="select-wrap">
          <div class="select-block">
            <ui-input
              v-model="limits.max_traffic_sources"
              :placeholder="$t(`affiliates.affiliatesInfo.max_traffic_sources`)"
              :label="$t(`affiliates.affiliatesInfo.max_traffic_sources`)"
              :error="errors.max_traffic_sources"
              size="big"
              autosize
            />
          </div>
          <div class="select-block">
            <ui-input
              v-model="limits.max_postbacks_per_traffic_source"
              :placeholder="$t(`affiliates.affiliatesInfo.max_postbacks_per_traffic_source`)"
              :label="$t(`affiliates.affiliatesInfo.max_postbacks_per_traffic_source`)"
              :error="errors.max_postbacks_per_traffic_source"
              size="big"
              autosize
            />
          </div>
        </div>
        <div class="select-wrap">
          <div class="select-block">
            <ui-input
              v-model="limits.max_tags_per_traffic_source"
              :placeholder="$t(`affiliates.affiliatesInfo.max_tags_per_traffic_source`)"
              :label="$t(`affiliates.affiliatesInfo.max_tags_per_traffic_source`)"
              :error="errors.max_tags_per_traffic_source"
              size="big"
              autosize
            />
          </div>
          <div class="select-block">
            <span class="label">{{ $t('settings.systemSettings.createPromoCodes') }}</span>
            <el-select
              v-model="limits.allow_promo_code_create_or_edit"
              size="big"
              :class="getError('allow_promo_code_create_or_edit')"
              :placeholder="$t('settings.systemSettings.createPromoCodes')"
            >
              <el-option
                v-for="item in promoCodesOption"
                :key="item.id"
                class="payment-select-item currency-option"
                :label="item.text"
                :value="item.id"
              />
            </el-select>
          </div>
        </div>
      </div>
    </ui-confirm>
    <ui-confirm
      ref="changeNotificationsStatus"
      :width="480"
      type="success"
      :action-name="$t('affiliates.confirm.save')"
      action-fa-icon="check"
      class="change-notifications-status"
      @close="cancelNotificationsStatus"
      @save="saveNotificationsStatus"
    >
      <div
        slot="title"
        v-html="changeNotificationsStatusTitle"
      />
      <div class="body__wrap">
        <div class="title">
          <span>{{ $t(`affiliates.affiliate_notifications.popup.notifications_enabled`) }}</span>
          <el-switch
            v-model="notification.service_domain_changes_notifications_enabled"
            :active-color="activeColor"
            :inactive-color="inactiveColor"
          />
        </div>
        <div
          :class="!notification.service_domain_changes_notifications_enabled ? 'disabled' : ''"
          class="select-block"
        >
          <span class="label">{{ $t(`affiliates.affiliate_notifications.popup.select_label`) }}</span>
          <el-select
            v-model="notification.service_domain_changes_notifications_channel"
          >
            <el-option
              v-for="item in notificationsType"
              :key="item.id"
              :disabled="item.disabled"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </div>
      </div>
    </ui-confirm>
    <ui-confirm
      ref="confirmChangeMail"
      :width="480"
      :action-name="$t(`affiliates.cardView.info.change_mail`)"
      type="success"
      action-fa-icon="check"
      class="confirm-edit-payment"
      @save="changeEmail"
    >
      <div
        slot="title"
        v-html="confirmChangeMail.title"
      />
      <div class="body__wrap">
        <div class="select-wrap">
          <div class="select-block select-block__full">
            <ui-input
              v-model="newEmail"
              :placeholder="$t(`affiliates.confirm.change_email_placeholder`)"
              :label="$t(`affiliates.confirm.change_email_label`)"
              :error="errors.email"
              size="big"
              autosize
            />
          </div>
        </div>
      </div>
    </ui-confirm>
    <ui-confirm
      ref="confirmEditInfo"
      :width="480"
      :type="confirmEditInfo.type"
      :action-name="confirmEditInfo.actionName"
      :action="confirmEditInfo.action"
      :action-icon="actionIcon"
      :action-fa-icon="actionFaIcon"
      class="confirm-edit-payment"
      @close="cancelInfo"
      @save="saveInfo"
    >
      <div
        slot="title"
        v-html="confirmEditInfo.title"
      />
      <div class="body__wrap">
        <div class="select-wrap">
          <div class="select-block">
            <ui-input
              v-model="popupInfo.first_name"
              :placeholder="$t(`affiliates.cardView.info.fname`)"
              :label="$t(`affiliates.cardView.info.fname`)"
              :error="errors.first_name"
              size="big"
              autosize
            />
          </div>
          <div class="select-block">
            <ui-input
              v-model="popupInfo.last_name"
              :placeholder="$t(`affiliates.cardView.info.surname`)"
              :label="$t(`affiliates.cardView.info.surname`)"
              :error="errors.last_name"
              size="big"
              autosize
            />
          </div>
        </div>
        <div class="select-wrap">
          <div class="select-block select-block__full">
            <span class="label">{{ $t(`affiliates.cardView.info.birthDay`) }}</span>
            <el-date-picker
              v-model="popupInfo.date_of_birth"
              :placeholder="$t(`affiliates.cardView.info.birthDay`)"
              :label="$t(`affiliates.cardView.info.birthDay`)"
              :editable="false"
              size="big"
              type="date"
              autosize
              format="dd MMM yyyy"
              :default-value="popupInfo.date_of_birth ? undefined : defaultBirthDateView"
              :picker-options="birthPickerOptions"
            />
            <span
              v-if="$_.isArray(errors.date_of_birth)"
              class="input-error"
            >
              {{ `${errors.date_of_birth[0].message}` }}
            </span>
          </div>
        </div>
        <div class="popup-line" />
        <div class="select-wrap">
          <ui-input
            v-model="popupInfo.phone_number"
            input-mask="+###############"
            :placeholder="$t(`affiliates.cardView.info.phone`)"
            :label="$t(`affiliates.cardView.info.phone`)"
            :error="errors.phone_number"
            size="big"
            autosize
          />
        </div>
        <div class="select-wrap">
          <div class="select-block">
            <span class="label">{{ $t(`affiliates.cardView.info.messenger`) }}</span>
            <el-select
              v-model="popupInfo.messenger"
              :placeholder="$t(`affiliates.cardView.info.messenger`)"
              size="big"
              @change="popupInfo.messenger_id = ''"
            >
              <el-option
                v-for="item in messengersInfo"
                :key="item.id"
                :label="item.name | capitalize"
                :value="item.id"
              />
            </el-select>
          </div>
          <div class="select-block">
            <ui-input
              v-model="popupInfo.messenger_id"
              :placeholder="placeholderMessenger"
              :label="$t(`affiliates.cardView.info.msgid`)"
              :error="errors.messenger_id"
              size="big"
              autosize
            />
          </div>
        </div>
        <div class="popup-line" />
        <div class="select-wrap">
          <div class="select-block">
            <span class="label">{{ $t(`affiliates.cardView.info.country`) }}</span>
            <el-select
              v-model="popupInfo.country_code"
              :placeholder="$t(`affiliates.cardView.info.country`)"
              size="big"
              filterable
            >
              <el-option
                v-for="item in countries"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              />
            </el-select>
          </div>
          <div class="select-block">
            <ui-input
              v-model="popupInfo.city"
              :placeholder="$t(`affiliates.cardView.info.city`)"
              :label="$t(`affiliates.cardView.info.city`)"
              :error="errors.city"
              size="big"
              autosize
            />
          </div>
        </div>
        <div class="select-wrap">
          <div class="select-block">
            <ui-input
              v-model="popupInfo.postal_code"
              :placeholder="$t(`affiliates.cardView.info.postal_code`)"
              :label="$t(`affiliates.cardView.info.postal_code`)"
              :error="errors.postal_code"
              size="big"
              autosize
            />
          </div>
          <div class="select-block">
            <ui-input
              v-model="popupInfo.address"
              :placeholder="$t(`affiliates.cardView.info.address`)"
              :label="$t(`affiliates.cardView.info.address`)"
              :error="errors.address"
              size="big"
              autosize
            />
          </div>
        </div>
      </div>
    </ui-confirm>
    <ui-confirm
      ref="editSiteUrl"
      :width="480"
      :type="editSiteUrl.type"
      :action-name="editSiteUrl.actionName"
      :action="editSiteUrl.action"
      :action-icon="actionIcon"
      :action-fa-icon="actionFaIcon"
      class="edit-sites-url"
      @close="cancelSiteUrl"
      @save="savelSiteUrl"
    >
      <div
        slot="title"
        v-html="editSiteUrl.title"
      />
      <div
        class="body__msg"
        v-html="editSiteUrl.msg"
      />
      <div class="body__wrap">
        <div class="sites-title">
          {{ $t(`affiliates.cardView.info.site_urls`) }}
        </div>
        <div class="site-wrapper">
          <div
            v-for="(item, index) in popupInfo.site_url"
            :key="index"
            class="site-row"
          >
            <ui-input
              v-model="popupInfo.site_url[index]"
              :placeholder="$t(`affiliates.cardView.info.site_urls`)"
              class="site-input"
            />
            <ui-button
              :icon-size="12"
              color="red"
              lib="fa"
              substyle="fas"
              icon="times"
              class="btn site-del"
              @click="removeSite(index)"
            />
          </div>
        </div>
        <ui-button
          icon="plus"
          lib="fa"
          substyle="fas"
          color="green"
          class="btn"
          @click="addSite"
        >
          {{ $t(`affiliates.cardView.info.add_url`) }}
        </ui-button>
      </div>
    </ui-confirm>
    <reset-password-popup
      :id="id"
      :is-open="isResetOpen"
      :email="info.email || ''"
      @close="isResetOpen = false"
    />
    <confirm-popup
      v-show="isConfirmOpen"
      :on-confirm="onConfirm"
      :action-title="confirmAction"
      :is-open="isConfirmOpen"
      @close="isConfirmOpen = false"
    >
      {{ confirmMsg }}
    </confirm-popup>
    <affiliate-sub-popup-temporary
      v-show="isPinOpen"
      ref="subpin"
      :is-open="isPinOpen"
      :affiliate-data="info"
      aff-info="aff"
      @confirm="confirmPin"
      @close="isPinOpen = false"
    />
    <div class="wrapper">
      <div
        v-if="isShowButtons"
        class="toolbar tools"
      >
        <div
          v-if="info.account_status === 'pending'"
          class="btns"
        >
          <ui-button
            v-if="isEditStatus && hasPermission('affiliates_can_decline')"
            icon="times"
            lib="fa"
            substyle="fas"
            color="red"
            filled
            class="action-btn"
            @click="declineAffiliateHandler(false)"
          >
            {{ $t('affiliates.cardView.toolbar.btns.decline') }}
          </ui-button>
          <ui-button
            v-if="isEditStatus && hasPermission('affiliates_can_decline')"
            icon="bell"
            lib="fa"
            substyle="fas"
            color="red"
            class="action-btn"
            @click="declineAffiliateHandler(true)"
          >
            {{ $t('affiliates.cardView.toolbar.btns.note_decline') }}
          </ui-button>
          <ui-button
            v-if="!info.email_verified && isEditStatus"
            icon="check"
            color="green"
            lib="fa"
            substyle="fas"
            filled
            class="action-btn"
            @click="activateAffiliateHandler"
          >
            {{ $t('affiliates.cardView.toolbar.btns.activate_manual') }}
          </ui-button>
          <ui-button
            v-if="isCreatePayment && hasPermission('affiliates_payments_can_create')"
            color="green"
            icon="money-bill-wave-alt"
            lib="fa"
            substyle="fas"
            class="action-btn"
            @click="createPayment"
          >
            {{ $t('affiliates.cardView.toolbar.btns.create_payment') }}
          </ui-button>
          <ui-button
            v-if="isEditStatus && hasPermission('affiliates_can_approve')"
            filled
            color="green"
            icon="check"
            lib="fa"
            substyle="fas"
            class="action-btn"
            @click="approveAffiliateHandler"
          >
            {{ $t('affiliates.cardView.toolbar.btns.approve') }}
          </ui-button>
        </div>
        <div
          v-else-if="info.account_status === 'rejected'"
          class="btns"
        >
          <ui-button
            v-if="!info.email_verified && isEditStatus"
            lib="fa"
            substyle="fas"
            icon="check"
            color="green"
            filled
            class="action-btn"
            @click="activateAffiliateHandler"
          >
            {{ $t('affiliates.cardView.toolbar.btns.activate_manual') }}
          </ui-button>
          <a
            v-if="isViewReports"
            :href="affiliateStatisticSupported ? reportParam : undefined"
            class="login-link"
            @click.prevent="() => {
              affiliateStatisticSupported ? $router.push(reportParam) : undefined
            }"
          >
            <ui-button
              color="green"
              lib="fa"
              substyle="fas"
              icon="chart-bar"
              class="action-btn el-dropdown-link"
              :disabled="!affiliateStatisticSupported"
            >{{ $t('affiliates.cardView.toolbar.btns.statistics') }}</ui-button>
          </a>
          <ui-button
            v-if="isCreatePayment && hasPermission('affiliates_payments_can_create')"
            color="green"
            icon="money-bill-wave-alt"
            lib="fa"
            substyle="fas"
            class="action-btn"
            @click="createPayment"
          >
            {{ $t('affiliates.cardView.toolbar.btns.create_payment') }}
          </ui-button>
          <ui-button
            v-if="isEditStatus && hasPermission('affiliates_can_revoke')"
            color="green"
            filled
            icon="eye"
            lib="fa"
            substyle="fas"
            class="action-btn"
            @click="revokeAffiliateHandler"
          >
            {{ $t('affiliates.cardView.toolbar.btns.revoke') }}
          </ui-button>
        </div>
        <div v-else>
          <div
            class="btns"
          >
            <ui-button
              v-if="info.account_status === 'approved' && isEditStatus && hasPermission('affiliates_can_block')"
              icon="lock"
              lib="fa"
              substyle="fas"
              color="red"
              class="action-btn"
              @click="blockAffiliateHandler"
            >
              {{ $t('affiliates.cardView.toolbar.btns.block') }}
            </ui-button>
            <ui-button
              v-if="info.account_status === 'blocked' && isEditStatus && hasPermission('affiliates_can_unblock')"
              icon="unlock"
              lib="fa"
              substyle="fas"
              color="green"
              class="action-btn"
              @click="unblockAffiliateHandler"
            >
              {{ $t('affiliates.cardView.toolbar.btns.unblock') }}
            </ui-button>
            <a
              v-if="isViewReports"
              :href="affiliateStatisticSupported ? reportParam : undefined"
              class="login-link"
              @click.prevent="() => {
                affiliateStatisticSupported ? $router.push(reportParam) : undefined
              }"
            >
              <ui-button
                color="green"
                lib="fa"
                substyle="fas"
                icon="chart-bar"
                class="action-btn el-dropdown-link"
                :disabled="!affiliateStatisticSupported"
              >{{ $t('affiliates.cardView.toolbar.btns.statistics') }}</ui-button>
            </a>
            <ui-button
              v-if="isCreatePayment && hasPermission('affiliates_payments_can_create')"
              color="green"
              icon="money-bill-wave-alt"
              lib="fa"
              substyle="fas"
              class="action-btn"
              @click="createPayment"
            >
              {{ $t('affiliates.cardView.toolbar.btns.create_payment') }}
            </ui-button>
            <ui-button
              v-if="isEditStatus && hasPermission('affiliates_can_revoke')"
              color="green"
              icon="eye"
              lib="fa"
              substyle="fas"
              class="action-btn"
              @click="revokeAffiliateHandler"
            >
              {{ $t('affiliates.cardView.toolbar.btns.revoke') }}
            </ui-button>
            <a
              v-if="hasPermission('affiliates_can_login') && info.account_status !== 'blocked' && info.email_verified &&
                this.$store.getters['session/significantActionsSeconds'] !== 0"
              :href="info.login_url"
              target="_blank"
              class="login-link"
            >
              <ui-button
                color="green"
                lib="fa"
                substyle="fas"
                icon="sign-in-alt"
                class="action-btn el-dropdown-link"
              >{{ $t('affiliates.cardView.toolbar.btns.login') }}</ui-button>
            </a>

            <ui-button
              v-if="hasPermission('affiliates_can_login') && info.account_status !== 'blocked' && info.email_verified &&
                this.$store.getters['session/significantActionsSeconds'] === 0"
              color="green"
              lib="fa"
              substyle="fas"
              icon="sign-in-alt"
              class="action-btn el-dropdown-link no-events"
              @click="checkSensitiveAction('')"
            >
              {{ $t('affiliates.cardView.toolbar.btns.login') }}
            </ui-button>

            <ui-button
              v-if="info.google2fa_enabled && isResetTotp"
              icon="shield-check"
              color="green"
              lib="fa"
              class="action-btn first-btn"
              @click="resetTotp"
            >
              {{ $t('affiliates.cardView.toolbar.btns.resetTotp') }}
            </ui-button>
            <ui-button
              v-if="isEditPass && hasPermission('affiliates_password_edit')"
              lib="fa"
              substyle="fas"
              icon="key"
              color="green"
              class="action-btn first-btn"
              @click="resetPasswordAffiliate"
            >
              {{ $t('affiliates.cardView.toolbar.btns.reset') }}
            </ui-button>
            <ui-button
              v-if="!info.email_verified && isEditStatus"
              lib="fa"
              substyle="fas"
              icon="check"
              color="green"
              filled
              class="action-btn"
              @click="activateAffiliateHandler"
            >
              {{ $t('affiliates.cardView.toolbar.btns.activate_manual') }}
            </ui-button>
          </div>
        </div>
      </div>
      <div class="info">
        <div class="sidebar">
          <div class="card">
            <div class="title">
              <div class="title-content">
                <span>{{ $t('affiliates.affiliatesInfo.currency.label') }}</span>
                <span
                  v-if="isEditCurrencies"
                  style="color: #20815e"
                  class="action"
                  @click="checkSensitiveAction('handleEditCurrency')"
                >
                  <ui-icon
                    name="pen"
                    color="#20815e"
                    lib="fa"
                  />
                  {{ $t(`affiliates.affiliatesInfo.edit`) }}
                </span>
              </div>
            </div>
            <div class="card-content">
              <div class="field">
                <span class="label">{{ activeCurrency.name }}</span>
              </div>
            </div>
          </div>
          <div class="card balance">
            <div class="title">
              <div class="title-content">
                <span>{{ $t(`affiliates.cardView.info.balance`) }}</span>
                <div class="buttons-wrapper">
                  <span
                    style="color: #20815e"
                    class="action"
                    @click="showBalanceHistory"
                  >
                    <ui-icon
                      name="history"
                      color="#20815e"
                      lib="fa"
                    />
                    {{ $t(`affiliates.affiliatesInfo.history`) }}
                  </span>
                  <span
                    v-if="isEditBalance && hasPermission('affiliates_balance_edit')"
                    style="color: #20815e"
                    class="action"
                    @click="checkSensitiveAction('handleEditBls')"
                  >
                    <ui-icon
                      name="pen"
                      color="#20815e"
                      lib="fa"
                    />
                    {{ $t(`affiliates.affiliatesInfo.edit`) }}
                  </span>
                </div>
              </div>
            </div>
            <div class="card-content">
              <div class="field">
                <span class="label">{{ $t(`affiliates.affiliatesInfo.total`) }}</span>
                <div
                  :class="{ negative: wallets.hold_balance + wallets.available_balance < 0 }"
                  class="values total_balance"
                >
                  {{ currencyPrefix }}{{ (wallets.hold_balance + wallets.available_balance) | formatMoney }}
                </div>
              </div>
              <div class="field">
                <span class="label">{{ $t(`affiliates.affiliatesInfo.in_hold`) }}</span>
                <div
                  :class="{ negative: wallets.hold_balance < 0 }"
                  class="values"
                >
                  {{ currencyPrefix }}{{ wallets.hold_balance | formatMoney }}
                </div>
              </div>
              <div class="field">
                <span class="label">{{ $t(`affiliates.affiliatesInfo.available`) }}</span>
                <div
                  :class="{ negative: wallets.available_balance < 0 }"
                  class="values"
                >
                  {{ currencyPrefix }}{{ wallets.available_balance | formatMoney }}
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="title">
              <div class="title-content">
                <span>{{ $t(`affiliates.affiliatesInfo.paymentinfo`) }}</span>
                <span
                  v-if="isPaymentMethod && hasPermission('affiliates_payment_info_edit') && info.allowed_to_update_payment_method"
                  style="color: #20815e"
                  class="action"
                  @click="checkSensitiveAction('handleEditPayment')"
                >
                  <ui-icon
                    name="pen"
                    color="#20815e"
                    lib="fa"
                  />
                  {{ $t(`affiliates.affiliatesInfo.edit`) }}
                </span>
              </div>
            </div>
            <div class="card-content">
              <div class="field">
                <span class="label">{{ $t(`affiliates.cardView.info.payments_enabled`) }}</span>
                <div class="values">
                  {{ info.payments_enabled ? $t(`affiliates.cardView.info.status_yes`) : $t(`affiliates.cardView.info.status_no`) }}
                </div>
              </div>
              <div class="field">
                <span class="label">{{ $t(`affiliates.affiliatesInfo.method`) }}</span>
                <div
                  v-if="humanizePaymentMethod(info.site_payment_method_id)"
                  style="color: #303634"
                  class="values"
                >
                  {{ humanizePaymentMethod(info.site_payment_method_id) }}
                </div>
                <div
                  v-else
                  style="color: #d3d3d3"
                  class="values"
                >
                  {{ $t('affiliates.affiliatesInfo.not_specefied') }}
                </div>
              </div>
              <div class="field">
                <span class="label">{{ $t(`affiliates.affiliatesInfo.wallet`) }}</span>
                <el-tooltip
                  :disabled="popoverDisabled"
                  :content="info.payment_wallet"
                  popper-class="wlt"
                  placement="bottom"
                  effect="light"
                >
                  <div
                    ref="wlt"
                    :style="{
                      color: info.payment_wallet ? '#303634' : '#d3d3d3'
                    }"
                    class="values"
                  >
                    {{ info.payment_wallet ? info.payment_wallet : $t(`affiliates.affiliatesInfo.not_specefied`) }}
                  </div>
                </el-tooltip>
              </div>
              <div class="field">
                <span class="label">{{ $t(`affiliates.affiliatesInfo.period`) }}</span>
                <div class="values">
                  {{ info.payments_net | capitalize }}
                </div>
              </div>
              <div class="field">
                <span class="label">{{ $t(`affiliates.cardView.info.negative_carryover`) }}</span>
                <div class="values">
                  {{ info.payments_negative_carryover ? $t(`affiliates.cardView.info.status_yes`) : $t(`affiliates.cardView.info.status_no`) }}
                </div>
              </div>
            </div>
          </div>
          <div class="card card-max-limits">
            <div class="title">
              <div class="title-content">
                <span>{{ $t(`affiliates.affiliatesInfo.max_limits`) }}</span>
                <span
                  v-if="isEditLimits"
                  style="color: #20815e"
                  class="action"
                  @click="handleEditLimits"
                >
                  <ui-icon
                    name="pen"
                    color="#20815e"
                    lib="fa"
                  />
                  {{ $t(`affiliates.affiliatesInfo.edit`) }}
                </span>
              </div>
            </div>
            <div class="card-content">
              <div class="field">
                <span class="label">{{ $t(`affiliates.affiliatesInfo.max_api_keys`) }}</span>
                <div class="values">
                  {{ info.limits && info.limits.max_api_keys ? parseFloat(info.limits.max_api_keys).toLocaleString('en-US') : $t(`affiliates.affiliatesInfo.max_default`) }}
                </div>
              </div>
              <div class="field">
                <span class="label">{{ $t(`affiliates.affiliatesInfo.max_promo_codes`) }}</span>
                <div class="values">
                  {{ info.limits && info.limits.max_promo_codes ? parseFloat(info.limits.max_promo_codes).toLocaleString('en-US') : $t(`affiliates.affiliatesInfo.max_default`) }}
                </div>
              </div>
              <div class="field">
                <span class="label">{{ $t(`affiliates.affiliatesInfo.max_traffic_sources`) }}</span>
                <div class="values">
                  {{ info.limits && info.limits.max_traffic_sources ? parseFloat(info.limits.max_traffic_sources).toLocaleString('en-US') : $t(`affiliates.affiliatesInfo.max_default`) }}
                </div>
              </div>
              <div class="field">
                <span class="label card__label-max">{{ $t(`affiliates.affiliatesInfo.max_postbacks_per_traffic_source`) }}</span>
                <div class="values">
                  {{ info.limits && info.limits.max_postbacks_per_traffic_source ? parseFloat(info.limits.max_postbacks_per_traffic_source).toLocaleString('en-US') : $t(`affiliates.affiliatesInfo.max_default`) }}
                </div>
              </div>
              <div class="field">
                <span class="label card__label-max">{{ $t(`affiliates.affiliatesInfo.max_tags_per_traffic_source`) }}</span>
                <div class="values">
                  {{ info.limits && info.limits.max_tags_per_traffic_source ? parseFloat(info.limits.max_tags_per_traffic_source).toLocaleString('en-US') : $t(`affiliates.affiliatesInfo.max_default`) }}
                </div>
              </div>
              <div class="field">
                <span class="label card__label-max">{{ $t(`settings.systemSettings.createPromoCodes`) }}</span>
                <div class="values">
                  <span v-if="info.limits && info.limits.allow_promo_code_create_or_edit === null">{{ $t('affiliates.affiliatesInfo.max_default') }}</span>
                  <span v-else-if="info.limits" :style="{ color: info.limits && info.limits.allow_promo_code_create_or_edit ? $theme.accentColor : $theme.dangerColor }">
                    {{ info.limits.allow_promo_code_create_or_edit ? $t('permissions.allow') : $t('permissions.deny') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="body">
          <div class="body-info personal-info">
            <div class="card">
              <div class="title">
                <div class="title-content">
                  <span>{{ $t(`affiliates.cardView.info.personal_info`) }}</span>
                  <span
                    v-if="isEditPermission"
                    class="action success"
                    @click="handleEditInfo"
                  >
                    <ui-icon
                      name="pen"
                      class="success"
                      lib="fa"
                    />
                    {{ $t(`affiliates.affiliatesInfo.edit`) }}
                  </span>
                </div>
              </div>
              <div class="card-content">
                <div class="field field__login">
                  <span
                    v-if="info.first_name || info.last_name"
                    class="label name"
                  >{{ `${info.first_name} ${info.last_name}` }}</span>
                  <span
                    v-else
                    class="label name empty"
                  >{{ $t('affiliates.affiliatesInfo.not_specefied') }}</span>
                  <div class="login-card">
                    <span class="label">{{ $t(`affiliates.cardView.info.lastLogin`) }}: </span>
                    <span
                      v-if="info.last_active"
                    >
                      {{ info.last_active | formatDate3 }}
                    </span>
                    <span
                      v-else
                    >
                      {{ $t(`affiliates.cardView.info.lastLoginEmpty`) }}
                    </span>
                    <span class="divider">|</span>
                    <span class="label">{{ $t(`affiliates.cardView.info.regDae`) }}:</span> {{ info.created_at | formatDate3 }}
                  </div>
                </div>
                <div class="field without-padding">
                  <div class="card">
                    <div class="field field-solo">
                      <ui-icon
                        :size="14"
                        :color="$theme.accentColor"
                        lib="fa"
                        substyle="fas"
                        name="phone"
                        class="ico"
                      />
                      <span
                        v-if="info.phone_number"
                        class="phone"
                      >
                        <a :href="`tel:${info.phone_number}`">{{ info.phone_number }}</a>
                      </span>
                      <span
                        v-else
                        class="phone empty"
                      >{{ $t('affiliates.affiliatesInfo.not_specefied') }}</span>
                    </div>
                    <div class="field">
                      <span class="mail-wrapper">
                        <ui-icon
                          :size="14"
                          lib="fa"
                          :color="$theme.accentColor"
                          substyle="fas"
                          name="envelope"
                          class="ico"
                        />
                        <span
                          v-if="info.email"
                          class="phone"
                        >
                          <a
                            :href="`mailto:${info.email}`"
                            rel="noopener noreferrer"
                            target="_blank"
                          >{{ info.email }}</a>
                          <ui-icon
                            :size="10"
                            :name="info.email_verified ? 'check' : ''"
                            lib="fa"
                            :class="info.email_verified ? 'success' : ''"
                            substyle="fas"
                          />
                        </span>
                        <span
                          v-else
                          class="phone empty"
                        >{{ $t('affiliates.affiliatesInfo.not_specefied') }}</span>
                      </span>
                      <span
                        v-if="isEditPermission && hasPermission('affiliates_email_edit')"
                        class="action success"
                        @click="handleChangeMail"
                      >
                        <ui-icon
                          name="pen"
                          class="success"
                          lib="fa"
                        />
                        {{ $t(`affiliates.affiliatesInfo.edit`) }}
                      </span>
                    </div>
                    <div class="field">
                      <span
                        v-show="info.messenger_id && info.messenger_id"
                        :class="info.messenger"
                        class="messenger"
                      >
                        <a
                          :href="getMessangerLink()"
                          rel="noopener noreferrer"
                          target="_blank"
                        >{{ info.messenger_id }}</a>
                      </span>
                    </div>
                    <div class="field">
                      <span class="label">{{ $t(`affiliates.cardView.info.birthDay`) }}</span>
                      <div
                        v-if="info.date_of_birth"
                        class="values"
                      >
                        {{ info.date_of_birth | formatDate4 }}
                      </div>
                      <div
                        v-else
                        class="values empty"
                      >
                        {{ $t('affiliates.affiliatesInfo.not_specefied') }}
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="field">
                      <span class="label">{{ $t(`affiliates.cardView.info.country`) }}</span>
                      <div
                        v-if="getContryName"
                        class="values"
                      >
                        {{ getContryName }}
                      </div>
                      <div
                        v-else
                        class="values empty"
                      >
                        {{ $t('affiliates.affiliatesInfo.not_specefied') }}
                      </div>
                    </div>
                    <div class="field">
                      <span class="label">{{ $t(`affiliates.cardView.info.city`) }}</span>
                      <div
                        v-if="info.city"
                        class="values"
                      >
                        {{ info.city }}
                      </div>
                      <div
                        v-else
                        class="values empty"
                      >
                        {{ $t('affiliates.affiliatesInfo.not_specefied') }}
                      </div>
                    </div>
                    <div class="field">
                      <span class="label">{{ $t(`affiliates.cardView.info.postal_code`) }}</span>
                      <div
                        v-if="info.postal_code"
                        class="values"
                      >
                        {{ info.postal_code }}
                      </div>
                      <div
                        v-else
                        class="values empty"
                      >
                        {{ $t('affiliates.affiliatesInfo.not_specefied') }}
                      </div>
                    </div>
                    <div class="field">
                      <span class="label">{{ $t(`affiliates.cardView.info.address`) }}</span>
                      <div
                        v-if="info.address"
                        class="values"
                      >
                        {{ info.address }}
                      </div>
                      <div
                        v-else
                        class="values empty"
                      >
                        {{ $t('affiliates.affiliatesInfo.not_specefied') }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="!info.referred_by && isUpdateReferralProgram"
                  class="field field-info"
                >
                  <div class="field-info_title">
                    <span>{{ $t('affiliates.cardView.info.introducedEmpty') }}</span>
                  </div>
                  <div class="field-info_controls">
                    <span
                      :style="{color: $theme.accentColor}"
                      class="action"
                      @click="checkSensitiveAction('introducedPin')"
                    >
                      <ui-icon
                        name="link"
                        substyle="fas"
                        :color="$theme.accentColor"
                        lib="fa"
                      />
                      {{ $t(`affiliates.affiliatesInfo.pin`) }}
                    </span>
                  </div>
                </div>
                <div
                  v-if="info.referred_by"
                  class="field field-info"
                >
                  <div class="field-info_title">
                    <span>{{ $t('affiliates.cardView.info.introduced') }}:</span> {{ info.referred_by_info.email }}
                  </div>
                  <div class="field-info_controls">
                    <span
                      v-if="info.referred_by_info.permissions.view"
                      class="action success"
                      @click="handleIntroducedCommand('open')"
                    >
                      <ui-icon
                        name="info-circle"
                        substyle="fas"
                        class="success"
                        lib="fa"
                      />
                      {{ $t(`affiliates.affiliatesInfo.openInfo`) }}
                    </span>
                    <span
                      v-if="isEditPermission && isUpdateReferralProgram"
                      class="action decline"
                      @click="checkSensitiveAction('introducedUnpin')"
                    >
                      <ui-icon
                        name="unlink"
                        substyle="fas"
                        class="decline"
                        lib="fa"
                      />
                      {{ $t(`affiliates.affiliatesInfo.unpin`) }}
                    </span>
                  </div>
                </div>
                <div
                  v-if="info.team_name"
                  class="field field-info"
                >
                  <div class="field-info_title">
                    <span>{{ $t('affiliates.cardView.info.team') }}:</span> {{ info.team_name }}
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="title">
                <div class="title-content">
                  <span>{{ $t('affiliates.affiliatesInfo.report_setting') }}</span>
                  <span
                    v-if="!editReporting && isReportingSettings && isEditPermission && (hasPermission('affiliates_reporting_settings_cpa_profit_visibility_edit') || hasPermission('affiliates_reporting_settings_rs_profit_visibility_edit'))"
                    style="color: #20815e"
                    class="action"
                    @click="editReportingSettings"
                  >
                    <ui-icon
                      name="pen"
                      color="#20815e"
                      lib="fa"
                    />
                    {{ $t(`affiliates.affiliatesInfo.edit`) }}
                  </span>
                  <div
                    v-else-if="editReporting && isReportingSettings"
                    class="values"
                  >
                    <span
                      :style="{color: $theme.dangerColor}"
                      class="action"
                      @click="cancelEdit('reportSettings')"
                    >
                      <ui-icon
                        name="times"
                        :color="$theme.dangerColor"
                        lib="fa"
                      />
                      {{ $t(`affiliates.affiliatesInfo.cancel`) }}
                    </span>
                    <span
                      style="color: #20815e"
                      class="action"
                      @click="saveEdit('reportSettings')"
                    >
                      <ui-icon
                        name="check"
                        color="#20815e"
                        lib="fa"
                      />
                      {{ $t(`affiliates.affiliatesInfo.save`) }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="card-content">
                <div class="field without-padding">
                  <div class="card">
                    <div class="field field-solo">
                      <div class="column">
                        <el-checkbox
                          v-model="reports.cpa_profit_viewable"
                          :disabled="!isEditPermission || !hasPermission('affiliates_reporting_settings_cpa_profit_visibility_edit') || !editReporting"
                          class="checkbox"
                        >
                          {{ $t(`affiliates.affiliatesInfo.cpa_profit`) }}
                        </el-checkbox>
                        <el-checkbox
                          v-model="reports.referred_cpa_profit_viewable"
                          :disabled="!isEditPermission || !hasPermission('affiliates_reporting_settings_cpa_profit_visibility_edit') || !editReporting"
                          class="checkbox"
                        >
                          {{ $t(`affiliates.affiliatesInfo.cpa_profit_for_aff`) }}
                        </el-checkbox>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="field field-solo">
                      <div class="column">
                        <el-checkbox
                          v-model="reports.revshare_profit_viewable"
                          :disabled="!isEditPermission || !hasPermission('affiliates_reporting_settings_rs_profit_visibility_edit') || !editReporting"
                          class="checkbox"
                        >
                          {{ $t(`affiliates.affiliatesInfo.refshare_profit`) }}
                        </el-checkbox>
                        <el-checkbox
                          v-model="reports.referred_revshare_profit_viewable"
                          :disabled="!isEditPermission || !hasPermission('affiliates_reporting_settings_rs_profit_visibility_edit') || !editReporting"
                          class="checkbox"
                        >
                          {{ $t(`affiliates.affiliatesInfo.refshare_profit_for_aff`) }}
                        </el-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- media_buying -->
            <media-buying-setting-card
              :id="id"
              :info="info"
              :is-edit-permission="isEditPermission"
              :has-permission="hasPermission('affiliates_media_buying_edit')"
              :edit-buying="editBuying"
              :customer-currency="wallets.currency_code"
              :media-buying="mediaBuying"
              :get-contry-name="getContryName"
              @cancelEdit="cancelEdit"
              @saveEdit="fetchAffiliateData"
            />

            <div class="card">
              <div class="title">
                <div class="title-content">
                  <span>{{ $t('affiliates.affiliatesInfo.notifications_setting') }}</span>
                  <span
                    v-if="isEditPermission && hasPermission('affiliates_domain_change_notifications_edit')"
                    style="color: #20815e"
                    class="action"
                    @click="handleEditNotificationsStatus"
                  >
                    <ui-icon
                      name="pen"
                      color="#20815e"
                      lib="fa"
                    />
                    {{ $t(`affiliates.affiliatesInfo.edit`) }}
                  </span>
                </div>
              </div>
              <div class="card-content">
                <div class="field without-padding">
                  <div class="card">
                    <div class="field field-solo">
                      <div class="column">
                        <div
                          v-if="info.service_domain_changes_notifications_enabled"
                          class="column-text"
                        >
                          <ui-icon
                            v-if="info.service_domain_changes_notifications_channel === 'email'"
                            :size="14"
                            lib="fa"
                            :color="$theme.accentColor"
                            substyle="fas"
                            name="envelope"
                            class="ico"
                          />
                          <span
                            v-else
                            class="messenger telegram"
                          />
                          {{ $t(`affiliates.affiliate_notifications.type.${info.service_domain_changes_notifications_channel}`) }}
                        </div>
                        <div
                          v-else
                          class="column-text red-text"
                        >
                          {{ $t(`affiliates.affiliate_notifications.disabled`) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <rotation-groups-card
              :id="id"
              ref="rotationGroupsCard"
              :edit-permission="isEditPermission && hasPermission('affiliates_rotation_groups_edit') && hasPermission('service_rotation_groups_view' )"
            />

            <div class="card">
              <div class="title">
                <span>{{ $t(`affiliates.affiliatesInfo.multiaccounts`) }}</span>
              </div>
              <div class="card-content">
                <div v-if="multiaccounts.length > 0">
                  <router-link
                    v-for="item in multiaccounts"
                    :key="item.id"
                    class="field field-solo site"
                    :to="`/affiliates/${item.id}/info`"
                  >
                    {{ item.email }}
                  </router-link>
                </div>
                <div
                  v-if="multiaccounts.length === 0"
                  class="field"
                >
                  <span class="empty">{{ $t('affiliates.affiliatesInfo.not_specefied') }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="right-side">
            <div v-if="info.is_public_profile_enabled" class="card">
              <div class="title">
                <div class="title-content">
                  <span>{{ $t(`affiliates.public_profile.title`) }}</span>
                  <div v-if="isEditPermission" class="ui-d-flex">
                    <span v-if="info.public_profile" class="action delete" @click="handleDeletePublicProfile">
                      <ui-icon name="trash-alt" substyle="fas" lib="fa" />
                      {{ $t('crm.buttons.delete') }}
                    </span>
                    <span
                      style="color: #20815e"
                      class="action ui-m-xl-l"
                      @click="$refs.editPublicProfile.open(info.public_profile)"
                    >
                      <ui-icon
                        name="pen"
                        color="#20815e"
                        substyle="fas"
                        lib="fa"
                      />
                      {{ $t('affiliates.affiliatesInfo.edit') }}
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="info.public_profile" class="card-content">
                <div class="field">
                  <el-tooltip
                    placement="left"
                    :content="info.public_profile.name"
                  >
                    <span class="label name">{{ info.public_profile.name }}</span>
                  </el-tooltip>
                </div>
                <div
                  v-for="(contact, i) in info.public_profile.contacts"
                  :key="i"
                  class="field"
                >
                  <el-tooltip
                    placement="left"
                    class="w-100"
                    :content="contact.value"
                  >
                    <div class="ui-d-flex">
                      <ui-icon
                        v-if="contact.type === 'email'"
                        :size="14"
                        lib="fa"
                        :color="$theme.accentColor"
                        substyle="fas"
                        name="envelope"
                        class="ico"
                      />
                      <span v-else class="messenger" :class="contact.type" />
                      <a
                        :href="getMessangerLink(contact.type, contact.value)"
                        class="social-link value"
                        :target="contact.type === 'email' && '_blank'"
                      >
                        {{ contact.value }}
                      </a>
                    </div>
                  </el-tooltip>
                </div>
                <div v-if="info.public_profile.additional_information" class="field misc" v-html="info.public_profile.additional_information" />
              </div>
              <div v-else class="no-public-profile">
                <i class="fas fa-user ui-m-md-r" />
                {{ $t('affiliates.public_profile.no_data') }}
              </div>
            </div>
            <div
              v-if="hasPermission('affiliates_activity_log_view')"
              class="card"
            >
              <div class="title">
                <div class="title-content">
                  <span>{{ $t(`affiliates.cardView.info.historyTitle`) }}</span>
                  <span
                    style="color: #20815e"
                    class="action"
                  >
                    <ui-icon
                      name="history"
                      color="#20815e"
                      substyle="fas"
                      lib="fa"
                    />
                    <router-link
                      :to="`/affiliates/${id}/activity`"
                    >{{ $t(`affiliates.cardView.info.historyLink`) }}</router-link>
                  </span>
                </div>
              </div>
              <div class="card-content">
                <div />
                <div v-if="activity.length > 0">
                  <div
                    v-for="(item, index) in activity"
                    :key="index"
                    class="point field"
                  >
                    <span class="time">{{ item.action_timestamp | formatDate3 }}</span>
                    <span class="value">{{ item.action_name }}</span>
                  </div>
                </div>

                <div
                  v-if="activity.length === 0"
                  class="field"
                >
                  <div class="field__empty-text">
                    <ui-icon
                      :size="32"
                      name="folder-open"
                      lib="fa"
                      substyle="fal"
                      color="#a6b2c4"
                    />
                    <div>{{ $t(`affiliates.cardView.info.noHistoryTitle`) }}</div>
                  </div>
                </div>
              </div>
            </div>
            <Notes
              v-if="hasPermission('affiliates_notes_view')"
              :add-note-permission="isEditPermission && hasPermission('affiliates_notes_edit')"
              :affiliate-id="$route.params.id"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { setTimeout } from 'timers';
import { NAV_SET_SUB_VIEW } from '@/store/action_types/nav';

import ResetPasswordPopup from '@/components/Popups/ResetPasswordPopup.vue';
import Notes from '@/components/Notes.vue';
import AffiliateSubPopupTemporary from '@/components/Popups/AffiliateSubPopupTemporary.vue';
import RotationGroupsCard from './components/RotationGroupsCard.vue';
import MediaBuyingSettingCard from './components/MediaBuyingSettingCard.vue';
import errorHandleMixin from '@/views/mixins/error-hadle';
import EditPublicProfile from '@/views/Affiliates/Affiliate/components/EditPublicProfile.vue';

const formatCurrencies = ({ code, name, ...restParams }) => ({
  code,
  name: `${code} - ${name}`,
  ...restParams,
});

export default {
  name: 'AffiliateInfo',
  components: {
    EditPublicProfile,
    Notes,
    ResetPasswordPopup,
    AffiliateSubPopupTemporary,
    RotationGroupsCard,
    MediaBuyingSettingCard,
  },
  mixins: [errorHandleMixin],
  props: {
    info: {
      type: Object,
      default: () => ({
        site_url: [],
      }),
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
    multiaccounts: {
      type: Array,
      default: () => ([]),
    },
    isParentDataLoading: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      isLoading: false,
      currencyFilterValue: '',
      activeColor: this.$theme.accentColor,
      inactiveColor: this.$theme.dangerColor,
      confirmData: {},
      targetAffiliate: {},
      errorWallet: [],
      editReporting: false,
      editBuying: false,
      isEditPayment: false,
      id: '',
      availableCurrencies: [],
      currentCurrency: null,
      newEmail: '',
      infoDump: {},
      wallets: {},
      errors: {},
      isConfirmOpen: false,
      confirmMsg: '',
      confirmAction: '',
      confirmTitle: '',
      confirmType: '',
      actionName: '',
      actionFaIcon: '',
      actionIcon: '',
      isResetOpen: false,
      isPinOpen: false,
      popupInfo: {},
      notification: {
        service_domain_changes_notifications_enabled: false,
        service_domain_changes_notifications_channel: '',
      },
      payment: {
        site_payment_method_id: null,
        payment_wallet: null,
        payments_net: '',
        vat: '',
        payments_negative_carryover: '',
        payments_enabled: false,
        reason: '',
      },

      commissions: {
        deposits_cpa_commission: '',
        withdrawals_cpa_commission: '',
        deposits_rs_commission: '',
        withdrawals_rs_commission: '',
      },
      limits: {
        max_api_keys: '',
        max_postbacks_per_traffic_source: '',
        max_promo_codes: '',
        max_tags_per_traffic_source: '',
        max_traffic_sources: '',
        allow_promo_code_create_or_edit: false,
      },
      balance: {
        operation: 'deposit',
        sum: '',
        note: '',
      },
      activity: [],
      isDataLoading: true,
      reports: {
        cpa_profit_viewable: false,
        referred_cpa_profit_viewable: false,
        revshare_profit_viewable: false,
        referred_revshare_profit_viewable: false,
      },
      mediaBuying: {
        media_buying_enabled: false,
        media_buying_auto_approve_posts_cost_per_player: null,
        media_buying_auto_approve_posts_max_amount: null,
        media_buying_balance_limit: null,
        media_buying_pending_balance: null,
        media_buying_limits_currency_code: null,
        media_buying_pending_balance_currency_code: null,
      },
      // isOpenRotationGroupsPopup: false,
      // rotationGroups: [],
      editBls: {
        type: 'success',
        title: '',
        msg: '',
        action: '',
        actionName: this.$t('affiliates.confirm.edit_balance'),
      },
      confirmChangeMail: {
        title: '',
      },
      confirmEditLimits: {
        type: 'success',
        title: '',
        action: '',
        actionFaIcon: 'check',
        actionName: this.$t('affiliates.confirm.save'),
      },
      confirmEditPayment: {
        type: 'success',
        title: '',
        msg: '',
        action: '',
        actionFaIcon: 'check',
        actionName: this.$t('affiliates.confirm.save'),
      },
      editAffiliateOpen: false,
      confirmEditInfo: {
        type: 'success',
        title: '',
        action: '',
        actionName: this.$t('affiliates.confirm.save'),
      },
      editSiteUrl: {
        type: 'success',
        title: '',
        msg: '',
        action: '',
        actionName: this.$t('affiliates.confirm.save'),
      },
      changeNotificationsStatusTitle: this.$t('affiliates.affiliate_notifications.popup.title'),
      sitesList: [],
      infoCreatePayment: {},
      popoverDisabled: true,
      defaultBirthDateView: this.$moment().subtract(18, 'year').format('YYYY-MM-DD'),
      birthPickerOptions: {
        disabledDate(time) {
          return time.setFullYear(time.getFullYear() + 18) > Date.now();
        },
      },
      filterValue: '',
      paymentMethodsByCurrencyCode: [],
    };
  },
  computed: {
    ...mapGetters({
      countries: 'misc/countries',
      currencies: 'misc/currencies',
      groups: 'misc/groups',
      paymentMethods: 'misc/paymentMethods',
      paymentPeriods: 'misc/paymentPeriods',
      messengersInfo: 'misc/apiMessengers',
      currentAcl: 'auth/currentAcl',
      adminAcl: 'auth/adminAcl',
      reportsSettings: 'reports/reportsSettings',
      panelSettings: 'reports/panelSettings',
    }),
    promoCodesOption() {
      return [
        {
          id: null,
          text: this.$t('affiliates.affiliatesInfo.max_default'),
        },
        {
          id: false,
          text: this.$t('permissions.deny'),
        },
        {
          id: true,
          text: this.$t('permissions.allow'),
        },
      ];
    },
    currencyOptions() {
      if (!this.$_.isEmpty(this.activeCurrency)) {
        return this.$_.uniqBy([this.activeCurrency, ...this.availableCurrencies], 'code');
      }

      return this.availableCurrencies;
    },
    filteredCurrencyOptions() {
      return this.currencyOptions.filter(el => el.name.toLowerCase().includes(this.currencyFilterValue.toLowerCase()));
    },
    activeCurrency() {
      return this.currencies.find(currency => currency.code === this.wallets.currency_code) || {};
    },
    currencyPrefix() {
      return this.activeCurrency.symbol || `${this.wallets.currency_code || ''} `;
    },
    notificationsType() {
      return [
        {
          id: 'telegram',
          name: 'Telegram',
          disabled: !this.panelSettings.telegram_bot.bot_username,
        },
        {
          id: 'email',
          name: 'E-mail',
        },
      ];
    },

    affiliateStatisticSupported() {
      return !!this.reportsSettings.dimensions
        .map(dim => dim.column_name)
        .find(grouping => grouping === 'affiliate_email');
    },

    paymentMethodsWithEmpty() {
      return [
        {
          id: null,
          payments_negative_carryover: '',
          name: this.$t('affiliates.affiliatesInfo.not_specefied'),
        },
      ].concat(this.$_.cloneDeep(this.paymentMethodsByCurrencyCode)
        .sort((x, y) => {
          if (x.id === this.payment.site_payment_method_id) {
            return -1;
          }
          return x.is_disabled - y.is_disabled;
        })
        .filter(el => el.name.toLowerCase().includes(this.filterValue.toLowerCase())));
    },
    noteData: {
      get() {
        return this.confirmData ? this.confirmData : {};
      },
    },
    isEditLimits() {
      return this.calcPermissions('edit_limits');
    },
    isViewReports() {
      return this.calcPermissions('view_reports');
    },
    affiliateStats() {
      return {
        id: this.info.id,
        email: this.info.email,
      };
    },
    reportParam() {
      return `/reports/custom?affiliate_stats=${encodeURIComponent(this.$qs.stringify(this.affiliateStats))}`;
    },
    isEditBalance() {
      return this.calcPermissions('edit_balance');
    },
    isEditAffiliate() {
      return this.calcPermissions('edit');
    },
    isEditCurrencies() {
      return this.info.account_status === 'approved' && this.isEditAffiliate && this.isEditBalance && this.hasPermission('affiliates_edit');
    },
    isCreatePayment() {
      return this.calcPermissions('create_payments')
        && this.info.payments_enabled
        && this.info.account_status === 'approved';
    },
    isEditStatus() {
      return this.calcPermissions('account_status_actions');
    },
    isResetTotp() {
      return this.calcPermissions('reset_totp');
    },
    isPaymentMethod() {
      return this.calcPermissions('update_payment_method');
    },
    isReportingSettings() {
      return this.calcPermissions('update_reporting_settings');
    },
    isEditPass() {
      return this.calcPermissions('update_password');
    },
    isEditPermission() {
      return this.calcPermissions('edit') && this.hasPermission('affiliates_edit');
    },
    isUpdateReferralProgram() {
      return this.calcPermissions('update_referral_program');
    },

    placeholderWallet() {
      if (this.paymentMethods.length !== 0 && this.payment.site_payment_method_id) {
        return this.$_.find(this.paymentMethods, e => e.id === this.payment.site_payment_method_id).wallet_placeholder;
      }
      return '';
    },
    placeholderMessenger() {
      if (this.messengersInfo.length !== 0 && this.popupInfo.messenger) {
        return this.$_.find(this.messengersInfo, e => e.id === this.popupInfo.messenger).example_placeholder;
      }
      return '';
    },
    isDataLoadingComputed() {
      return this.isDataLoading || this.isParentDataLoading;
    },
    getContryName() {
      const c = this.countries.find(country => country.code === this.info.country_code);
      if (c) {
        return c.name;
      }
      return '';
    },
    isInfoEmpty() {
      return this.$_.isEmpty(this.info);
    },

    isShowButtons() {
      if (this.info.account_status === 'pending') {
        return (this.isEditStatus && this.hasPermission('affiliates_can_decline'))
          || (this.info.email_verified && this.isEditStatus)
          || (this.isCreatePayment && this.hasPermission('affiliates_payments_can_create'))
          || (this.isEditStatus && this.hasPermission('affiliates_can_approve'));
      }
      if (this.info.account_status === 'rejected') {
        return (!this.info.email_verified && this.isEditStatus)
          || (this.isCreatePayment && this.hasPermission('affiliates_payments_can_create'))
          || (this.isEditStatus && this.hasPermission('affiliates_can_revoke'));
      }
      return (this.info.account_status === 'approved' && this.isEditStatus && this.hasPermission('affiliates_can_block'))
        || (this.info.account_status === 'blocked' && this.isEditStatus && this.hasPermission('affiliates_can_unblock'))
        || (this.isCreatePayment && this.hasPermission('affiliates_payments_can_create'))
        || (this.isEditStatus && this.hasPermission('affiliates_can_revoke'))
        || (this.hasPermission('affiliates_can_login'))
        || this.isViewReports
        || (this.info.google2fa_enabled && this.isResetTotp)
        || (this.isEditPass && this.hasPermission('affiliates_password_edit'))
        || (!this.info.email_verified && this.isEditStatus);
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.params.id;
    if (!this.id) {
      this.$router.push('/affiliates/list');
      return;
    }
    this.fetchAffiliateData();

    next();
  },
  watch: {
    info: {
      handler(value) {
        this.isDataLoading = true;
        this.popupInfo = this.$_.cloneDeep(value);
        [
          this.payment.payments_enabled,
          this.payment.site_payment_method_id,
          this.payment.payment_wallet,
          this.payment.payments_net,
          this.payment.vat,
          this.payment.payments_negative_carryover,

          this.mediaBuying.media_buying_auto_approve_posts_cost_per_player,
          this.mediaBuying.media_buying_auto_approve_posts_max_amount,
          this.mediaBuying.media_buying_balance_limit,
          this.mediaBuying.media_buying_enabled,
          this.mediaBuying.media_buying_pending_balance,
          this.mediaBuying.media_buying_limits_currency_code,
          this.mediaBuying.media_buying_pending_balance_currency_code,

          this.reports.cpa_profit_viewable,
          this.reports.referred_cpa_profit_viewable,
          this.reports.revshare_profit_viewable,
          this.reports.referred_revshare_profit_viewable,
          this.notification.service_domain_changes_notifications_enabled,
          this.notification.service_domain_changes_notifications_channel,
        ] = [
          value.payments_enabled,
          value.site_payment_method_id,
          value.payment_wallet,
          value.payments_net,
          value.vat,
          value.payments_negative_carryover,

          value.media_buying_auto_approve_posts_cost_per_player,
          value.media_buying_auto_approve_posts_max_amount,
          value.media_buying_balance_limit,
          value.media_buying_enabled,
          value.media_buying_pending_balance,
          value.media_buying_limits_currency_code,
          value.media_buying_pending_balance_currency_code,

          value.cpa_profit_viewable,
          value.referred_cpa_profit_viewable,
          value.revshare_profit_viewable,
          value.referred_revshare_profit_viewable,
          value.service_domain_changes_notifications_enabled,
          value.service_domain_changes_notifications_channel,
        ];
        if (value.limits) {
          this.limits = this.$_.cloneDeep(value.limits);
        }
        if (value.id) {
          this.fetchWallets(value.id)
            .finally(() => {
              this.isDataLoading = false;
            });

          this.$nextTick(() => {
            this.$refs.rotationGroupsCard.getRotationGroupsForAffiliate(value.id);
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.$eventBus.$on('delete-note', note => this.deleteNote(note));
    this.id = this.$route.params.id;
    if (!this.id) {
      this.$router.push('/affiliates/list');
      return;
    }
    // this.getAffiliateMultiaccounts(this.id);

    if (this.hasPermission('affiliates_activity_log_view')) {
      this.getAffiliateActivity();
    }
  },

  created() {
    this.$eventBus.$on('handleEditBls', this.handleEditBls);
    this.$eventBus.$on('handleEditCurrency', this.handleEditCurrency);
    this.$eventBus.$on('handleEditPayment', this.handleEditPayment);
    this.$eventBus.$on('introducedPin', this.introducedPin);
    this.$eventBus.$on('introducedUnpin', this.introducedUnpin);
  },

  beforeDestroy() {
    if (!this.isStatic) {
      window.removeEventListener('resize', this.debouncedTableHeight);
    }
    this.$eventBus.$off('delete-note');
    this.$eventBus.$off('handleEditBls', this.handleEditBls);
    this.$eventBus.$off('handleEditCurrency', this.handleEditCurrency);
    this.$eventBus.$off('handleEditPayment', this.handleEditPayment);
    this.$eventBus.$off('introducedPin', this.introducedPin);
    this.$eventBus.$off('introducedUnpin', this.introducedUnpin);
  },
  methods: {
    customCurrencyFilter(value) {
      this.currencyFilterValue = value;
    },
    getMessangerLink(messenger = this.info.messenger, messengerId = this.info.messenger_id) {
      switch (messenger) {
      case 'email':
        return `mailto:${messengerId}`;
      case 'telegram':
        return `tg://resolve?domain=${messengerId}`;
      case 'skype':
        return `skype:${messengerId}?chat`;
      case 'viber':
        return `viber://chat?number=${messengerId}`;
      case 'whatsapp':
        return `https://api.whatsapp.com/send?phone=${messengerId.replace(/[^\d]/g, '')}`;
      case 'facebook':
        return `https://www.facebook.com/${messengerId}`;
      case 'instagram':
        return `https://www.instagram.com/${messengerId}`;
      case 'twitter':
        return `https://twitter.com/@${messengerId}`;
      default:
        return '#';
      }
    },
    deletePublicProfile() {
      this.isLoading = true;
      return this.$api.deleteAffiliatePublicProfile(this.$route.params.id)
        .then(() => this.fetchAffiliateData({ isSilent: true }))
        .finally(() => this.isLoading = false);
    },
    fetchAvailableCurrencies() {
      return this.$api.getAffiliateWalletsAvailableToSwitch(this.$route.params.id).then((res) => {
        this.availableCurrencies = res.data.payload.map(formatCurrencies);
      });
    },
    async fetchWallets(id = this.id) {
      const response = await this.$api.getAffiliateWallets(id);

      this.wallets = response.data.payload.find(wallet => wallet.status === 'active');

      this.$api.getPaymentMethods({ currency_code: this.wallets.currency_code }).then((res) => {
        this.paymentMethodsByCurrencyCode = res.data.payload;
      });

      return response;
    },
    handleEditCurrency() {
      this.fetchAvailableCurrencies();

      this.currentCurrency = this.wallets.currency_code;
      this.currencyFilterValue = '';
      this.$refs.editCurrency.open();
    },
    filterMethod(v) {
      this.filterValue = v;
    },
    checkSensitiveAction(action) {
      this.$eventBus.$emit('checkSensitiveAction', action);
    },

    hasPermission(permission) {
      return this.currentAcl[permission] === 'allow' || this.adminAcl.is_superuser;
    },

    calcPermissions(item) {
      return this.permissions[item];
    },

    isPopoverDisabled() {
      setTimeout(() => {
        const { wlt } = this.$refs;
        if (wlt !== undefined) {
          if (wlt.clientWidth < wlt.scrollWidth) {
            const style = wlt.currentStyle || window.getComputedStyle(wlt);
            this.popoverDisabled = style.textOverflow !== 'ellipsis';
            return;
          }
        }

        this.popoverDisabled = true;
      });
    },
    changeBalance() {
      const q = {
        operation_type: this.balance.operation,
        operation_description: this.balance.note,
        amount: +this.balance.sum,
      };
      this.$api.changeBalance(this.wallets.id, q)
        .then(() => {
          this.$refs.editBls.close();
        })
        .catch((error) => {
          this.errors = error.data.errors;
        });
    },
    handleEditBls() {
      this.editBls.title = this.$t('affiliates.confirm.title_1');
      this.editBls.msg = `${this.$t('affiliates.confirm.msg_1', { affiliateEmail: this.info.email })}`;
      this.editBls.actionFaIcon = 'check';
      this.$refs.editBls.open();
    },
    handleDeletePublicProfile() {
      this.confirmTitle = this.$t('affiliates.public_profile.confirm.title');
      this.confirmMsg = this.$t('affiliates.public_profile.confirm.msg');
      this.confirmType = 'warning';
      this.actionIcon = 'check';
      this.confirmAction = 'deletePublicProfile';
      this.actionName = this.$t('crm.buttons.delete');
      this.$refs.confirm.open();
    },
    handleEditNotificationsStatus() {
      this.editBls.title = this.$t('affiliates.confirm.title_1');
      this.editBls.msg = `${this.$t('affiliates.confirm.msg_1', { affiliateEmail: this.info.email })}`;
      this.$refs.changeNotificationsStatus.open();
    },
    saveBls() {
      this.confirmAction = 'editBls';
      this.confirmMsg = `${this.$t('affiliates.confirm.msg_2', { affiliateEmail: this.info.email })}`;
      this.actionName = this.$t('affiliates.confirm.action_2');
      this.confirmTitle = this.$t('affiliates.confirm.title_2');
      this.confirmType = 'success';
      this.actionFaIcon = 'check';
      this.$refs.confirm.open();
    },

    confirmPin({ affiliate, cb }) {
      this.targetAffiliate = this.$_.clone(affiliate);
      this.confirmTitle = `${this.$t('affiliates.affiliateSub.confirm.title_4', { subAffiliateEmail: this.info.email, affiliateEmail: affiliate.email })}`;
      this.confirmMsg = `${this.$t('affiliates.affiliateSub.confirm.msg_4', { subAffiliateEmail: this.info.email, affiliateEmail: affiliate.email })}`;
      this.confirmType = 'success';
      this.actionIcon = 'link';
      this.confirmAction = 'Pin';
      this.cbAction = cb;
      this.actionName = `${this.$t('affiliates.affiliateSub.confirm.action_4')}`;
      this.$refs.confirm.open();
    },
    cancelBls() {
      this.balance = {
        operation: 'deposit',
        sum: '',
        note: '',
      };
      this.errors = {};
      this.fetchAffiliateData();
    },
    addSite() {
      this.popupInfo.site_url.push('');
    },
    removeSite(i) {
      this.popupInfo.site_url.splice(i, 1);
    },
    handleEditLimits() {
      this.confirmEditLimits.title = this.$t('affiliates.confirm.limits_popup_title');
      this.$refs.confirmEditLimits.open();
      this.errors = {};
    },
    handleEditPayment() {
      this.confirmEditPayment.title = this.$t('affiliates.confirm.title_3');
      this.confirmEditPayment.msg = `${this.$t('affiliates.confirm.msg_3', { affiliateEmail: this.info.email })}`;
      this.$refs.confirmEditPayment.open();
      this.errors = {};
    },
    handleEditInfo() {
      this.confirmEditInfo.title = `${this.$t('affiliates.confirm.edit')} <span>${this.info.email}</span>`;
      this.$refs.confirmEditInfo.open();
      this.actionFaIcon = 'check';
      this.errors = {};
    },
    handleChangeMail() {
      this.newEmail = '';
      this.$refs.confirmChangeMail.open();
      this.confirmChangeMail.title = `${this.$t('affiliates.confirm.change_email_title', { name: `${this.info.first_name} ${this.info.last_name}` })}`;
      this.errors = {};
    },

    changeEmail() {
      this.errors = {};
      this.$api.postChangeEmail(this.id, this.newEmail).then(() => {
        this.$eventBus.$emit('update-email', this.newEmail);
        this.$refs.confirmChangeMail.close();
      })
        .catch((error) => {
          this.errors = error.data.errors;
        });
    },
    fetchAffiliateData({ isSilent = false } = {}) {
      if (!isSilent) {
        this.isDataLoading = true;
      }

      this.$api
        .getAffiliateInfo(this.id)
        .then((response) => {
          this.$emit('update:info', this.$_.cloneDeepWith(response.data.payload));
        })
        .finally(() => {
          this.isDataLoading = false;
          this.isPopoverDisabled();
        });
    },
    getAffiliateActivity() {
      this.isDataLoading = true;
      this.$api
        .getAffiliateActivity({
          affiliate_id: this.id,
          limit: 3,
          offset: 0,
        })
        .then((response) => {
          this.activity = this.$_.cloneDeep(response.data.payload);
        })
        .finally(() => {
          this.isDataLoading = false;
          this.isPopoverDisabled();
        });
    },
    pinAffiliateHandler(affiliate) {
      const pr = {
        referred_by: affiliate.referral_id,
      };
      this.$api
        .updateAffiliateReferral(this.id, pr)
        .then(() => {
          this.fetchAffiliateData();
          this.isPinOpen = false;
          this.cbAction({});
        })
        .catch((e) => {
          this.cbAction(e.data.errors);
        });
    },
    handleIntroducedCommand(command) {
      switch (command) {
      case 'open':
        this.$router.push({
          path: `/affiliates/${this.info.referred_by_info.id}/info`,
        });
        break;
      default:
        break;
      }
    },
    introducedPin() {
      this.isPinOpen = true;
    },
    handlePayment() {
      this.$api
        .createPaymentAffiliate(this.id)
        .then((response) => {
          this.infoCreatePayment = response.data.payload;
          if (this.infoCreatePayment.payment_created) {
            this.confirmPayment(this.infoCreatePayment.payment_created);
          } else {
            this.confirmPayment(this.infoCreatePayment.payment_created);
          }
        });
    },
    onConfirm() {
      this.isConfirmOpen = false;
      switch (this.confirmAction) {
      case 'Pin':
        this.pinAffiliateHandler(this.targetAffiliate);
        this.$refs.confirm.close();
        break;
      case 'editBls':
        this.changeBalance();
        this.$refs.confirm.close();
        break;
      case 'confirmPayment':
        this.$refs.confirm.close();
        this.fetchAffiliateData();
        break;
      case 'createPayment':
        this.handlePayment();
        this.$refs.confirm.close();
        break;
      case 'Block':
        this.blockAffiliate();
        this.$refs.confirm.close();
        break;
      case 'Unblock':
        this.unblockAffiliate();
        this.$refs.confirm.close();
        break;
      case 'Approve':
        this.approve();
        this.$refs.confirm.close();
        break;
      case 'To Review':
        this.revoke();
        this.$refs.confirm.close();
        break;
      case 'Decline':
        this.decline();
        this.$refs.confirm.close();
        break;
      case 'Decline w/':
        this.declineNote();
        this.$refs.confirm.close();
        break;
      case 'Unpin':
        this.unpinAffiliate();
        this.$refs.confirm.close();
        break;
      case 'resetTotp':
        this.reset_totp();
        this.$refs.confirm.close();
        break;
      case 'deleteNote':
        this.handleDeleteNote(this.noteData);
        this.$refs.confirm.close();
        break;
      case 'deletePublicProfile':
        this.deletePublicProfile().then(() => this.$refs.confirm.close());
        break;
      default:
        break;
      }
    },

    handleDeleteNote(note) {
      this.$eventBus.$emit('delete-note-component', note);
    },
    reset_totp() {
      this.$api
        .resetTotp(this.id)
        .then(() => {
          this.info.google2fa_enabled = false;
        });
    },
    unpinAffiliate() {
      const pr = {
        referred_by: null,
      };
      this.$api
        .updateAffiliateReferral(this.id, pr)
        .then(() => {
          this.fetchAffiliateData();
        });
    },
    confirmClose() {
      this.actionIcon = '';
      this.actionFaIcon = '';
    },
    confirmPayment(isCreated) {
      if (isCreated) {
        this.confirmAction = 'confirmPayment';
        this.confirmTitle = `${this.$t('affiliates.confirm.title_9')}`;
        this.confirmMsg = `${this.$t('affiliates.confirm.msg_9')}`;
        this.actionName = `${this.$t('affiliates.confirm.action_9')}`;
        this.confirmType = 'success';
        this.actionFaIcon = 'check';
        this.$refs.confirm.open();
      } else {
        this.confirmAction = 'confirmPayment';
        this.confirmTitle = `${this.$t('affiliates.confirm.title_10')}`;
        this.actionName = `${this.$t('affiliates.confirm.action_10')}`;
        this.confirmType = 'success';
        this.actionFaIcon = 'check';
        this.$refs.confirm.open();

        switch (this.infoCreatePayment.fail_reason) {
        case 'AFFILIATE_ACCOUNT_STATUS_IS_NOT_SUITABLE_ERROR':
        case 'AFFILIATE_HAS_NO_WALLET_ERROR':
        case 'AMOUNT_LOWER_THAN_MINIMUM_PAYMENT_ERROR':
        case 'AFFILIATE_PAYMENTS_DISABLED_ERROR':
        case 'SITE_PAYMENT_METHOD_IS_DISABLED_ERROR':
          this.confirmMsg = `${this.$t(`affiliates.confirm.${this.infoCreatePayment.fail_reason}`)}`;
          break;
        case 'PAYMENT_CANNOT_BE_CREATED_EARLIER_ERROR':
          this.confirmMsg = `${this.$t(`affiliates.confirm.${this.infoCreatePayment.fail_reason}`)} <span>${this.$moment(this.infoCreatePayment.next_payment_min_timestamp).locale(this.$i18n.locale).format('DD MMM YYYY HH:mm')}</span>`;
          break;
        default:
          break;
        }
      }
    },
    resetTotp() {
      this.confirmAction = 'resetTotp';
      this.confirmMsg = `${this.$t('affiliates.confirm.resetTotp_text', { name: this.info.email })}`;
      this.actionName = `${this.$t('affiliates.confirm.resetTotp_action')}`;
      this.confirmTitle = `${this.$t('affiliates.confirm.resetTotp_title')}`;
      this.confirmType = 'success';
      this.actionFaIcon = 'shield-check';
      this.$refs.confirm.open();
    },
    deleteNote(note) {
      this.confirmAction = 'deleteNote';
      this.confirmTitle = `${this.$t('affiliates.confirm.delete_note_title')}`;
      this.confirmMsg = `${this.$t('affiliates.confirm.delete_note_text')}`;
      this.actionName = `${this.$t('affiliates.confirm.delete_note_action')}`;
      this.confirmType = 'warning';
      this.actionFaIcon = 'trash-alt';
      this.confirmData = note;
      this.$refs.confirm.open();
    },
    createPayment() {
      this.confirmAction = 'createPayment';
      this.confirmMsg = `${this.$t('affiliates.confirm.msg_5', { affiliateEmail: this.info.email })}`;
      this.actionName = `${this.$t('affiliates.confirm.action_5')}`;
      this.confirmTitle = `${this.$t('affiliates.confirm.title_5')}`;
      this.confirmType = 'success';
      this.actionFaIcon = 'money-bill-wave-alt';
      this.$refs.confirm.open();
    },
    introducedUnpin() {
      this.confirmAction = 'Unpin';
      this.confirmMsg = `${this.$t('affiliates.confirm.msg_6', { refferedEmail: this.info.referred_by_info.email })}`;
      this.actionName = `${this.$t('affiliates.confirm.action_6')}`;
      this.confirmTitle = `${this.$t('affiliates.confirm.title_6', { refferedEmail: this.info.referred_by_info.email })}`;
      this.confirmType = 'warning';
      this.actionFaIcon = 'unlink';
      this.$refs.confirm.open();
    },

    blockAffiliateHandler() {
      this.confirmAction = 'Block';
      this.confirmMsg = `${this.$t('affiliates.affiliatesInfo.blockText', { affiliateEmail: this.info.email })}`;
      this.actionName = `${this.$t('affiliates.affiliatesInfo.block_action')}`;
      this.confirmTitle = `${this.$t('affiliates.affiliatesInfo.block_title', { affiliateEmail: this.info.email })}`;
      this.confirmType = 'warning';
      this.actionFaIcon = 'lock';
      this.$refs.confirm.open();
    },
    unblockAffiliateHandler() {
      this.confirmAction = 'Unblock';
      this.confirmMsg = `${this.$t('affiliates.affiliatesInfo.unblockText', { affiliateEmail: this.info.email })}`;
      this.actionName = `${this.$t('affiliates.affiliatesInfo.unblock_action')}`;
      this.confirmTitle = `${this.$t('affiliates.affiliatesInfo.unblock_title', { affiliateEmail: this.info.email })}`;
      this.confirmType = 'success';
      this.actionFaIcon = 'unlock';
      this.$refs.confirm.open();
    },
    approveAffiliateHandler() {
      this.confirmAction = 'Approve';
      this.confirmTitle = `${this.$t('affiliates.affiliatesInfo.approve_title', { affiliateEmail: this.info.email })}`;
      this.confirmMsg = `${this.$t('affiliates.affiliatesInfo.approveText', { affiliateEmail: this.info.email })}`;
      this.actionName = `${this.$t('affiliates.affiliatesInfo.approve_action')}`;
      this.confirmType = 'success';
      this.actionFaIcon = 'check';
      this.$refs.confirm.open();
    },
    revokeAffiliateHandler() {
      this.confirmAction = 'To Review';
      this.confirmTitle = `${this.$t('affiliates.affiliatesInfo.review_title', { affiliateEmail: this.info.email })}`;
      this.confirmMsg = `${this.$t('affiliates.affiliatesInfo.toReviewText', { affiliateEmail: this.info.email })}`;
      this.actionName = `${this.$t('affiliates.affiliatesInfo.review_action')}`;
      this.confirmType = 'success';
      this.actionFaIcon = 'check';
      this.$refs.confirm.open();
    },
    declineAffiliateHandler(w) {
      this.confirmAction = `Decline${w ? ' w/' : ''}`;
      this.confirmMsg = w ? `${this.$t('affiliates.affiliatesInfo.declineAndNotifyText', { affiliateEmail: this.info.email })}` : `${this.$t('affiliates.affiliatesInfo.declineText', { affiliateEmail: this.info.email })}`;
      this.actionName = w ? this.$t('affiliates.affiliatesInfo.decline_and_notify') : `${this.$t('affiliates.affiliatesInfo.decline')}`;
      this.confirmTitle = w ? `${this.$t('affiliates.confirm.title_8_1', { affiliateEmail: this.info.email })}` : `${this.$t('affiliates.confirm.title_8_2', { affiliateEmail: this.info.email })}`;
      this.confirmType = 'warning';
      this.actionFaIcon = w ? 'bell' : 'check';
      this.$refs.confirm.open();
    },
    blockAffiliate() {
      this.$api
        .blockAffiliate(this.id)
        .then(() => {
          this.fetchAffiliateData();
        });
    },
    unblockAffiliate() {
      this.$api
        .unblockAffiliate(this.id)
        .then(() => {
          this.fetchAffiliateData();
        });
    },
    resetPasswordAffiliate() {
      this.isResetOpen = true;
    },
    activateAffiliateHandler() {
      this.$api
        .activateAffiliate(this.id)
        .then(() => {
          this.fetchAffiliateData();
          this.$noty.info(this.$t('affiliates.confirm.aff_email_success'));
        })
        .catch(() => {
          this.$noty.info(this.$t('affiliates.confirm.aff_email_fail'));
        });
    },

    editReportingSettings() {
      this.editReporting = true;
      this.errors = {};
      this.infoDump = this.$_.cloneDeep(this.info);
    },

    cancelLimits() {
      this.errors = {};
      this.fetchAffiliateData();
    },
    saveLimits() {
      Object.keys(this.limits).forEach((key) => {
        if (this.limits[key] === '') {
          this.limits[key] = null;
        }
      }, this.limits);
      this.$api
        .updateAffiliateLimits(this.id, this.limits)
        .then(() => {
          this.$refs.confirmEditLimits.close();
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },
    cancelPayment() {
      this.errors = {};
      this.payment.reason = '';
      this.fetchAffiliateData();
    },
    savePayment() {
      this.payment.payment_wallet = this.payment.payment_wallet ? this.payment.payment_wallet : null;
      this.$api
        .updateAffiliatePayment(this.id, this.payment)
        .then(() => {
          this.payment.reason = '';
          this.$refs.confirmEditPayment.close();
          this.$eventBus.$emit('update-note-component', this.id);
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },
    saveCurrency() {
      this.$api.postAffiliateSwitchWallet({ affiliate_id: this.id, currency_code: this.currentCurrency })
        .then(() => {
          this.fetchWallets();
          this.fetchAvailableCurrencies();
          this.$refs.editCurrency.close();
        });
    },

    cancelNotificationsStatus() {
      this.errors = {};
      this.fetchAffiliateData();
    },
    saveNotificationsStatus() {
      this.$api.updateAffiliateNotificationsSettings(this.id, this.notification).then(() => {
        this.$refs.changeNotificationsStatus.close();
      })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },

    cancelEdit(setting) {
      this.$emit('update:info', this.$_.cloneDeep(this.infoDump));
      if (setting === 'mediaBuying') {
        this.editBuying = false;
        this.mediaBuying.media_buying_enabled = this.info.media_buying_enabled;
      } else {
        this.editReporting = false;
        [
          this.reports.cpa_profit_viewable,
          this.reports.referred_cpa_profit_viewable,
          this.reports.revshare_profit_viewable,
          this.reports.referred_revshare_profit_viewable,
        ] = [
          this.info.cpa_profit_viewable,
          this.info.referred_cpa_profit_viewable,
          this.info.revshare_profit_viewable,
          this.info.referred_revshare_profit_viewable,
        ];
      }
      this.errors = {};
    },
    saveEdit(/* setting */) {
      // if (setting === 'mediaBuying') {
      //   this.editBuying = false;
      //   this.updateMediaBuyingOptions();
      // } else {
      this.editReporting = false;
      this.updateReportingOptions();
      // }
    },

    declineNote() {
      this.$api
        .rejectAffiliate(this.id, true)
        .then(() => {
          this.fetchAffiliateData();
        });
    },
    decline() {
      this.$api
        .rejectAffiliate(this.id, false)
        .then(() => {
          this.fetchAffiliateData();
        });
    },
    approve() {
      this.$api
        .approveAffiliate(this.id, this.payment)
        .then(() => {
          this.$store.commit(`nav/${NAV_SET_SUB_VIEW}`, 'list');
          this.fetchAffiliateData();
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },
    revoke() {
      this.$api
        .revokeAffiliate(this.id)
        .then(() => {
          this.$store.commit(`nav/${NAV_SET_SUB_VIEW}`, 'review');
          this.fetchAffiliateData();
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },

    returnAffiliateInfo() {
      this.errors = {};
      this.fetchAffiliateData();
    },


    cancelInfo() {
      this.returnAffiliateInfo();
    },
    saveInfo() {
      if (this.popupInfo.date_of_birth) {
        this.popupInfo.date_of_birth = `${this.$moment(this.popupInfo.date_of_birth).format('YYYY-MM-DDT00:00:00.000')}Z`;
      } else {
        this.popupInfo = this.$_.omit(this.popupInfo, 'date_of_birth');
      }
      this.$api
        .putAffiliateInfo(this.id, this.popupInfo)
        .then((response) => {
          if (this.confirmAction === 'Approve') {
            this.$store.commit(`nav/${NAV_SET_SUB_VIEW}`, 'list');
          }
          this.$emit('update:info', this.$_.cloneDeepWith(response.data.payload));
          this.$refs.confirmEditInfo.close();
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },

    cancelSiteUrl() {
      this.returnAffiliateInfo();
    },

    savelSiteUrl() {
      this.$api.putAffiliateInfo(this.id, this.popupInfo).then((response) => {
        this.$emit('update:info', this.$_.cloneDeepWith(response.data.payload));
        this.$refs.editSiteUrl.close();
      })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },

    showBalanceHistory() {
      this.$router.push({
        path: `/affiliates/${this.id}/balance-history/${this.wallets.id}`,
      });
    },
    humanizePaymentMethod(value) {
      const pm = this.paymentMethods.find((el) => {
        if (el.id === value) {
          return true;
        }
        return false;
      });
      return pm ? pm.name : false;
    },
    updateReportingOptions() {
      return this.$api
        .updateAffiliatReportingOptions(this.id, this.reports).then(() => {
          this.fetchAffiliateData();
        });
    },
    // updateMediaBuyingOptions() {
    //   return this.$api
    //     .updateAffiliatMediaBuyingOptions(this.id, this.mediaBuying).then(() => {
    //       this.fetchAffiliateData();
    //     });
    // },
  },
};
</script>

<style lang="scss" scoped>
.no-public-profile {
  font-size: 16px;
  line-height: 1.25;
  text-align: center;
  color: #a6b2c4;
  padding: 16px 0;
}
.payment-select-item {
  text-overflow: ellipsis;
  display: block!important;
  max-width: 400px!important;

  &.currency-option {
    max-width: 430px!important;
  }
}
.input-error {
  font-size: 12px;
  height: 14px;
  line-height: 14px;
  font-style: italic;
  text-transform: uppercase;
  font-weight: 500;
  text-align: right;
  color: var(--danger-color);
  margin-top: 6px;
  width: 100%;
}

.affiliate-info {
  .edit-balance.ui-input {
    /deep/ .input-post-text {
      font-size: 13px;
      margin-left: 4px;
      margin-right: 8px;
    }
  }

  padding-bottom: 12px;
  .wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 90%;
    min-width: 1140px;

    .card-content {
      .values {
        margin-left: 8px;
        text-wrap: nowrap;
      }

      .social-link {
        color: inherit;
        text-decoration: none;
      }

      .field {
        padding: 8px 0;

        .label, .value {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          line-height: normal;
        }

        &.misc {
          white-space: pre-line;
        }

        .w-100 {
          width: 100%;
        }
      }
    }

    .personal-info {
      .card-content {
        .label {
          white-space: nowrap;
        }

        .values {
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          margin-left: 8px;
        }
      }
    }

    .select-wrap .select-block .label {
      padding-bottom: 0;
    }

    .toolbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eaeaea;
      padding: 12px 0;
      .btns {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        .login-link {
          color: inherit;
          user-select: none;
          text-decoration: none;
        }
        .action-btn {
          margin-right: 8px;
          flex: 1 1 auto;
        }
      }
      &.tools {
        justify-content: flex-start;
      }
    }
    .info {
      padding-top: 16px;
      display: flex;
      justify-content: space-between;
      .select,
      .input {
        width: 260px;
      }
      .sidebar {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-right: 30px;
        width: 275px;
        min-width: 275px;
        .block {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          width: 100%;
          padding: 16px 0 24px;
          .payment-head {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;
            .block-title {
              font-size: 20px;
            }
          }
          &:not(:last-of-type) {
            border-bottom: 1px solid #eaeaea;
          }
          .sub-block {
            width: 100%;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            &:not(:last-child) {
              margin-bottom: 16px;
            }
            .label {
              font-size: 14px;
              font-weight: 500;
              text-transform: uppercase;
              margin-bottom: 4px;
            }
            .value {
              font-size: 14px;
            }
            &.balance {
              cursor: pointer;
              .label {
                margin-bottom: 0;
                margin-top: 4px;
              }
              .value {
                font-size: 36px;
                color: #20815e;
                &.negative {
                  color: #d26370;
                }
              }
            }
          }
        }
      }
    }
  }

  .body {
    flex-grow: 10;
    display: flex;
    &-info{
      min-width: 556px;
      width: 100%;
      margin-right: 30px;
    }
  }
}

</style>
