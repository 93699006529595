export default {
  settings: {
    name: 'Settings',
    menu: {
      sites: 'Sites',
    },
    fields: {
      country_name: {
        title: 'Country',
      },
      desktop_domain: {
        title: 'Desktop',
      },
      mobile_domain: {
        title: 'Mobile',
      },
    },
    domains: {
      title: 'Sites Settings',
      homepage: 'Homepage',
      api: 'API',
      click: 'Click',
      adserver: 'Adserver',
      banners: 'Banners',
      promo: 'Promo',
      promo_api: 'Promo API',
      mail: 'Mail',
    },

    tokens: {
      title: 'Token Management',
      actions: 'Actions',
      create_token: 'Create Token',
      access_key: 'Access Key',
      secret_key: 'Secret Key',
      token_name: 'Token Name',
      enter_token_name: 'Enter Token Name',
      title_delete_token: 'Delete {token} Token?',
      msg_delete_token: 'Are you sure you want to delete <span>{token}</span>?',
      delete: 'Delete',
      service: 'Service',
      complete: 'Complete',

      fields: {
        id: {
          title: 'Access Key',
        },
        name: {
          title: 'Name',
        },
        service: {
          title: 'Service',
        },
        last_active: {
          title: 'Last Used',
        },
        created_at: {
          title: 'Created At',
        },
      },
    },
    fees: {
      brand: 'Brand',
      placeholder_country: 'Select Country',
      deposit: 'Deposit',
      withdrawal: 'Withdrawal',
      sport: 'Sport',
      casino: 'Casino',
      gaming_tax: 'Gaming Tax',
      admin: 'Admin',
      fees: 'Fees',
      default: 'Default',
      edit_fees: 'Edit Fees',
      add_country: 'Add Country',
    },
    paymentMethods: {
      title: 'Payment Methods',
      fields: {
        id: {
          title: 'ID',
        },
        status: {
          title: 'Status',
        },
        visibility: {
          title: 'Visibility',
        },
        name: {
          title: 'Name',
        },
        logo: {
          title: 'Logo',
        },
        export: {
          title: 'Export via API',
        },
        fileAttachments: {
          title: 'File Attachments',
        },
        minPaymentAmount: {
          title: 'Minimal payment',
        },
        currencies: {
          title: 'Currencies',
        },
        actions: {
          title: 'Actions',
        },
      },
      statuses: {
        active: 'Active',
        disabled: 'Disabled',
      },
      fileAttachments: {
        required: 'Required',
        allowed: 'Allowed',
        no: 'No',
      },
      visible: 'Visible',
      hidden: 'Hidden',
      addPaymentMethod: 'Add Payment Method',
      popup: {
        title: 'Add Payment Method',
        titleEdit: 'Edit Payment Method',
        name: 'Name',
        currencies: 'Currencies',
        placeholderCurrencies: 'Select Currencies',
        placeholderName: 'Payment Method Name',
        status: 'Status',
        statusHint: 'Disabled payment method will be hidden in Affiliates Panel, unavailable to be selected and unable to proceed payments.',
        visibility: 'Visibility',
        visibilityHint: 'Hidden payment method won’t be visible in Affiliates Panel but still can be selected for payments.',
        export: 'Export via API',
        walletRegexp: 'Wallet RegExp',
        walletPlaceholder: 'Wallet Placeholder',
        requiredAffiliateInfo: 'Required Affiliate Info',
        description: 'Description',
        descriptionPlaceholder: 'Your description goes here',
        yes: 'Yes',
        no: 'No',
        all: 'All',
        selected: 'Selected',
        allowed: 'Allowed',
        required: 'Required',
        fileAttachments: 'File Attachments',
        logo: 'Logo',
        minimalPaymentAmount: 'Minimal Payment Amount',
        minimalPaymentAmountCurrency: 'Minimal Payment Amount Currency',
        dropFileHereOr: 'Drop file here or',
        selectFile: 'click to upload',
        notSelected: 'Not Selected',
        requiredOptions: {
          phone_number: 'Phone Number',
          country_code: 'Country Code',
          address: 'Address',
          postal_code: 'Postal Code',
        },
        limitFileSize: 'The file size cannot exceed 100KB',
      },
    },
    mediaBuyingPaymentMethods: {
      title: 'Media Buying Settings',
      addCurrency: 'Add Currency',
      limits: 'Limits',
      default: 'Default',
      otherCurrencies: 'Other Currencies',
      fields: {
        id: {
          title: 'ID',
        },
        status: {
          title: 'Status',
        },
        name: {
          title: 'Name',
        },
        logo: {
          title: 'Logo',
        },
        currencies: {
          title: 'Currencies',
        },
        actions: {
          title: 'Actions',
        },
      },
    },
    systemSettings: {
      title: 'System Settings',
      affiliateProgramName: 'Affiliate Program Name',
      emailSettings: 'Email Settings',
      transactionalEmailSender: 'Transactional Email Sender',
      replyTo: 'Reply-To',
      serviceEmailHint: 'This email will be used as a sender in transactional messages, e.g.: noreply@example.com',
      supportEmail: 'Support Email',
      supportEmailHint: 'This email will be used as a reply-to address for transactional messages',
      recaptcha: 'ReCAPTCHA',
      v3PublicKey: 'v3 Public Key',
      v3SecretKey: 'v3 Secret Key',
      affiliatesTelegramBot: 'Affiliates Telegram Bot',
      affiliatesTelegramBotHint: 'Telegram Bot may be used for creating tracking URLs and notifications about domain replacement',
      botUsername: 'Bot Username',
      botToken: 'Bot Token',
      policies: 'Policies',
      policiesHint: 'These links will be placed on the sign up form',
      termsOfServiceURL: 'Terms of Service URL',
      privacyPolicyURL: 'Privacy Policy URL',
      supportContacts: 'Support Contacts',
      supportContactsHint: 'This information will be shown on the popup after successful sign up',
      skype: 'Skype',
      telegram: 'Telegram',
      email: 'Email',
      referralProgramDefaultSettings: 'Referral Program Default Settings',
      referralProgram: 'Referral Program',
      referralPercent: 'Referral Percent',
      enabled: 'Enabled',
      disabled: 'Disabled',
      paymentsDefaultSettings: 'Payments Default Settings',
      domainChangeNotifications: 'Domain Change Notifications',
      period: 'Period',
      negativeCarryover: 'Negative Carryover',
      enableNotifications: 'Enable notifications for affiliates by default',
      affiliateLimitsSettings: 'Affiliate Limits Settings',
      affiliateLimits: 'Affiliate Limits',
      maxApiKeys: 'Max API Keys',
      maxPromoCodes: 'Max Promo Codes',
      maxTrafficSources: 'Max Traffic Sources',
      maxPostbacksPerTrafficSource: 'Max Postbacks Per Traffic Source',
      maxTagsPerTrafficSource: 'Max Tags Per Traffic Source',
      maxBalanceToSetPaymentWallet: 'Max Balance to Set Payment Wallet',
      createAndEditPromoCodes: 'Create and Edit Promo Codes',
      createPromoCodes: 'Create Promo Codes',
      yes: 'Yes',
      no: 'No',
      on: 'On',
      off: 'Off',
      popup: {
        affiliateProgram: 'Affiliate Program',
        referralProgram: 'Referral Program',
        revenueCalculation: 'Revenue Calculation',
        ggr: 'GGR',
        ngr: 'NGR',
        net_project_profit: 'Net Project Profit',
        paymentPeriod: 'Payment Period',
        net7: 'Net 7',
        net15: 'Net 15',
        net30: 'Net 30',
        net60: 'Net 60',
        manual: 'Manual',
      },
    },
    systemDomains: {
      title: 'System Domains',
      default: 'Default',
      fields: {
        country_code: {
          title: 'Country',
        },
        front_domain: {
          title: 'Front Domain',
        },
        panel_domain: {
          title: 'Panel Domain',
        },
        admin_domain: {
          title: 'Admin Domain',
        },
      },
      confirm: {
        addSystemDomains: 'Add System Domains',
        editSystemDomains: 'Edit System Domains',
        deleteSystemDomains: 'Delete System Domains',
        setAsDefaultDomains: 'Set as Default Domains',
        setAsDefault: 'Set as Default',
        confirmSetAsDefault: 'Are you sure you want to set provided domain names as default for  <b>{country_name}</b>?',
        confirmDelete: 'Are you sure you want to delete domain names for <b>{country_name}</b>?',

        country_code: 'Country',
        notSelected: 'Not Selected',
        front_domain: 'Front Domain',
        front_domain_placeholder: 'Enter Front Domain',
        panel_domain: 'Panel Domain',
        panel_domain_placeholder: 'Enter Panel Domain',
        admin_domain: 'Admin Domain',
        admin_domain_placeholder: 'Enter Admin Domain',
      },
    },
    predefinedPage: {
      title: 'Predefined Page URL’s',
      url: 'URL',
      addUrl: 'Add URL',
      missingTranslations: 'Missing translations found',
      confirm: {
        deleteUrl: {
          title: 'Delete {text}',
          msg: 'Are you sure you want to delete <b>{text}</b> from <b>{brandName}</b>?',
        },
        unsavedChanges: {
          title: 'Unsaved Changes',
          msg: 'There are unsaved changes. Do you want to save them or exit without saving?',
        },
      },
    },
    offersCountryGroups: {
      title: 'Offers Country Groups',
      addCountryGroup: 'Add New Country Group',
      fields: {
        name: {
          title: 'Name',
        },
        country_list: {
          title: 'Country List',
        },
      },
      confirm: {
        editCountryGroup: 'Edit Offers Country Group',
        addCountryGroup: 'Add Offers Country Group',
        country_list: 'Country List',
        name: 'Name',
        deleteOffersCountryGroup: 'Delete Offers Country Group',
        confirmDelete: 'Are you sure you want to delete offers country group <b>{name}</b>?',
        label: 'Specify Group Name and select countries for it',
      },
    },
    currencies: {
      title: 'Currencies',
      allCurrencies: 'All Currencies',
      currency: 'Currency',
      addCurrency: 'Add Currency',
      confirm: {
        currency: 'Currency',
        deleteCurrency: 'Delete Currency',
        confirmDelete: 'Are you sure you want to delete currency <b>{name}</b>?',
        setAsDefaultCurrency: 'Set as Default Currency',
        setAsDefault: 'Set as Default',
        confirmSetAsDefault: 'Are you sure you want to set <b>{currency}</b> as default currency?',
      },
      fields: {
        code: {
          title: 'Code',
        },
        name: {
          title: 'Name',
        },
        type: {
          title: 'Type',
        },
      },
    },
    buttons: {
      save: 'Save',
    },
  },
};
