import Vue from 'vue';
import ElementLocale from 'element-ui/lib/locale';
import { VueReCaptcha } from 'vue-recaptcha-v3';


// App modules
import App from '@/App';
import api from '@/api';
import i18n from '@/i18n';
import router from '@/router';
import store from '@/store';

// Init functions!
import LibsSetup from '@/setup/libs';
import PluginsSetup from '@/setup/plugins';
import ComponentsSetup from '@/setup/components';
import Directive from '@/setup/directive';
import FiltersSetup from '@/setup/filters';
import EventBusSetup from '@/setup/eventBus';
import HandlersSetup from '@/setup/handlers';
import NavigationGuardsSetup from '@/setup/navigationGuards';
import AnalyticsSetup from '@/setup/analytics';

import { config, loadStyles } from './config';
import fetchMiscSettings from '@/helpers/fetchMiscSettings';

Vue.config.performance = process.env.NODE_ENV !== 'production';
Vue.config.productionTip = process.env.NODE_ENV !== 'production';
Vue.config.devtools = process.env.NODE_ENV !== 'production';

const apiConfig = {
  store,
  router,
  affiliatesBaseUrl: `${config.apiBaseUrl}/admin`,
  miscBaseUrl: `${config.apiBaseUrl}/misc`,
};

NavigationGuardsSetup();

if (!Vue.prototype.$theme) {
  Object.defineProperty(Vue.prototype, '$theme', {
    value: config,
    writable: false,
  });
}

LibsSetup(Vue);
PluginsSetup(Vue);
ComponentsSetup(Vue);
Directive(Vue);
FiltersSetup(Vue, i18n);
EventBusSetup(Vue);

ElementLocale.i18n((key, value) => i18n.t(key, value));

Vue.use(api, apiConfig);

const initCaptcha = async () => {
  try {
    const {
      payload: {
        recaptcha: { public_key },
      },
    } = await fetchMiscSettings();

    Vue.use(VueReCaptcha, {
      siteKey: public_key,
      loaderOptions: {
        autoHideBadge: true,
      },
    });
  } catch (error) {
    window.console.error(error);
  }
};

initCaptcha();

const VueInstance = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
});

HandlersSetup(VueInstance);

loadStyles().then(async () => {
  await AnalyticsSetup(VueInstance);
  VueInstance.$mount('#app');
});

export default VueInstance;
