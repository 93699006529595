<template>
  <div
    v-loading="isDataLoading"
    class="affiliate-card"
  >
    <div class="head">
      <div class="breadcrumbs">
        <router-link
          v-if="info.account_status === 'pending'"
          class="back-link"
          to="/affiliates/review"
        >
          {{ $t('affiliates.cardView.toolbar.backLinks[1]') }}
        </router-link>
        <router-link v-else class="back-link" to="/affiliates/list">
          {{ $t('affiliates.cardView.toolbar.backLinks[0]') }}
        </router-link>
        <span v-if="this.$route.name === 'balance-history' || this.$route.name === 'activity'">
          <span class="divider">></span>
          <router-link
            :to="`/affiliates/${id}/info`"
            class="back-link"
          >{{ info.email }}</router-link>
          <span class="divider">></span>
          <span
            v-if="this.$route.name === 'balance-history'"
            class="email"
          >{{ $t('affiliates.cardView.toolbar.backLinks[2]') }}</span>
          <span
            v-if="this.$route.name === 'activity'"
            class="email"
          >{{ $t('affiliates.cardView.toolbar.backLinks[3]') }}</span>
        </span>
        <span v-else>
          <span class="divider">></span>
          <span class="email">{{ info.email }}</span>
        </span>
      </div>
      <div class="title-email">
        <span class="title-id">ID: {{ info.incrementing_id }}</span>
        <h2>{{ info.email }}</h2>
        <ui-icon
          :size="16"
          :name="info.account_status === 'blocked' ? 'lock' : info.account_status === 'rejected' ? 'times-circle' :
            info.account_status === 'approved' ? 'check' : 'eye'"
          :color="info.account_status === 'blocked' ? $theme.dangerColor : info.account_status === 'rejected' ? '#303634' :
            info.account_status === 'approved' ? $theme.accentColor : '#E1962B'"
          lib="fa"
          substyle="fas"
        />
      </div>
      <div class="sub-nav">
        <nav class="menu">
          <router-link
            v-for="item in $_.filter(nav, e => !(e.isHide && e.isHide()))"
            :key="item.name"
            :to="`/affiliates/${id}/${item.link}${item.params ? `/${item.params()}` : ''}`"
            :class="{active : isLinkActive(item.name)}"
            class="item"
          >
            <span
              :title="$t(`affiliates.cardView.nav.${item.name}`)"
              class="link-title"
            >
              {{ $t(`affiliates.cardView.nav.${item.name}`) }}
            </span>
          </router-link>
        </nav>
      </div>
    </div>
    <router-view
      :info.sync="info"
      :multiaccounts="multiaccounts"
      :permissions="permissions"
      :is-parent-data-loading="isDataLoading"
      class="view"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import app from '@/main';
import { NAV_SET_SUB_VIEW } from '@/store/action_types/nav';
import detectPermissions from '@/service/detectPermissions';

export default {
  name: 'AffiliatePayments',
  data() {
    return {
      isDataLoading: true,
      id: '',
      permissions: {},
      info: {
        site_url: [],
      },
      multiaccounts: [],
      nav: [
        {
          name: 'info',
          link: 'info',
        },
        {
          name: 'sub',
          link: 'sub-affiliates',
        },
        {
          name: 'offers',
          link: 'offers',
        },
        {
          name: 'kpi-bonus',
          link: 'kpi-bonus',
          params: () => this.info.account_status,
        },
        {
          name: 'promocodes',
          link: 'promo-codes',
        },
        {
          name: 'estpayment',
          link: 'payments-reports',
          isHide: () => this.isEditPayments(),
        },
        {
          name: 'payments',
          link: 'payments-history',
          isHide: () => this.isEditPayments(),
        },
        {
          name: 'dedication',
          link: 'dedication-domains',
          isHide: () => this.platformBettingDomainsView(),
        },
      ],
      currentCard: '',
    };
  },

  computed: {
    ...mapGetters('auth', ['currentTeam', 'adminAcl']),
  },

  watch: {
    id(id) {
      this.getAffiliateInfo();
      this.getAffiliateMultiaccounts(id);
    },
    $route() {
      if (this.info.account_status === 'pending') {
        this.$store.commit(`nav/${NAV_SET_SUB_VIEW}`, 'review');
      } else {
        this.$store.commit(`nav/${NAV_SET_SUB_VIEW}`, 'list');
      }
      this.currentCard = this.$route.matched[2].meta.view;
      if (this.id !== this.$route.params.id) {
        this.id = this.$route.params.id;
      }
    },
  },
  created() {
    app.$eventBus.$on('update-email', this.changeEmailEmit);
  },

  beforeDestroy() {
    app.$eventBus.$off('update-email', this.changeEmailEmit);
  },

  mounted() {
    if (detectPermissions.checkRequest('affiliates_view')) {
      this.id = this.$route.params.id;
      this.currentCard = this.$route.matched[2].meta.view;
      if (!this.id) {
        this.$router.push('/affiliates/list');
      }
    }
    detectPermissions.checkRoute('affiliates_view');
  },
  methods: {
    isEditPayments() {
      const isUser = !this.adminAcl.is_superuser && this.permissions.view_payments;
      const isSuperUser = this.adminAcl.is_superuser;
      return !(isSuperUser || isUser);
    },

    platformBettingDomainsView() {
      const isUser = !this.adminAcl.is_superuser
        && this.adminAcl.permissions[this.currentTeam].platform_betting_domains_view === 'allow';
      const isSuperUser = this.adminAcl.is_superuser;
      return !(isSuperUser || isUser);
    },

    changeEmailEmit(email) {
      this.info.email = email;
    },
    addSubAffiliateHandler() {},
    isLinkActive(name) {
      return this.currentCard === name;
    },
    getAffiliateInfo() {
      this.isDataLoading = true;
      this.$api.getAffiliateInfo(this.id)
        .then((response) => {
          this.isDataLoading = false;
          this.info = this.$_.cloneDeepWith(response.data.payload);
          this.permissions = this.$_.cloneDeepWith(response.data.misc.permissions);
          if (this.info.account_status === 'pending') {
            this.$store.commit(`nav/${NAV_SET_SUB_VIEW}`, 'review');
          } else {
            this.$store.commit(`nav/${NAV_SET_SUB_VIEW}`, 'list');
          }
        });
    },

    async getAffiliateMultiaccounts(id) {
      try {
        const { data: { payload } } = await this.$api.getAffiliateMultiaccounts(id);
        this.multiaccounts = payload;
      } catch (error) {
        this.multiaccounts = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .affiliate-card {
    .head {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      width: 90%;
      min-width: 1140px;
      padding: 16px 0 0 0;
    }
  }
  .title-id {
    font-size: 24px;
    color: #7d7d7d;
    margin-right: 4px;
  }
</style>
