<template>
  <div
    v-if="isOpen"
    class="add-blacklist-popup"
  >
    <div class="popup">
      <div class="head">
        <span class="name">
          {{ id ? $t('mediaBuying.blacklistUrls.popup.titleEdit') : $t('mediaBuying.blacklistUrls.popup.title') }}
        </span>
        <ui-icon
          :size="20"
          lib="fa"
          substyle="fas"
          class="close"
          name="times"
          @click.native="close"
        />
      </div>
      <adm-ui-scrollbar
        wrap-style="max-height: 75vh; height: auto;"
        :native="false"
      >
        <div
          v-loading="isLoading"
          class="body"
        >
          <div class="ui-fg-1">
            <div v-for="(_, i) in urls" :key="i" class="ui-m-md-b">
              <div class="ui-d-flex ui-ai-center ">
                <ui-input
                  v-model="urls[i]"
                  placeholder="URL"
                  size="big"
                  autocomplete="off"
                  type="text"
                  class="form-input ui-fg-1"
                  :class="{ error: getError('url') || getError('urls') || getError(['urls', i]) }"
                  @keydown.enter.prevent
                  @input="clearError(i)"
                />
                <div v-show="i !== 0" class="btn-cross" @click.prevent="popUrl(i)">
                  <i class="fas fa-times" />
                </div>
              </div>

              <div
                v-if="getError('url') || getError('urls') || getError(['urls', i])"
                class="field-error"
              >
                {{ getError('url') || getError('urls') || getError(['urls', i]) }}
              </div>
            </div>

            <ui-button
              v-if="!id"
              :disabled="urls.length >= 20"
              lib="fa"
              substyle="fas"
              icon="plus"
              @click="addUrl"
            >
              {{ $t('mediaBuying.blacklistUrls.add') }}
            </ui-button>
          </div>
        </div>
      </adm-ui-scrollbar>
      <div class="foot">
        <ui-button
          color="red"
          lib="fa"
          substyle="fas"
          icon="times"
          :disabled="isLoading"
          @click="close()"
        >
          {{ $t('crm.buttons.cancel') }}
        </ui-button>
        <ui-button
          :color="$theme.mainColor"
          :disabled="isLoading"
          filled
          lib="fa"
          substyle="fas"
          icon="check"
          @click="handleSave()"
        >
          {{ id ? $t('mediaBuying.blacklistUrls.edit') : $t('mediaBuying.blacklistUrls.add') }}
        </ui-button>
      </div>
    </div>
    <div class="wrapper" />
  </div>
</template>


<script>
import errorHandleMixin from '@/views/mixins/error-hadle';

export default {
  name: 'BlacklistUrlPopup',
  components: {},
  mixins: [errorHandleMixin],
  data() {
    return {
      isOpen: false,
      isLoading: false,
      id: null,
      urls: [''],
    };
  },
  methods: {
    clearError(i) {
      if (this.getError(['urls', i])) {
        this.errors.urls[i] = '';
      }
    },
    open({ id, url } = {}) {
      this.isLoading = false;
      this.id = null;
      this.urls = [''];

      this.isOpen = true;

      this.errors = {};

      if (id) {
        this.id = id;
        this.urls[0] = url;
      }
    },
    popUrl(i) {
      this.urls.splice(i, 1);
    },
    addUrl() {
      this.errors = {};
      this.urls.push('');
    },
    close() {
      this.isOpen = false;
    },
    handleSave() {
      this.isLoading = true;
      const urlsFiltered = this.urls.filter(e => e !== '');
      const urls = urlsFiltered.length ? urlsFiltered : [''];
      this.urls = urls;

      const promise = this.id
        ? this.$api.putMediaBuyingBlacklistUrls(this.id, { url: urls[0] })
        : this.$api.postMediaBuyingBlacklistUrls({ urls });

      promise
        .then(() => {
          this.close();
          this.$emit('save');
        })
        .catch(e => this.errors = e.data.errors)
        .finally(() => this.isLoading = false);
    },
  },
};
</script>

<style lang='scss' scoped>
.add-blacklist-popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;

  .field-error {
    align-self: flex-end;
    text-align: right;
    text-transform: uppercase;
    margin-top: 6px;
    margin-bottom: 0!important;
    font-weight: 500;
  }

  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
    width: 100%;
  }

  .popup {
    display: flex;
    flex-direction: column;
    z-index: 902;
    top: 8vh;
    width: 480px;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;

    .head {
      padding: 16px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eaeaea;

      .name {
        font-size: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
      }

      .close {
        font-size: 15px;
        cursor: pointer;

        &:hover {
          color: var(--main-color) !important;
        }
      }
    }

    .body {
      padding: 16px 24px;
      display: flex;
      position: relative;
      max-height: 84vh;

      .btn-cross {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        width: 32px;
        height: 32px;
        border-radius: 5px;
        border: solid 1px #20815e;
        margin-left: 8px;
      }
    }

    .foot {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eaeaea;
      padding: 16px 24px;
      .ui-button + .ui-button{
        margin-left: 8px;
      }
      .plus-icon-custom{
        margin-right: 4px;
      }
    }
  }
}
</style>
