import store from '@/store';

const actions = {
  async kpiBonusTypes({ commit }) {
    try {
      const { data: { payload } } = await store.$api.getAffiliateKpiBonusTypes();
      commit('kpiBonusTypes', payload);
    } catch (error) {
      throw error;
    }
  },
};

const mutations = {
  kpiBonusTypes(state, payload) {
    state.kpiBonusTypes = payload.available_types;
  },
};

const state = {
  kpiBonusTypes: [],
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
