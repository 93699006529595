export default {
  theHeader: {
    menu: {
      dashboards: {
        main: 'Dashboards',
        overview: 'Overview',
        add_dashboard: 'Add Dashboard',
        templates: 'Templates',
      },
      live: {
        main: 'Live',
        'last-bets': 'Last Bets & Deposits',
        'top-events': 'Top Events',
      },
      reports: {
        main: 'Reports',
        report: 'Report',
        paymentMethod: 'Payment Methods',
      },
      affiliates: {
        main: 'Affiliates',
        list: 'Affiliate List',
        review: 'Affiliate Review',
        kpiBonuses: 'KPI Bonus',
        notifications: 'Notifications',
        requests: 'Affiliate Requests',
        groups: 'Groups',
      },
      payments: {
        main: 'Payments',
        dashboard: 'Dashboard',
        payment_payout_section: 'Payments Payout',
        payment_moderation_section: 'Payments Moderation',
        on_review: 'On Review',
        approved: 'Approved',
        declined: 'Declined',
        not_paid: 'Not Paid',
        processing: 'Processing',
        paid: 'Paid',
        for_rework: 'For Rework',
        failed: 'Failed',
        payment_cpa_section: 'CPA',
        cpa_conversions: 'CPA Conversions',
      },
      players: {
        main: 'Players',
      },
      tools: {
        main: 'Tools',
        promoChecker: 'Promo Codes Checker',
        landingLeagues: 'Landing Leagues',
        rotation_groups: 'Rotation Groups',
        service_domains: 'Service Domains',
        servers: 'Servers',
        dns_hosting_accounts: 'DNS Hosting Accounts',
        domain_registrar_accounts: 'Domain Registrar Accounts',
        dedicated_site_domains: 'Dedicated Site Domains',
        'marketing-materials': 'Promo Materials',
        'promo-materials': 'Promo Materials',
        'promo-codes': 'Promo Codes',
        dms: 'DMS',
      },
      teams: {
        main: 'Teams',
      },
      'media-buying': {
        main: 'Media Buying',
        reports_section: 'Reports',
        summary_reports: 'Summary Report',
        blacklist: 'Blacklist Wallets',
        blacklist_urls: 'Blacklist URL’s',
        unreported_funds: 'Unreported Funds',
        payment_payout_section: 'Payments Payout',
        payment_moderation_section: 'Payments Moderation',
        posts_section: 'Posts',
        on_review: 'On Review',
        for_rework: 'For Rework',
        approved: 'Approved',
        declined: 'Declined',
        not_paid: 'Not Paid',
        processing: 'Processing',
        paid_out: 'Paid Out',
        failed: 'Failed',
      },
      settings: {
        main: 'Settings',
        domains: 'Product Domain',
        fees: 'Fees',
        affiliateProgram: 'Control Tower',
        currencies: 'Currencies',
        paymentMethod: 'Payment Methods',
        systemSettings: 'System Settings',
        systemDomains: 'System Domains',
        mediaBuying: 'Media Buying',
        predefinedPage: 'Predefined Page URL’s',
        offersCountryGroups: 'Offers Country Groups',
      },
      crm: {
        main: 'CRM',
      },
      test: 'Test',
    },
    language: {
      language: 'Language',
      ru: 'Russian',
      en: 'English',
    },
    profile: {
      main: 'My Profile',
      logout: 'Logout',
      all_teams: 'All teams',
    },
    confirm: {
      title: 'Sign Out',
      msg: 'Do you want to sign out?',
      action: 'Sign Out',
    },
  },
};
