<template>
  <div v-loading="isDataLoading" class="media-buying-info">
    <post-blacklist-popup
      v-show="isBlacklistOpen"
      ref="subpin"
      :campaign-id="info.campaign_id"
      :is-open="isBlacklistOpen"
      @close="isBlacklistOpen = false"
    />
    <ui-confirm
      ref="confirm"
      :width="480"
      :type="confirmType"
      :action-name="actionName"
      :action="confirmAction"
      :action-icon="actionIcon"
      :action-fa-icon="actionFaIcon"
      @save="onConfirm"
      @close="confirmClose"
    >
      <div slot="title" v-html="confirmTitle" />
      <div class="body__wrap">
        <i
          v-if="
            confirmAction === 'Rework' ||
              confirmAction === 'Approve' ||
              confirmAction === 'Review'
          "
          :class="confirmType"
          class="fas fa-exclamation-triangle"
        />
        <i v-else :class="confirmType" class="fas fa-ban" />
        <div class="body__msg" v-html="confirmMsg" />
      </div>
    </ui-confirm>
    <div class="head">
      <div v-if="!$_.isEmpty(info)" class="breadcrumbs">
        <router-link
          class="back-link"
          :to="`/media-buying/posts/${
            info.status ? info.status.replace('_', '-') : ''
          }`"
        >
          {{ $t(`mediaBuying.page_title.posts.name`) }}
          {{
            info.status ? $t(`mediaBuying.page_title.posts.${info.status}`) : ''
          }}
        </router-link>
        <span>
          <span class="divider">></span>
          <span class="email">{{
            $t(`mediaBuying.page_title.posts.post`)
          }}</span>
        </span>
      </div>
      <div class="title-email">
        <h2>
          <span style="margin-right: 4px">ID: {{ info.incrementing_id }} </span>{{
            $t('mediaBuying.info.post_title', {
              affiliate: info.affiliate_email,
            })
          }}
          ({{ info.created_at | formatDate3 }})
          <ui-icon
            v-if="info.needs_attention"
            :color="$theme.dangerColor"
            size="20px"
            lib="fa"
            substyle="fas"
            name="exclamation-circle"
          />
        </h2>
      </div>
    </div>
    <div v-if="hideToolbar" class="toolbar">
      <ui-button
        v-if="info.status !== 'for_rework'"
        icon="check"
        lib="fa"
        substyle="fas"
        color="green"
        filled
        class="action-btn"
        @click="approveHandler()"
      >
        {{ $t('mediaBuying.actions.approve') }}
      </ui-button>
      <ui-button
        v-if="info.status !== 'for_rework'"
        icon="sync-alt"
        lib="fa"
        substyle="fas"
        color="green"
        class="action-btn"
        @click="reworkHandler()"
      >
        {{ $t('mediaBuying.actions.send_for_rework') }}
      </ui-button>
      <ui-button
        :icon="
          !info.needs_attention
            ? 'triangle-exclamation'
            : 'circle-exclamation-check'
        "
        lib="fa"
        substyle="fa-6"
        :color="!info.needs_attention ? 'red' : 'green'"
        class="action-btn"
        @click="markAsRead"
      >
        {{
          !info.needs_attention
            ? $t('mediaBuying.other.btn_mark')
            : $t('mediaBuying.other.btn_unmark')
        }}
      </ui-button>
      <ui-button
        icon="ban"
        lib="fa"
        substyle="fas"
        color="red"
        class="action-btn"
        @click="addToBlacklist()"
      >
        {{ $t('mediaBuying.other.add_to_blacklist') }}
      </ui-button>
      <ui-button
        v-if="info.status !== 'for_rework'"
        icon="times"
        lib="fa"
        substyle="fas"
        color="red"
        class="action-btn"
        @click="declineHandler()"
      >
        {{ $t('mediaBuying.actions.decline') }}
      </ui-button>
      <ui-button
        v-if="info.status === 'for_rework'"
        icon="check-double"
        lib="fa"
        substyle="fas"
        color="green"
        class="action-btn"
        @click="reviewHandler()"
      >
        {{ $t('mediaBuying.actions.send_to_review') }}
      </ui-button>
    </div>
    <div v-else class="toolbar">
      <ui-button
        :icon="
          !info.needs_attention
            ? 'triangle-exclamation'
            : 'circle-exclamation-check'
        "
        lib="fa"
        substyle="fa-6"
        :color="!info.needs_attention ? 'red' : 'green'"
        class="action-btn"
        @click="markAsRead"
      >
        {{
          !info.needs_attention
            ? $t('mediaBuying.other.btn_mark')
            : $t('mediaBuying.other.btn_unmark')
        }}
      </ui-button>
    </div>
    <div class="info">
      <affiliate-info-card :currency-label="currencyLabel" :info="info" />

      <div class="media-info">
        <div class="card">
          <div class="card-title-wrapper">
            <div class="title">
              <span>{{ $t(`mediaBuying.info.post_info_card.title`) }}</span>
            </div>
            <div class="card-title-statuses">
              <div class="status" :class="info.status">
                {{ $t(`mediaBuying.status.moderation`) }}
                <span class="values">{{
                  $t(`mediaBuying.status.${info.status}`)
                }}</span>
              </div>
            </div>
          </div>
          <div class="card-content">
            <div class="field">
              <div class="requested">
                <span>
                  {{ $t(`mediaBuying.info.payment_info_card.requested_at`) }}
                  {{ info.review_requested_at | formatDate3 }}
                </span>

                <span class="card-content__span-updated">
                  <ui-icon size="12px" lib="fa" substyle="fas" name="history" />
                  {{ $t(`mediaBuying.info.payment_info_card.updated_at`) }}
                  {{ info.updated_at | formatDate3 }}
                </span>
              </div>
              <span class="label big-num request-summ">
                {{ getCurrencyLabel(info.currency_code) | uppercase }}{{ info.amount | formatMoney }}
                <div
                  v-if="info.currency_code !== currencyCode"
                  class="amount_usd"
                >
                  <div class="divider" />
                  <span> {{ currencyLabel }}{{ info.amount_usd | formatMoney }} </span>
                </div>
              </span>
              <div class="from">
                {{ $t(`mediaBuying.info.payment_info_card.from`) }}
                <router-link
                  v-if="hasPermission('view_profile')"
                  :to="`/affiliates/${info.affiliate_id}/info`"
                  class="email"
                >
                  {{ info.affiliate_email }}
                </router-link>
                <span v-else>
                  {{ info.affiliate_email }}
                </span>
              </div>
              <div v-if="info.team_name" class="field ui-fd-row">
                <span class="label word-break">{{ $t('mediaBuying.other.team') }}:</span>
                <div class="values ui-m-sm-l">
                  {{ info.team_name }}
                </div>
              </div>
            </div>
            <div class="field without-padding">
              <div class="card new-card two-elems">
                <div class="field">
                  <span class="label">{{
                    $t(`mediaBuying.info.post_info_card.period`)
                  }}</span>
                  <span class="values">
                    {{ formatDate(info.post_period_start) }} -
                    {{ formatDate(info.post_period_end) }}
                  </span>
                </div>
                <div class="field">
                  <span class="label">{{
                    $t(`mediaBuying.info.payment_info_card.type`)
                  }}</span>
                  <div v-if="info.type" class="values">
                    {{ $t(`mediaBuying.types.${info.type}`) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="urlFilter.length" class="card">
          <div class="title">
            <span>{{ $t(`mediaBuying.info.post_info_card.urls`) }}</span>
          </div>
          <div class="card-content">
            <div v-for="(url, key) in urlFilter" :key="key" class="field">
              <a :href="url.url" target="_blank" class="label link ui-ai-center">
                <img style="width: 17px" :src="getIcon(url)" alt="">
                <span>{{ url.url }}</span>
              </a>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="title">
            <span>{{ $t(`mediaBuying.info.post_info_card.description`) }}</span>
          </div>
          <div class="card-content">
            <div class="field">
              <span class="label description pre-line">
                {{ info.description }}
              </span>
            </div>
          </div>
        </div>

        <div class="stats-double-block">
          <div class="card">
            <div class="title">
              <span>{{ $t('mediaBuying.other.aff_stats') }}</span>
            </div>
            <div class="card-content">
              <div class="field">
                <span class="label word-break">{{ statisticTranslate('registrations_count') }}</span>
                <div class="values">
                  {{ affiliateStats.registrations_count | formatMoneyNoFrac }}
                </div>
              </div>
              <div class="field">
                <span class="label word-break">{{ statisticTranslate('first_deposits_count') }}</span>
                <div class="values">
                  {{ affiliateStats.first_deposits_count | formatMoneyNoFrac }}
                </div>
              </div>
              <div class="field">
                <span class="label word-break">{{ statisticTranslate('deposits_sum_usd') }}</span>
                <div class="values">
                  {{ currencyLabel }}{{ affiliateStats.deposits_sum_usd | formatMoney }}
                </div>
              </div>
              <div class="field">
                <span class="label word-break">{{ statisticTranslate('profit_sum_usd') }}</span>
                <div
                  class="values"
                >
                  {{ currencyLabel }}{{ affiliateStats.profit_sum_usd | formatMoney }}
                </div>
              </div>
              <div class="field">
                <span class="label word-break">{{ statisticTranslate('total_profit_sum_usd') }}</span>
                <div :class="colorClass(affiliateStats.total_profit_sum_usd)" class="values">
                  {{ currencyLabel }}{{ affiliateStats.total_profit_sum_usd | formatMoney }}
                </div>
              </div>
              <div class="field">
                <span class="label word-break">{{ statisticTranslate('cost_per_player_usd') }}</span>
                <div class="values">
                  {{ currencyLabel }}{{ affiliateStats.cost_per_player_usd | formatMoney }}
                </div>
              </div>
            </div>
          </div>
          <!-- 2 -->
          <div class="card">
            <div class="ui-d-flex ui-ai-center">
              <div class="title ui-fg-1">
                <span>{{ $t('mediaBuying.other.camp_stats') }}</span>
              </div>
              <campaign-statistic-filter-popup
                :props-period="statsPeriod"
                @apply-filters="applyCampaignStatistics"
              />
            </div>
            <div class="card-content">
              <div class="field">
                <span class="label word-break">{{ statisticTranslate('registrations_count') }}</span>
                <div class="values">
                  {{ campaignStats.registrations_count | formatMoneyNoFrac }}
                </div>
              </div>
              <div class="field">
                <span class="label word-break">{{ statisticTranslate('first_deposits_count') }}</span>
                <div class="values">
                  {{ campaignStats.first_deposits_count | formatMoneyNoFrac }}
                </div>
              </div>
              <div class="field">
                <span class="label word-break">{{ statisticTranslate('deposits_sum_usd') }}</span>
                <div class="values">
                  {{ currencyLabel }}{{ campaignStats.deposits_sum_usd | formatMoney }}
                </div>
              </div>
              <div class="field">
                <span class="label word-break">{{ statisticTranslate('profit_sum_usd') }}</span>
                <div class="values">
                  {{ currencyLabel }}{{ campaignStats.profit_sum_usd | formatMoney }}
                </div>
              </div>
              <div class="field">
                <span class="label word-break">{{ statisticTranslate('total_profit_sum_usd') }}</span>
                <div
                  :class="colorClass(campaignStats.total_profit_sum_usd)"
                  class="values"
                >
                  {{ currencyLabel }}{{ campaignStats.total_profit_sum_usd | formatMoney }}
                </div>
              </div>
              <div class="field">
                <span class="label word-break">{{ statisticTranslate('cost_per_player_usd') }}</span>
                <div class="values">
                  {{ currencyLabel }}{{ campaignStats.cost_per_player_usd | formatMoney }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Comments
          :is-editable="hasPermission('post_comments')"
          :comments="comments"
          :post-id="postId"
          :comments-loading.sync="commentsLoading"
          @post-comments="postComment"
          @get-comments="getComments"
        />
      </div>
      <div class="media-add-info">
        <div class="card">
          <div class="title">
            <span>{{ $t(`mediaBuying.info.files_card.title`) }}</span>
          </div>
          <div class="card-content">
            <div
              v-for="(item, index) in info.files"
              :key="index"
              class="field media-field"
            >
              <div class="media-item">
                <div class="media-item_wrapper">
                  <span>{{ item.filename }}</span>
                  <el-image
                    v-if="
                      item.mime_type === 'image/jpeg' ||
                        item.mime_type === 'image/png'
                    "
                    :src="item.preview_url"
                    :preview-src-list="srcList"
                    :fit="'contain'"
                  />
                </div>
                <el-dropdown
                  v-if="
                    item.mime_type === 'image/jpeg' ||
                      item.mime_type === 'image/png'
                  "
                  trigger="hover"
                  placement="top"
                >
                  <div class="el-dropdown-link">
                    <ui-icon
                      lib="fa"
                      substyle="fas"
                      :color="$theme.accentColor"
                      name="eye"
                    />
                    <el-dropdown-menu class="img-preview">
                      <el-dropdown-item>
                        <img :src="item.preview_url">
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </div>
                </el-dropdown>
              </div>
              <div class="values">
                <a
                  :style="{ color: $theme.accentColor }"
                  :href="item.download_url"
                  :download="item.filename"
                  target="_blank"
                  class="action"
                >
                  <ui-icon name="download" :color="$theme.accentColor" lib="fa" />
                  {{ $t(`mediaBuying.info.files_card.download`) }}
                </a>
              </div>
            </div>
            <div v-if="info.files && info.files.length === 0" class="field">
              <div class="field__empty-text">
                <ui-icon
                  :size="32"
                  name="folder-open"
                  lib="fa"
                  substyle="fal"
                  color="#a6b2c4"
                />
                <div>{{ $t(`mediaBuying.info.files_card.no_files`) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Comments from '@/components/Comments.vue';
import app from '@/main';
import detectPermissions from '../../service/detectPermissions';
import AffiliateInfoCard from './components/AffiliateInfoCard.vue';
import PostBlacklistPopup from './components/PostBlacklistPopup.vue';
import CampaignStatisticFilterPopup from './components/CampaignStatisticFilterPopup.vue';
import updateUrl from '@/service/updateUrl';

export default {
  name: 'RequestInfo',
  components: {
    Comments,
    AffiliateInfoCard,
    PostBlacklistPopup,
    CampaignStatisticFilterPopup,
  },

  data() {
    return {
      confirmType: '',
      actionName: '',
      confirmAction: '',
      actionFaIcon: '',
      actionIcon: '',
      confirmTitle: '',
      confirmMsg: '',
      isDataLoading: true,
      query: {},
      info: {},
      currencyCode: '',
      permissions: {},
      channelInfo: {},
      campaignInfo: {},
      commentsLoading: true,
      comments: [],
      isBuyingPopupOpen: false,
      isBlacklistOpen: false,
      declineType: '',
      activity: [
        {
          description: 'Profile updated by Owner',
          timestamp: '2019-11-13 17:15:58',
        },
        {
          description: 'Profile updated by Owner',
          timestamp: '2019-11-13 17:15:58',
        },
        {
          description: 'Profile updated by Owner',
          timestamp: '2019-11-13 17:15:58',
        },
      ],
    };
  },

  computed: {
    ...mapGetters('reports', ['reportsSettings']),
    ...mapGetters({
      currentAcl: 'auth/currentAcl',
      adminAcl: 'auth/adminAcl',
      currencies: 'misc/currencies',
    }),
    ...mapGetters('misc', ['postPlatform']),
    currencyLabel() {
      return this.getCurrencyLabel(this.currencyCode);
    },
    statsPeriod() {
      const { stats_period_start, stats_period_end } = this.query;

      if (stats_period_start && stats_period_end) {
        return [stats_period_start, stats_period_end];
      }

      return null;
    },
    affiliateStats() {
      return this.info.affiliate?.stats || {};
    },

    campaignStats() {
      return this.info.campaign?.stats || {};
    },

    urlFilter() {
      if (this.info.urls) {
        return this.info.urls.filter(el => el.url.trim() !== '');
      }
      return [];
    },

    srcList() {
      return (
        this.info.files
          .filter(img => img.preview_url !== null)
          .map(img => img.preview_url) || []
      );
    },

    hideToolbar() {
      return (
        (this.adminAcl.is_superuser
          && (this.info.status === 'on_review'
            || this.info.status === 'for_rework'))
        || (!this.adminAcl.is_superuser
          && this.permissions.review_actions
          && (this.info.status === 'on_review'
            || this.info.status === 'for_rework'))
      );
    },

    postId() {
      return this.$route.params.id;
    },
  },

  async mounted() {
    if (detectPermissions.checkRequest('media_buying_posts_view')) {
      await this.initNeedsAttantion();
      this.getPostInfo();
    }
    detectPermissions.checkRoute('media_buying_posts_view');

    const _urlData = updateUrl.getParseParams(this.$route.hash);
    const { stats_period_start, stats_period_end } = !this.$_.isEmpty(_urlData) ? _urlData : this.$ls.get('MediaBuyingPost/stats') || {};

    if (stats_period_start && stats_period_end) {
      this.query = {
        stats_period_start: stats_period_start ? this.$moment(stats_period_start).startOf('day').format('YYYY-MM-DD HH:mm:ss') : '',
        stats_period_end: stats_period_end ? this.$moment(stats_period_end).endOf('day').format('YYYY-MM-DD HH:mm:ss') : '',
      };
    }
  },
  methods: {
    getCurrencyLabel(code) {
      if (code === undefined) return '';

      const currency = this.currencies.find((el => el.code === code));

      return currency?.symbol || `${code} `;
    },
    applyCampaignStatistics(period) {
      if (period) {
        const [stats_period_start, stats_period_end] = period;

        this.query = {
          stats_period_start: stats_period_start ? this.$moment(stats_period_start).startOf('day').format('YYYY-MM-DD HH:mm:ss') : '',
          stats_period_end: stats_period_end ? this.$moment(stats_period_end).endOf('day').format('YYYY-MM-DD HH:mm:ss') : '',
        };

        this.$ls.set('MediaBuyingPost/stats', {
          stats_period_start,
          stats_period_end,
        });
      } else {
        this.query = this.$_.omit(this.query, 'stats_period_start', 'stats_period_end');
        this.$ls.set('MediaBuyingPost/stats', null);
      }

      this._completedFilterUrl();
      this.getPostInfo();
    },
    _completedFilterUrl() {
      const _dataFilters = {
        stats_period_start: this.query.stats_period_start,
        stats_period_end: this.query.stats_period_end,
      };
      updateUrl.updateGetParams(_dataFilters);
    },
    async initNeedsAttantion() {
      if (this.$route.query.needs === 'success') {
        await this.$api.postMediaBuyingPostsMarkAsRead(this.$route.params.id);
        this.$router.replace({
          path: this.$route.path,
          query: { ...this.$route.query, needs: undefined },
        });
      }
    },

    colorClass(num) {
      return num >= 0 ? 'success' : 'danger';
    },

    statisticTranslate(value) {
      return this.$t(`mediaBuying.statistics.${value}`);
    },

    getIcon(url) {
      const id = url.url_platform_id || url.platform_id;
      return (this.postPlatform.find(el => el.id === id)).logo_base64;
    },

    hasPermission(kind) {
      return this.permissions[kind] || this.adminAcl.is_superuser;
    },

    hasGlobalPermission(kind) {
      return (
        (this.currentAcl[kind] === 'allow'
          && this.currentAcl.reports_group_by_affiliates === 'allow')
        || this.adminAcl.is_superuser
      );
    },

    getPostInfo() {
      if (!this.postId) return;

      this.isDataLoading = true;
      this.$api.getMediaBuyingPostsInfo(this.postId, this.query).then((response) => {
        this.info = this.$_.cloneDeepWith(response.data.payload);
        this.currencyCode = response.data.misc.currency_code;
        this.permissions = this.$_.cloneDeepWith(
          response.data.misc.permissions,
        );
        detectPermissions.checkRoute(
          'media_buying_posts_view',
          'media_buying_posts_statuses_visible',
          this.info.status,
        );
        this.getComments();
      })
        .finally(() => {
          this.isDataLoading = false;
        });
    },

    async markAsRead() {
      this.loadingMark = true;
      if (this.info.needs_attention) {
        await this.$api.postMediaBuyingPostsMarkAsRead(this.info.id);
        this.getPostInfo('silent');
      } else {
        await this.$api.postMediaBuyingPostsMarkAsUnread(this.info.id);
        this.getPostInfo('silent');
      }
      this.loadingMark = false;
    },

    postComment(comment) {
      this.commentsLoading = true;
      this.$api
        .postMediaBuyingPostsComments(this.postId, comment)
        .then(() => {
          this.getComments();
        })
        .catch(() => {
          this.commentsLoading = false;
        });
    },

    getComments() {
      if (!this.postId) return;

      this.$api
        .getMediaBuyingPostsComments(this.postId)
        .then((response) => {
          this.comments = this.$_.cloneDeepWith(response.data.payload);
          app.$eventBus.$emit('defaultCommentsState');
        })
        .finally(() => {
          this.commentsLoading = false;
        });
    },
    dataQuery() {
      const localMetrics = [
        'visits_count',
        'registrations_count',
        'players_with_bet_count',
        'profit_sum',
        'deposits_all_count',
        'bet_new_sports_count',
        'bet_new_games_count',
        'deposits_first_count',
      ];
      const metrics = this.reportsSettings.metrics
        .filter(item => localMetrics.includes(item.column_name))
        .map(item => item.column_name);

      return {
        dimensions: ['media_buying_campaign_name'],
        sorting: [
          {
            sort_by: 'media_buying_campaign_name',
            sort_dir: 'desc',
          },
        ],
        limit: 1,
        offset: 0,
        filters: {
          affiliate_id: {
            op: '=',
            value: [this.campaignInfo.affiliate_id],
          },
        },
        metrics,
        from: this.info.post_period_start,
        to: this.info.post_period_end,
        metrics_format: 'pretty',
        having: {},
        players_filter: 'all',
        traffic_type: 'all',
      };
    },

    formatDate(value) {
      return this.$moment(value)
        .locale(this.$i18n.locale)
        .format('DD MMM YYYY HH:mm');
    },
    reworkHandler() {
      this.confirmAction = 'Rework';
      this.confirmMsg = `${this.$t('mediaBuying.info.popup.rework_post.text')}`;
      this.actionName = `${this.$t(
        'mediaBuying.info.popup.rework_post.button',
      )}`;
      this.confirmTitle = `${this.$t(
        'mediaBuying.info.popup.rework_post.title',
      )}`;
      this.confirmType = 'success';
      this.actionFaIcon = 'check';
      this.$refs.confirm.open();
    },
    addToBlacklist() {
      this.isBlacklistOpen = true;
    },
    declineHandler() {
      this.confirmAction = 'Decline';
      this.confirmMsg = `${this.$t(
        'mediaBuying.info.popup.decline_post.text',
      )}`;
      this.actionName = `${this.$t(
        'mediaBuying.info.popup.decline_post.button',
      )}`;
      this.confirmTitle = `${this.$t(
        'mediaBuying.info.popup.decline_post.title',
      )}`;
      this.confirmType = 'warning';
      this.actionFaIcon = 'ban';
      this.$refs.confirm.open();
    },
    reviewHandler() {
      this.confirmAction = 'Review';
      this.confirmMsg = `${this.$t(
        'mediaBuying.info.popup.on_review_post.text',
      )}`;
      this.actionName = `${this.$t(
        'mediaBuying.info.popup.on_review_post.button',
      )}`;
      this.confirmTitle = `${this.$t(
        'mediaBuying.info.popup.on_review_post.title',
      )}`;
      this.confirmType = 'success';
      this.actionFaIcon = 'check';
      this.$refs.confirm.open();
    },
    approveHandler() {
      this.confirmAction = 'Approve';
      this.confirmMsg = `${this.$t(
        'mediaBuying.info.popup.approve_post.text',
      )}`;
      this.actionName = `${this.$t(
        'mediaBuying.info.popup.approve_post.button',
      )}`;
      this.confirmTitle = `${this.$t(
        'mediaBuying.info.popup.approve_post.title',
      )}`;
      this.confirmType = 'success';
      this.actionFaIcon = 'check';
      this.$refs.confirm.open();
    },
    onConfirm() {
      switch (this.confirmAction) {
      case 'Rework':
        this.$refs.confirm.close();
        this.changeStatus('for-rework');
        break;
      case 'Decline':
        this.$refs.confirm.close();
        this.changeStatus('decline');
        break;
      case 'Approve':
        this.$refs.confirm.close();
        this.changeStatus('approve');
        break;
      case 'Review':
        this.$refs.confirm.close();
        this.changeStatus('to-review');
        break;
      default:
        break;
      }
    },
    confirmClose() {
      this.actionIcon = '';
      this.actionFaIcon = '';
    },

    async changeStatus(status) {
      await this.$api.postMediaBuyingPostsChangeStatus(this.postId, status);
      const mapStatus = {
        approve: 'declined',
        decline: 'approved',
        'for-rework': 'for_rework',
        'to-review': 'on_review',
      };
      if (this.adminAcl.is_superuser) {
        await this.getPostInfo();
      } else if (
        this.currentAcl.media_buying_posts_statuses_visible.includes(
          mapStatus[status],
        )
      ) {
        await this.getPostInfo();
      } else {
        await this.$router.push(
          `/media-buying/posts/${
            this.info.status ? this.info.status.replace('_', '-') : ''
          }`,
        );
      }
    },
  },
};
</script>
