<template>
  <transition name="fade">
    <div
      v-if="isOpen"
      class="two-factor-popup"
    >
      <div v-if="isRouteLocked" class="bg" />
      <div class="popup">
        <div class="head">
          <span class="name">{{ $t('loginView.tfa.title') }}</span>
          <ui-icon
            :size="20"
            lib="fa"
            substyle="fas"
            class="close"
            name="times"
            @click.native="close"
          />
        </div>
        <div class="body">
          <div
            class="title"
            v-html="$t('loginView.tfa.message_action')"
          />
          <UiCodeInput
            class="tfa-input"
            :is-loading="isLoading"
            auto-focus
            @complete="confirm"
          />
          <div
            v-if="!$_.isEmpty(errors)"
            class="error"
          >
            {{ errors.token[0].message }}
          </div>
        </div>
      </div>
      <div class="wrapper" />
    </div>
  </transition>
</template>

<script>

import UiCodeInput from '@/components/UIElements/UiCodeInput.vue';

export default {
  name: 'TwoFactorPopup',

  components: {
    UiCodeInput,
  },

  props: {
    continueAction: {
      type: String,
      default() {
        return '';
      },
    },
    continueActionParams: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      isOpen: false,
      isLoading: false,
      errors: {},
    };
  },

  computed: {
    isRouteLocked() {
      return this.$store.getters['session/significantActionsSeconds'] === 0
        && ['teams', 'settings'].includes(this.$store.state.nav.mainView);
    },
  },
  mounted() {
    window.addEventListener('keyup', this.keyupHandler);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.keyupHandler);
  },

  methods: {
    open() {
      this.isOpen = true;
    },

    close({ isConfirm = false } = {}) {
      this.isOpen = false;
      this.errors = {};

      if (!isConfirm && ['teams', 'settings'].includes(this.$store.state.nav.mainView)) {
        this.$router.push('/affiliates/list');
      }
    },

    keyupHandler(e) {
      if (e.key === 'Escape' && this.isOpen) {
        this.close();
      }
    },

    async confirm(code) {
      this.isLoading = true;
      const payload = {
        token: code,
      };
      this.errors = {};

      try {
        await this.$store.dispatch('session/getSessionAuth');
        await this.$api.significant('unlock', payload);
        await this.$store.dispatch('session/updateSession');
        await this.close({ isConfirm: true });
        if (this.continueAction) {
          this.$eventBus.$emit(this.continueAction, this.continueActionParams);
        }
      } catch (error) {
        this.errors = error.data.errors;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.two-factor-popup {
  .bg {
    position: fixed;
    top: 54px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
  }
}
</style>
