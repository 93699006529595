<template>
  <div class="media-buying-list blacklist">
    <blacklist-url-popup
      ref="addBlacklist"
      @save="fetchData"
    />
    <ui-confirm
      ref="confirm"
      :width="480"
      :action-disabled.sync="isDataLoading"
      :type="confirm.type"
      :action-name="confirm.actionName"
      :action-icon="confirm.faIcon"
      @save="confirm.confirmAction"
    >
      <div
        slot="title"
        v-html="confirm.title"
      />
      <div class="body__wrap">
        <i
          :class="confirm.type"
          class="fas fa-exclamation-triangle"
        />
        <div
          class="body__msg blacklist-body"
          v-html="confirm.msg"
        />
      </div>
    </ui-confirm>
    <div class="toolbar" @keyup.enter="handleRefresh">
      <div class="wrapper">
        <div class="section filters">
          <span class="title">{{ $t('mediaBuying.blacklistUrls.title') }}</span>
          <ui-input
            v-model.trim="searchQuery"
            :width="180"
            class="select-label"
            is-search
            @search="handleRefresh"
          />
        </div>
        <div class="section">
          <ui-pagination
            :page="page"
            :page-size="limit"
            :count="count"
            show-size-select
            @page-change="pageChange"
          />
          <ui-button
            v-if="isBlacklistEdit"
            color="green"
            lib="fa"
            substyle="fas"
            filled
            icon="plus"
            class="btn btn-add"
            @click="handleOpenPopup"
          >
            {{ $t('mediaBuying.blacklistUrls.add') }}
          </ui-button>
        </div>
      </div>
    </div>
    <ui-table
      v-loading="isDataLoading"
      :fields="fields"
      :data="tableData"
      :rows-count="limit"
      :sort="{
        prop: sortProp,
        order: sortOrder,
      }"
      lazy-loading
      i18n-path="mediaBuying.blacklistUrls"
      element-loading-custom-class="data-loader-spinner"
      class="table"
      @sort="handleSort"
    >
      <template
        v-if="isBlacklistEdit"
        slot="append"
      >
        <el-table-column width="74">
          <template slot="header" slot-scope="scope">
            <div :key="scope.$index" class="table-action-header">
              {{ $t('settings.tokens.actions') }}
            </div>
          </template>
          <template slot-scope="scope">
            <ActionIcon
              :tooltip="$t('crm.buttons.edit')"
            >
              <div
                class="action-ico"
                @click="editMethod(scope.row)"
              >
                <ui-icon
                  name="pen"
                  :color="$theme.accentColor"
                  lib="fa"
                />
              </div>
            </ActionIcon>
            <ActionIcon
              :tooltip="$t('crm.buttons.delete')"
            >
              <div
                class="action-ico"
                @click="removeMethod(scope.row)"
              >
                <ui-icon
                  name="trash-alt"
                  :color="$theme.dangerColor"
                  lib="fa"
                />
              </div>
            </ActionIcon>
          </template>
        </el-table-column>
      </template>
    </ui-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import updateUrl from '@/service/updateUrl';
import BlacklistUrlPopup from './components/BlacklistUrlPopup.vue';
import sessionAction from '@/views/mixins/session-action';

import { pageSizeMixin, resolvePageSize } from '@/service/pageSize';

const sizes = {
  1080: 20,
  1440: 30,
};
const LS_PATH = 'BlacklistUrl/List';
const viewName = `${LS_PATH}/limit`;
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

export default {
  name: 'BlacklistUrls',
  components: {
    BlacklistUrlPopup,
  },
  mixins: [pageSizeMixin, sessionAction],
  data() {
    return {
      viewName,
      confirm: this.getDefaultConfirm(),
      fields: [
        {
          name: 'url',
          align: 'left',
          class: 'text',
          linkAppend: this.renderUrl,
        },
        {
          name: 'created_at',
          width: '130',
          align: 'left',
          headerAlign: 'left',
          sortable: 'custom',
          format: 'date-time',
        },
      ],
      isDataLoading: false,
      tableData: [],
      page: 1,
      limit: pageSize,
      count: 0,
      searchQuery: '',
      options: {
        sort_column: '',
        sort_dir: '',
        search: '',
      },
      sortProp: '',
      sortOrder: '',
      fetchData: Function,
    };
  },

  computed: {
    ...mapGetters({
      adminAcl: 'auth/adminAcl',
      currentAcl: 'auth/currentAcl',
      postPlatform: 'misc/postPlatform',
    }),
    isBlacklistEdit() {
      return this.adminAcl.is_superuser || this.currentAcl.media_buying_payment_methods_blacklist_edit === 'allow';
    },
  },

  watch: {
    searchQuery() {
      this.options.search = this.searchQuery;
    },
    sortProp(newVal) {
      this.options.sort_column = this.sortProp;

      if (this.fields.some(item => item.name === this.options.sort_column && item.sortable === 'custom')) {
        this.$ls.set(`${LS_PATH}/sort_column`, newVal || 'created_at');
      }
    },
    sortOrder(newVal) {
      this.options.sort_dir = this.sortOrder;
      this.$ls.set(`${LS_PATH}/sort_dir`, newVal || 'desc');
    },
  },


  created() {
    this.fetchData = this.$_.debounce(this.fetchDataD, 300);
    this.initData();
  },
  beforeDestroy() {
  },

  methods: {
    renderUrl(row) {
      const h = this.$createElement;

      const platformImg = h('img', {
        attrs: {
          height: 14,
          src: this.getIcon(row.url_platform_id),
        },
      });

      const elements = [
        platformImg,
        h('span', { class: 'text ui-m-sm-l' }, row.url),
      ];

      return h('span', { class: 'ui-d-flex ui-ai-center' }, elements);
    },
    getIcon(id) {
      return (this.postPlatform.find(el => el.id === id)).logo_base64;
    },
    getDefaultConfirm() {
      return {
        title: '',
        msg: '',
        type: 'warning',
        action: 'delete',
        faIcon: 'check',
        actionName: '',
        confirmAction: () => {},
      };
    },
    initData() {
      const _urlData = updateUrl.getParseParams(this.$route.hash);

      if (!this.$_.isEmpty(_urlData)) {
        const { limit = 0, page = 1 } = _urlData;

        this.limit = Number(limit) || pageSize;
        this.page = Number(page) || 1;

        delete _urlData.page;
        delete _urlData.limit;

        this.$_.merge(this.options, _urlData);

        this.sortProp = this.$_.get(_urlData, ['sort_column'], 'created_at');
        this.sortOrder = this.$_.get(_urlData, ['sort_dir'], 'desc');

        this.searchQuery = this.$_.get(this.options, ['search'], '');

        if (this.searchQuery.length > 0) {
          this.blockedFetch = true;
        }
      } else {
        this.sortProp = this.$ls.get(`${LS_PATH}/sort_column`)
          ? this.$ls.get(`${LS_PATH}/sort_column`)
          : 'created_at';
        this.sortOrder = this.$ls.get(`${LS_PATH}/sort_dir`)
          ? this.$ls.get(`${LS_PATH}/sort_dir`)
          : 'desc';
        this.limit = +this.$ls.get(`${LS_PATH}/limit`) || pageSize;
      }

      this.fetchData(this.$refs?.addBlacklist?.isOpen && 'silent');
    },
    editMethod(row) {
      this.$refs.addBlacklist.open(row);
    },
    removeMethod(row) {
      this.confirm.confirmAction = () => this.confirmDeleteBlacklist(row.id);
      this.confirm.title = this.$t('mediaBuying.blacklistUrls.popup.delete.title');
      this.confirm.msg = `${this.$t('mediaBuying.blacklistUrls.popup.delete.msg', { address: row.url })}`;
      this.confirm.actionName = this.$t('mediaBuying.blacklistUrls.popup.delete.action');
      this.confirm.type = 'warning';
      this.confirm.faIcon = 'trash-alt';
      this.$refs.confirm.open();
    },
    confirmDeleteBlacklist(id) {
      this.$api.deleteMediaBuyingBlacklistUrls(id).then(() => {
        this.fetchDataD('silent');
        this.$refs.confirm.close();
      });
    },
    handleOpenPopup() {
      this.$refs.addBlacklist.open();
    },
    _query() {
      return {
        ...this.options,
        limit: this.limit,
        offset: this.limit * this.page - this.limit,
      };
    },

    async fetchDataD(action) {
      if (action !== 'silent') {
        this.isDataLoading = true;
      }

      this._completedFilterUrl();
      const options = this._query();

      this.$api
        .getMediaBuyingBlacklistUrls(options)
        .then((res) => {
          this.count = res.data.misc.count;
          this.tableData = this.$_.cloneDeep(res.data.payload);
        })
        .finally(() => {
          if (action !== 'silent') {
            this.isDataLoading = false;
          }
        });
    },

    pageChange(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;
        this.fetchData();
      }
    },
    handleSort({ prop, order }) {
      if (order === '') return;
      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }
      this.fetchData();
    },
    handleRefresh() {
      this.page = 1;
      this.fetchData();
    },
    /*
     * Создаем filter URL
     * */
    _completedFilterUrl() {
      const _dataFilters = this._query();
      _dataFilters.page = this.page;

      delete _dataFilters.offset;

      updateUrl.updateGetParams(_dataFilters);
    },
  },
};
</script>
<style lang='scss' scoped>
.blacklist {
  .wrapper {
    width: 90%;
    min-width: 1140px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn {
      margin-left: 12px;
      &:focus{
        outline: none;
      }
    }
  }

  .blacklist-body {
    /deep/ span {
      white-space: initial!important;
      word-break: break-all;
    }
  }

  /deep/ {
    .el-table__cell .row-value.text {
      display: inline-block!important;
      text-overflow: ellipsis;
    }

    .el-table__row {
      cursor: default!important;
    }
  }
}
</style>
