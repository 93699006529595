export default {
  mediaBuying: {
    name: 'Медиабаинг',
    page_title: {
      media_buying_unreported_funds: {
        name: 'Без отчетов',
      },
      media_buying_payments_moderation: {
        name: 'Модерация платежей — ',
        on_review: 'На рассмотрении',
        for_rework: 'На доработке',
        approved: 'Одобрено',
        declined: 'Отклонено',
      },
      media_buying_payments_payout: {
        name: 'Проведение платежей — ',
        not_paid: 'Не выплачено',
        for_rework: 'На доработке',
        processing: 'В обработке',
        paid: 'Выплачено',
        failed: 'Неудачно',
      },
      posts: {
        name: 'Публикации — ',
        post: 'Публикация',
        on_review: 'На рассмотрении',
        for_rework: 'На доработке',
        approved: 'Одобрено',
        declined: 'Отклонено',
      },
    },
    blacklistWallets: {
      title: 'Черный список кошельков',
      add: 'Добавить кошелек',
      popup: {
        title: 'Добавить кошелек в черный список',
        titleEdit: 'Редактировать кошелек из черного списка',
        address: 'Адрес',
        placeholderAddress: 'Ведите платежный адрес',
        paymentMethod: 'Способ платежа',
        placeholderPaymentMethod: 'Выберите метод оплаты',

        delete: {
          title: 'Удалить из черного списка',
          msg: 'Вы уверены, что хотите удалить реквизиты?',
          action: 'удалить',
        },
      },
      fields: {
        media_buying_payment_method_name: {
          title: 'Способ оплаты',
        },
        payment_wallet: {
          title: 'Адрес',
        },
        site_payment_method_name: {
          title: 'Способ оплаты',
        },
        created_at: {
          title: 'Создан',
        },
      },
    },
    blacklistUrls: {
      title: 'Черный список URL',
      add: 'Добавить URL',
      edit: 'Редактировать URL',
      popup: {
        title: 'Добавить URL в черный список',
        titleEdit: 'Редактировать URL из черного списка',
        add: 'Добавить URL',

        delete: {
          title: 'Удалить из черного списка',
          msg: 'Вы уверены, что хотите удалить URL <span>{address}</span>?',
          action: 'удалить',
        },
      },
      fields: {
        url: {
          title: 'URL',
        },
        created_at: {
          title: 'Создан',
        },
      },
    },
    other: {
      aff_stats: 'Статистика по партнеру',
      camp_stats: 'Статистика по кампании',
      paid_in_total: 'Оплачено',
      spent_total: 'Затраты',
      team: 'Команда',
      camp_info: 'Информация о кампании',
      btn_mark: 'Отметить как требующую внимания',
      btn_unmark: 'Отметить прочитанным',
      paid_pending: 'Ожид.оплаты',
      spent_pending: 'Траты на рассм.',
      action_required: 'Требуется действие',
      add_to_blacklist: 'Добавить в черный список',
    },
    statistics: {
      registrations_count: 'Регистрации',
      first_deposits_count: 'Первые депозиты',
      deposits_sum_usd: 'Депозиты',
      profit_sum_usd: 'Игровая прибыль',
      total_profit_sum_usd: 'Итоговая прибыль',
      cost_per_player_usd: 'Цена за игрока',
      period: 'Период',
      select_period: 'Выберите период',
    },
    status: {
      moderation: 'Модерация',
      payout: 'Проведение',
      not_paid: 'Не выплачено',
      for_rework: 'На доработке',
      processing: 'В обработке',
      paid: 'Выплачено',
      failed: 'Неудачно',
      on_review: 'На рассмотрении',
      approved: 'Одобрено',
      declined: 'Отклонено',
    },
    types: {
      cancellation: 'Списание',
      publication: 'Публикация',
    },
    method: {
      wire: 'Банковский перевод',
      bitcoin_micro: 'Bitcoin (micro)',
      card_rub: 'Карты RUB',
      ecopayz_eur: 'ecoPayz EUR',
      ecopayz_usd: 'ecoPayz USD',
      neteller_eur: 'Neteller EUR',
      neteller_gbr: 'Neteller GBR',
      neteller_usd: 'Neteller USD',
      payeer_usd: 'Payeer USD',
      paypal: 'PayPal',
      player_account: 'Игровой аккаунт',
      qiwi: 'Qiwi',
      skrill_eur: 'Skrill EUR',
      skrill_gbp: 'Skrill GBP',
      skrill_usd: 'Skrill USD',
      webmoney_r: 'Webmoney R',
      webmoney_z: 'Webmoney Z',
    },

    reports_table_title: {
      fields: {
        affiliate_email: {
          title: 'Партнер',
        },
        root_campaign_name: {
          title: 'Родительская кампания',
        },
        campaign_name: {
          title: 'Кампания',
        },
        impressions_count: {
          title: 'Показы',
        },
        visits_count: {
          title: 'Посещения',
        },
        registrations_count: {
          title: 'Регистрации',
        },
        first_deposits_count: {
          title: 'Первые депозиты',
        },
        first_deposits_sum: {
          title: 'Первые депозиты',
        },
        deposits_count: {
          title: 'Депозиты',
        },
        deposits_sum: {
          title: 'Депозиты',
        },
        payments_sum: {
          title: 'Выплаты',
        },
        cost_per_player: {
          title: 'За игрока',
        },
        profit_sum: {
          title: 'Игровая прибыль',
        },
        total_profit_sum: {
          title: 'Итоговая прибыль',
        },
      },
    },

    list_table_title: {
      fields: {
        incrementing_id: {
          title: 'ID',
        },
        type: {
          title: 'Тип',
        },
        affiliate_email: {
          title: 'Email партнера',
        },
        teams: {
          title: 'Команды',
        },
        team_name: {
          title: 'Команда',
        },
        channel_name: {
          title: 'Рекламный канал',
        },
        campaign_name: {
          title: 'Кампания',
        },
        payment_method_id: {
          title: 'Способ',
        },
        amount: {
          title: 'Сумма',
        },
        amount_converted: {
          title: 'Сумма',
        },
        amount_usd: {
          title: 'Сумма, USD',
        },
        created_at: {
          title: 'Создано',
        },
        updated_at: {
          title: 'Изменено',
        },
        period: {
          title: 'Период',
        },
        campaigns_without_posts_count: {
          title: 'Кампании без публикаций',
        },
        unreported_sum: {
          title: 'Без отчетов',
        },
        reported_sum: {
          title: 'С отчетами',
        },
      },
    },
    actions: {
      info: 'Информация',
      decline: 'Отклонить',
      approve: 'Одобрить',
      send_for_rework: 'Отправить на доработку',
      send_to_review: 'Отправить на рассмотрение',
      paid: 'Выплачено',
      failed: 'Не выполнено',
      initiate_payment: 'Создать платеж',
      profile: 'Информация о партнере',
    },
    info: {
      title: 'Запрос от {affiliate}',
      post_title: 'Пост от {affiliate}',
      advertising_channel_card: {
        title: 'Рекламный канал',
      },
      campaign_card: {
        title: 'Кампания',
      },
      summary: {
        cost_per_dep: 'Стоимость 1го деп.',
        visits: 'Посещения',
        profit: 'Прибыль',
        registrations: 'Регистрации',
        ndep_reg_rait: 'Соотношение первых деп./Регистраций',
        deposits: 'Депозиты',
        players_w_bets: 'Игроки со ставками',
        sport_bets: 'Ставки на спорт',
        casino_bets: 'Ставки на казино',
      },
      payment_info_card: {
        title: 'Информация о платежах',
        type: 'Тип',
        method: 'Способ',
        period: 'Период',
        wallet: 'Кошелек',
        platform: 'Платформа',
        affiliate: 'Партнер',
        requested_at: 'Запрошено',
        updated_at: 'Обновлено',
        from: 'От: ',
        status: {
          title: 'Статус',
          on_review: 'На рассмотрении',
          not_paid: 'Не оплачено',
          processing: 'В обработке',
          paid: 'Выплачено',
        },
      },
      post_info_card: {
        title: 'Информация о публикации',
        description: 'Описание',
        period: 'Период',
        urls: 'URL’s',
      },
      campaign_costs_card: {
        title: 'Стоимость кампании',
        balance: 'Баланс',
        spent: 'Потрачено',
        paid_in_total: 'Всего оплачено',
        on_review: 'На рассмотрении',
      },
      account_overview: {
        title: 'Общие сведения',
      },
      payment_description_card: {
        title: 'Описание оплаты',
      },
      files_card: {
        title: 'Файлы',
        download: 'Загрузить',
        no_files: 'Нет файлов',
      },
      history_card: {
        title: 'История',
        no_history: 'Нет истории',
      },
      popup: {
        cancel: 'отменить',
        failed_request: {
          title: 'Ошибка проведения платежа',
          text: 'Подтвердить ошибку проведения платежа?',
          button: 'Подтвердить',
        },
        decline_request: {
          title: 'Отклонить заявку',
          text: 'Подтвердить отказ об оплате?',
          button: 'Отклонить заявку',
        },
        rework_request: {
          title: 'Отправить на доработку',
          text: 'Подтвердить запрос на доработку?',
          button: 'Подтвердить',
        },
        on_review_request: {
          title: 'Отправить на рассмотрение',
          text: 'Подтвердить запрос на рассмотрение?',
          button: 'Подтвердить',
        },
        approve_request: {
          title: 'Одобрить',
          text: 'Вы уверены, что хотите одобрить эту заявку?',
          button: 'Одобрить',
        },
        initiate_request: {
          title: 'Создать платеж',
          text: 'Вы уверены, что хотите создать платеж?',
          button: 'Создать платеж',
        },
        paid_request: {
          title: 'Оплачено',
          text: 'Вы уверены, что хотите оплатить данный запрос?',
          button: 'Оплачено',
        },
        approve_post: {
          title: 'Одобрить пост',
          text: 'Вы уверены, что хотите одобрить этот пост?',
          button: 'Одобрено',
        },
        decline_post: {
          title: 'Отклонить пост',
          text: 'Подтвердить отклонение поста?',
          button: 'Отклонить пост',
        },
        rework_post: {
          title: 'Отправить на доработку?',
          text: 'Отправить пост на доработку?',
          button: 'Подтвердить',
        },
        on_review_post: {
          title: 'Отправить на рассмотрение',
          text: 'Отправить пост на рассмотрение?',
          button: 'Подтвердить',
        },
        reply: 'Ответить',
        reply_placeholder: 'Описать причину',
        files: 'Файлы',
        files_placeholder: 'Перетащите файл сюда или <em>нажмите, чтобы загрузить</em>',
      },
      payment_auto: 'Выплата инициирована автоматически',
    },
    comments: {
      title: 'Комментарии',
      placeholder: 'Ваш комментарий…',
      button_create: 'Отправить',
      button_update: 'Сохранить',
      types: {
        user: 'Комментарии',
        system: 'История',
        all: 'Все',
      },
    },
    cost_model: {
      fixed: 'Фиксированная оплата',
      cpm: 'CPM',
      cpc: 'CPC',
      cpv: 'CPV',
      cpi: 'CPI',
    },
  },
};
