<template>
  <div class="login">
    <primary-logo class="logo" />
    <div class="wrapper">
      <div v-show="action === 'auth'" v-loading="isLoading" class="login-form">
        <p class="title">
          {{ $t('loginView.auth.title') }}
        </p>
        <ui-input
          v-model="email"
          :label="$t('loginView.auth.email.label')"
          :placeholder="$t('loginView.auth.email.placeholder')"
          :error="errors.email"
          autosize
          size="big"
          class="form-input"
        />

        <ui-input
          v-model="password"
          :label="$t('loginView.auth.password.label')"
          :error="errors.password"
          autosize
          size="big"
          placeholder="********"
          class="form-input"
          type="password"
        />
        <div
          v-if="siteKey"
          v-show="isCaptchaV2Required"
          class="ui-m-lg-b"
        >
          <vue-recaptcha
            ref="captchaV2"
            :sitekey="siteKey"
            @verify="verifyV2"
          />
        </div>
        <div class="control-wr">
          <el-checkbox
            v-model="remember"
            :label="$t('loginView.auth.rememder.label')"
            class="form-checkbox"
          />
          <ui-button
            :disabled="!(email && password)"
            lib="fa"
            substyle="fas"
            icon="sign-in"
            filled
            size="big"
            @click="signIn"
          >
            {{ $t('loginView.auth.loginBtn.text') }}
          </ui-button>
        </div>
      </div>
      <div
        v-if="action === '2fa'"
        v-loading="isLoading"
        class="2fa-form tfa-form"
      >
        <span v-show="action === '2fa'" class="back" @click="signOut">
          <i class="fas fa-chevron-left" />
          {{ $t('tfaSetupView.backLink') }}
        </span>
        <p class="title">
          {{ $t('loginView.tfa.title') }}
        </p>
        <span class="tfa-msg" v-html="$t('loginView.tfa.message')" />
        <UiCodeInput auto-focus @complete="tfaCheck" />
        <div v-if="!$_.isEmpty(errors) && action === '2fa'" class="error">
          {{ errors.token[0].message }}
        </div>
        <div class="control-wr control-wr_tfa">
          <el-checkbox
            v-model="trust"
            :label="$t('loginView.tfa.trust.label')"
            class="form-checkbox"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiCodeInput from '@/components/UIElements/UiCodeInput.vue';
import {
  AUTH_REQUEST,
  AUTH_2FA_REQUEST,
  AUTH_LOGOUT,
  AUTH_ADMIN_INFO,
  AUTH_ADMIN_ACL,
  AUTH_2FA_SUCCESS,
  AUTH_SUCCESS,
} from '@/store/action_types/auth';
import PrimaryLogo from '@/components/PrimaryLogo.vue';
import {
  MISC_INIT_DATA,
  MISC_FETCH_BRANDS,
} from '@/store/action_types/misc';
import { storagePrefix } from '@/service/configLocalStorage';
import { I18N_SET_LANG } from '@/store/action_types/i18n';

export default {
  name: 'Login',
  components: {
    PrimaryLogo,
    UiCodeInput,
  },
  props: {
    action: {
      type: String,
      default: 'auth',
    },
  },
  data() {
    return {
      email: '',
      password: '',
      remember: true,
      trust: true,
      errors: {},
      isLoading: false,
      isCaptchaV2Required: false,
      recaptchaTokenV2: null,
      recaptchaTokenV3: null,
    };
  },
  computed: {
    siteKey() {
      return this.$store.state.misc.miscSettings.recaptcha?.public_key_v2;
    },
  },
  mounted() {
    window.addEventListener('keyup', this.enterLogin);
    if (this.action === 'auth') {
      this.clearLocalStorage();
    }
    if (this.action === '2fa') {
      this.$nextTick(() => {
        document
          .getElementsByClassName('react-code-input')[0]
          .children[0].focus();
      });
    }
  },

  beforeRouteUpdate(to, from, next) {
    this.email = '';
    this.password = '';
    this.remember = true;
    next();
  },

  beforeDestroy() {
    window.removeEventListener('keyup', this.keyupHandler);
  },

  methods: {
    verifyV2(token) {
      this.recaptchaTokenV2 = token;
    },
    async recaptcha() {
      try {
        await this.$recaptchaLoaded();
        this.recaptchaTokenV3 = await this.$recaptcha('login');
      } catch (error) {
        window.console.error(error);
      }
    },
    clearLocalStorage() {
      const ignoreItem = ['language'];
      const ignoreReportTeam = `${storagePrefix}reports/`;
      const ignoreReportMetrics = `${storagePrefix}reportMetrics4/`;
      // eslint-disable-next-line
      for (const key in window.localStorage) {
        if (
          localStorage.hasOwnProperty(key)
          && !ignoreItem.includes(key)
          && key.indexOf(ignoreReportMetrics) !== 0
          && key.indexOf(ignoreReportTeam) !== 0
        ) {
          localStorage.removeItem(key);
        }
      }
    },

    enterLogin(e) {
      if (e.key === 'Enter') {
        switch (this.action) {
        case 'auth':
          this.signIn();
          break;
        default:
          break;
        }
      }
    },

    async signOut() {
      try {
        await this.$store.dispatch(`auth/${AUTH_LOGOUT}`);
        this.$router.push({
          name: 'login',
          params: {
            action: 'auth',
          },
        });
      } catch (error) {
        this.errors = error.data.errors;
      }
    },

    async signIn() {
      const recaptcha_token = this.recaptchaTokenV2 || this.recaptchaTokenV3;

      this.errors = {};

      if (this.email && this.password) {
        this.isLoading = true;
        const payload = {
          email: this.email,
          password: this.password,
          remember: this.remember,
          ...(recaptcha_token && { recaptcha_token }),
        };

        try {
          const response = await this.$store.dispatch(
            `auth/${AUTH_REQUEST}`,
            payload,
          );
          if (response.otp_auth_url && response.otp_qr_code_url) {
            this.$router.push('/2fasetup');
          } else if (response.google2fa_challenge_needed) {
            this.$router.push({
              name: 'login',
              params: {
                action: '2fa',
              },
            });
          } else {
            if (response.id) {
              localStorage.setItem('auth-token', response.id);
            }

            await this.$store.dispatch(`auth/${AUTH_ADMIN_ACL}`);
            await Promise.all([
              this.$store.dispatch('session/initialSessionData'),
              this.$store.dispatch(`misc/${MISC_FETCH_BRANDS}`),
              this.$store.dispatch(`auth/${AUTH_ADMIN_INFO}`),
              this.$store.dispatch(`misc/${MISC_INIT_DATA}`),
              this.$store.dispatch('affiliate/kpiBonusTypes'),
            ]);
            await this.$store.dispatch(`i18n/${I18N_SET_LANG}`, this.$store.getters['auth/adminInfo'].language_code);
            this.$store.commit(`auth/${AUTH_SUCCESS}`);
            this.$store.commit(`auth/${AUTH_2FA_SUCCESS}`);
            this.$router.push('/affiliates');
          }
        } catch (error) {
          if (error.hasOwnProperty('recaptcha_token')) {
            if (!this.recaptchaTokenV3) {
              await this.recaptcha();
              return await this.signIn();
            }

            if (!this.recaptchaTokenV2) {
              this.isCaptchaV2Required = true;
              this.isLoading = false;
              return Promise.resolve();
            }

            this.$noty.error(this.$t('loginView.captchaRequestFailed'));
            this.isLoading = false;
            return Promise.resolve();
          }
          this.errors = error;
        } finally {
          this.isLoading = false;
        }
      } else {
        this.errors = {
          login: [{ code: 1000 }],
          password: [{ code: 1000 }],
        };
      }

      return Promise.resolve();
    },
    async tfaCheck(code) {
      this.errors = {};

      if (code !== '') {
        this.isLoading = true;

        const payload = {
          token: code,
          trust: this.trust,
        };

        try {
          await this.$store.dispatch(`auth/${AUTH_2FA_REQUEST}`, payload);
          this.$store.dispatch(`misc/${MISC_FETCH_BRANDS}`);
          this.$router.push('/affiliates');
        } catch (error) {
          this.errors = error;
        } finally {
          this.isLoading = false;
        }
      } else {
        this.errors = {
          token: [{ code: 1000 }],
        };
      }
    },
  },
};
</script>

<style lang="scss">
.login {
  @import '@/assets/styles/colors.scss';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $mainGrey;

  .title {
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    color: #303634;
    width: 100%;
    margin-bottom: 16px;
  }

  .logo {
    margin-bottom: 30px;
  }

  .back {
    display: block;
    width: 100%;
    // color: #20815e;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 12px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 395px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.09);
    box-sizing: border-box;
    padding: 32px;

    .control-wr {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
      &_tfa {
        justify-content: center;
        margin-top: 8px;
      }
    }

    & > form,
    & > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .ui-button {
      width: 100%;
    }

    .form-input {
      margin-bottom: 16px;

      .label-text {
        text-transform: capitalize;
      }

      input {
        border-radius: 5px;
      }
    }

    .form-checkbox {
      padding-left: 16px;
      margin-right: 0;

      .el-checkbox__label {
        font-size: 12px;
      }

      // margin-bottom: 16px;
    }

    .tfa-msg {
      // text-align: center;
      font-size: 14px;
      line-height: 1.7;
      color: $mainGrey;
      margin-bottom: 16px;

      span {
        font-weight: 500;
      }
    }

    .tfa-form {
      .error {
        text-align: center;
        // color: #d26370;
        font-size: 12px;
        height: 14px;
        line-height: 14px;
        font-style: italic;
        margin-top: 6px;
        text-transform: uppercase;
        font-weight: 500;
      }
    }

    .success-msg {
      font-size: 24px;
      font-weight: 500;
      text-align: center;
      line-height: 1.5;
      color: $mainGrey;
      margin: 20px 0 24px;
    }
  }
}
</style>
