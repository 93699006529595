export default {
  getSiteSettings(params) {
    return this.affiliatesApi.get('/sites', { params });
  },

  getSettings() {
    return this.affiliatesApi.get('/settings');
  },

  getAccessTokens(params) {
    return this.affiliatesApi.get('/control-tower/external-access-tokens', { params });
  },

  postAccessToken(params) {
    return this.affiliatesApi.post('/control-tower/external-access-tokens', params);
  },

  deleteAccessToken(id) {
    return this.affiliatesApi.delete(`/control-tower/external-access-tokens/${id}`);
  },

  getFees(siteId) {
    return this.affiliatesApi.get(`/control-tower/fees/${siteId}`);
  },

  updateFees(siteId, params) {
    return this.affiliatesApi.put(`/control-tower/fees/${siteId}`, params);
  },
  getControlPaymentMethods(params) {
    return this.affiliatesApi.get('/control-tower/payment-methods', { params });
  },
  getControlPaymentMethodsById(id) {
    return this.affiliatesApi.get(`/control-tower/payment-methods/${id}`);
  },
  postControlPaymentMethods(params) {
    return this.affiliatesApi.post('/control-tower/payment-methods', params);
  },
  putControlPaymentMethods(id, params) {
    return this.affiliatesApi.put(`/control-tower/payment-methods/${id}`, params);
  },
  getCustomersAffiliateProgramName() {
    return this.affiliatesApi.get('/control-tower/customers/affiliate-program-name');
  },
  putCustomersAffiliateProgramName(params) {
    return this.affiliatesApi.put('/control-tower/customers/affiliate-program-name', params);
  },
  getCustomersEmailSettings() {
    return this.affiliatesApi.get('/control-tower/customers/email-settings');
  },
  putCustomersEmailSettings(params) {
    return this.affiliatesApi.put('/control-tower/customers/email-settings', params);
  },
  getCustomersRecaptchaSettings() {
    return this.affiliatesApi.get('/control-tower/customers/recaptcha-settings');
  },
  putCustomersRecaptchaSettings(params) {
    return this.affiliatesApi.put('/control-tower/customers/recaptcha-settings', params);
  },
  getCustomersAffiliateTelegramBotSettings() {
    return this.affiliatesApi.get('/control-tower/customers/affiliate-telegram-bot-settings');
  },
  putCustomersAffiliateTelegramBotSettings(params) {
    return this.affiliatesApi.put('/control-tower/customers/affiliate-telegram-bot-settings', params);
  },
  getCustomersPrivacyPolicySettings() {
    return this.affiliatesApi.get('/control-tower/customers/privacy-policy-settings');
  },
  putCustomersPrivacyPolicySettings(params) {
    return this.affiliatesApi.put('/control-tower/customers/privacy-policy-settings', params);
  },
  getCustomersSupportContactsSettings() {
    return this.affiliatesApi.get('/control-tower/customers/support-contacts-settings');
  },
  putCustomersSupportContactsSettings(params) {
    return this.affiliatesApi.put('/control-tower/customers/support-contacts-settings', params);
  },
  getCustomersAffiliateReferralProgramSettings() {
    return this.affiliatesApi.get('/control-tower/customers/affiliate-referral-program-settings');
  },
  putCustomersAffiliateReferralProgramSettings(params) {
    return this.affiliatesApi.put('/control-tower/customers/affiliate-referral-program-settings', params);
  },
  getCustomersAffiliatePaymentsSettings() {
    return this.affiliatesApi.get('/control-tower/customers/affiliate-payments-settings');
  },
  putCustomersAffiliatePaymentsSettings(params) {
    return this.affiliatesApi.put('/control-tower/customers/affiliate-payments-settings', params);
  },
  getCustomersDefaultDomainsNotificationsSettings() {
    return this.affiliatesApi.get('/control-tower/customers/default-domains-notifications-default-settings');
  },
  getCustomersAffiliatesLimits() {
    return this.affiliatesApi.get('/control-tower/customers/affiliates-limits');
  },
  putCustomersAffiliatesLimits(params) {
    return this.affiliatesApi.put('/control-tower/customers/affiliates-limits', params);
  },
  putCustomersDefaultDomainsNotificationsSettings(params) {
    return this.affiliatesApi.put('/control-tower/customers/default-domains-notifications-settings', params);
  },
  getCustomerDomains() {
    return this.affiliatesApi.get('/control-tower/customer-domains');
  },
  deleteCustomerDomains({ country_code }) {
    return this.affiliatesApi.delete(`/control-tower/customer-domains/${country_code}`);
  },
  putCustomerDomains({ country_code, ...restParams }) {
    return this.affiliatesApi.put(`/control-tower/customer-domains/${country_code}`, restParams);
  },
  putCustomerDomainsSetDefault({ country_code }) {
    return this.affiliatesApi.put(`/control-tower/customer-domains/${country_code}/set-default`);
  },

  getFinalPages({ siteId }) {
    return this.affiliatesApi.get(`/control-tower/final-pages/${siteId}`);
  },
  putFinalPages({ siteId }, params) {
    return this.affiliatesApi.put(`/control-tower/final-pages/${siteId}`, params);
  },

  getCountriesTags(params) {
    return this.affiliatesApi.get('/control-tower/countries-tags', { params });
  },
  postCountriesTags(params) {
    return this.affiliatesApi.post('/control-tower/countries-tags', params);
  },
  putCountriesTags(id, params) {
    return this.affiliatesApi.put(`/control-tower/countries-tags/${id}`, params);
  },
  deleteCountriesTags(id) {
    return this.affiliatesApi.delete(`/control-tower/countries-tags/${id}`);
  },

  getCustomerCurrencies(params) {
    return this.affiliatesApi.get('/control-tower/customer-currencies', { params });
  },
  postCustomerCurrencies(params) {
    return this.affiliatesApi.post('/control-tower/customer-currencies', params);
  },
  deleteCurrency(code) {
    return this.affiliatesApi.delete(`/control-tower/customer-currencies/${code}`);
  },
  putCurrencySetDefault({ code }) {
    return this.affiliatesApi.put(`/control-tower/customer-currencies/${code}/set-default`);
  },
  getControlMediaBuyingPaymentMethods(params) {
    return this.affiliatesApi.get('/control-tower/media-buying-payment-methods', { params });
  },
  getControlMediaBuyingPaymentMethodById(id) {
    return this.affiliatesApi.get(`/control-tower/media-buying-payment-methods/${id}`);
  },
  postControlMediaBuyingPaymentMethods(params) {
    return this.affiliatesApi.post('/control-tower/media-buying-payment-methods', params);
  },
  putControlMediaBuyingPaymentMethods(id, params) {
    return this.affiliatesApi.put(`/control-tower/media-buying-payment-methods/${id}`, params);
  },
};
