<template>
  <div>
    <div class="affiliate-promocode-popup">
      <div class="popup">
        <div class="head">
          <span class="name">{{ `${$t(`affiliates.affiliatePromocode.popup.${action}_title`)}` }}</span>
          <ui-icon
            :size="19"
            class="close"
            lib="fa"
            substyle="fas"
            name="times"
            @click.native="close"
          />
        </div>
        <div
          v-loading="isDataLoading"
          class="form"
        >
          <div class="row">
            <span
              class="text-top"
            >{{ `${$t(`affiliates.affiliatePromocode.popup.${action}_text`)}` }}</span>
          </div>
          <div
            v-if="action === 'add'"
            class="row"
          >
            <ui-input
              v-model="promo_code"
              :label="$t('affiliates.affiliatePromocode.popup.label_text')"
              autosize
              :error="errors.promo_code"
              class="edit-input"
            />
          </div>
          <div class="row">
            <div class="field">
              <span class="label">{{ $t('affiliates.affiliatePromocode.popup.offer_text') }}</span>
              <el-select
                v-model="offerId"
                :placeholder="$t('players.pinPlayer.placeholder_offer')"
                filterable
                class="select"
              >
                <el-option
                  v-for="(item) in affiliateOffers"
                  :key="item.id"
                  :label="item.full_name"
                  :value="item.id"
                />
              </el-select>
            </div>
          </div>
          <div class="row">
            <div class="field">
              <span class="label">{{ $t('affiliates.affiliatePromocode.popup.traffic_source_text') }}</span>
              <el-select
                v-model="trafficSourceId"
                :placeholder="$t('affiliates.affiliatePromocode.popup.traffic_source_placeholder')"
                filterable
                class="select"
              >
                <el-option
                  v-for="(item) in trafficSources"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </div>
          </div>
        </div>
        <div class="foot">
          <ui-button
            size="big"
            color="red"
            class="btn"
            lib="fa"
            substyle="fas"
            icon="times"
            @click.native="close"
          >
            {{ $t('affiliates.affiliatePromocode.popup.cancel') }}
          </ui-button>
          <ui-button
            v-if="action === 'add'"
            filled
            size="big"
            icon="check"
            lib="fa"
            substyle="fas"
            color="green"
            :disabled="disabledButton"
            @click="addPromocode"
          >
            {{ $t('affiliates.affiliatePromocode.popup.add_button') }}
          </ui-button>
          <ui-button
            v-else
            :disabled="disabledButton"
            filled
            size="big"
            lib="fa"
            substyle="fas"
            icon="check"
            color="green"
            @click="updatePromocode"
          >
            {{ $t('affiliates.affiliatePromocode.popup.edit_button') }}
          </ui-button>
        </div>
      </div>
      <div class="wrapper" />
    </div>
  </div>
</template>

<script>


export default {
  name: 'LinkAffiliatePopup',
  props: {
    userId: {
      type: String,
      default() {
        return '';
      },
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isDataLoading: false,
      offerId: '',
      trafficSourceId: '',
      promo_code: '',
      action: 'add',
      affiliateOffers: [],
      trafficSources: [],
      paramsAttach: {},
      errors: {},
    };
  },
  computed: {
    disabledButton() {
      if (this.promo_code === '' || this.offerId === '') {
        return true;
      }
      return false;
    },
  },
  watch: {
    isOpen(e) {
      if (e) {
        this.resetForm();
        Promise.all([this.getAffiliateOffers(), this.getTrafficSources()])
          .finally(() => this.isDataLoading = false);

        if (!this.$_.isEmpty(this.data)) {
          this.promo_code = this.data.promo_code;
          this.offerId = this.data.affiliate_offer_id;
          this.trafficSourceId = this.data.traffic_source_id;
          this.action = 'edit';
        } else {
          this.action = 'add';
        }
      }
    },
  },
  methods: {
    getTrafficSources() {
      return this.$api.getTrafficSources({ affiliate_id: this.userId })
        .then((res) => {
          this.trafficSources = res.data.payload;
        });
    },
    getAffiliateOffers() {
      return this.$api.getAffiliateOffers(this.userId)
        .then((res) => {
          this.affiliateOffers = res.data.payload;
        });
    },
    addPromocode() {
      this.paramsAttach = {
        promo_code: this.promo_code,
        affiliate_offer_id: this.offerId,
        traffic_source_id: this.trafficSourceId,
        status: 'active',
      };
      this.$api.postAffiliatePromoCodes(this.paramsAttach)
        .then(() => {
          this.close('reload');
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },
    updatePromocode() {
      this.paramsAttach = {
        promo_code: this.promo_code,
        affiliate_offer_id: this.offerId,
        traffic_source_id: this.trafficSourceId,
        status: 'active',
      };
      this.$api.putAffiliatePromoCodes(this.data.id, this.paramsAttach)
        .then(() => {
          this.close('reload');
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },
    resetForm() {
      this.isDataLoading = true;
      this.affiliateOffers = [];
      this.trafficSources = [];
      this.promo_code = '';
      this.trafficSourceId = '';
      this.offerId = '';
      this.paramsAttach = {};
      this.errors = {};
    },
    close(v) {
      this.$emit('close', v);
    },
  },
};
</script>

<style lang="scss" scoped>
.affiliate-promocode-popup {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;
  .popup {
    position: absolute;
    z-index: 902;
    left: calc(50% - 240px);
    top: 10%;
    width: 480px;
    // padding: 40px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .head {
      // width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eaeaea;
      padding: 16px 24px;
      padding-top: 0;
      margin-bottom: 16px;
      .name {
        font-size: 20px;
        font-weight: 500;
      }
      .close {
        color: #303634;
        cursor: pointer;
        padding: 5px;
      }
    }
    .form {
      padding-left: 24px;
      padding-right: 24px;
      .text-top {
        font-size: 14px;
        line-height: 1.71;
        color: #303634;
      }
      .row {
        display: flex;
        width: 100%;
        .ui-input.medium {
          .label-text {
            font-size: 14px;
            font-weight: 500;
            color: #303634;
            margin-bottom: 8px;
          }
        }
        & + .row {
          margin-top: 16px;
        }
        .field {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex: 10 0 302px;
          .label {
            font-size: 14px;
            font-weight: 500;
            color: #303634;
            margin-bottom: 8px;
          }
          .select {
            margin: 0;
            width: 100%;
            flex: 0 0 auto;
          }
        }
      }
    }
    .foot {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eaeaea;
      padding: 16px 24px;
      padding-bottom: 0;
      margin-top: 16px;
      .ui-button {
        margin-left: 8px;
      }
    }
  }
  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
  }
}
</style>
<style lang="scss">
.affiliate-promocode-popup {
  .select {
    .el-select__caret {
      // line-height: 48px;
      font-family: "Font Awesome 6 Pro" !important;
      font-weight: 900;
      color: #7d7d7d !important;
      transform: rotateZ(360deg) !important;
      font-size: 12px !important;
      &.is-reverse {
        transform: rotateZ(180deg) !important;
      }
      &:before {
        content: "\f078";
      }
    }
    .el-input__inner {
      height: 32px !important;
      line-height: 32px;
      border-color: #d3d3d3;
      border-width: 1px;
      border-radius: 5px;
      background-image: linear-gradient(180deg, #ffffff 0%, #f6f6f6 100%);
      &::placeholder {
        font-size: 13px;
        color: #d3d3d3;
      }
    }
  }
}
</style>
