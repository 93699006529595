export default {
  methods: {
    pickerOptions() {
      return {
        // disabledDate(time) {
        //   return (
        //     time.getTime()
        //     > moment()
        //       .endOf('day')
        //       .unix()
        //       * 1000
        //   );
        // },
        firstDayOfWeek: 1,
        shortcuts:
          [
            {
              title: this.$t('ui.shortcuts.relative_time_ranges'),
              text: this.$t('ui.shortcuts.last_24_hours'),
              short_title: 'last_24_hours',
              range: [this.$moment().subtract(24, 'h'), this.$moment()],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
            {
              text: this.$t('ui.shortcuts.last_2_days'),
              short_title: 'last_2_days',
              range: [this.$moment().subtract(2, 'day'), this.$moment()],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
            {
              text: this.$t('ui.shortcuts.last_7_days'),
              short_title: 'last_7_days',
              range: [this.$moment().subtract(7, 'd'), this.$moment()],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
            {
              text: this.$t('ui.shortcuts.last_30_days'),
              short_title: 'last_30_days',
              range: [this.$moment().subtract(30, 'd'), this.$moment()],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
            {
              text: this.$t('ui.shortcuts.last_90_days'),
              short_title: 'last_90_days',
              range: [this.$moment().subtract(90, 'd'), this.$moment()],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
            {
              text: this.$t('ui.shortcuts.last_6_months'),
              short_title: 'last_6_months',
              range: [this.$moment().subtract(6, 'month'), this.$moment()],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
            {
              text: this.$t('ui.shortcuts.last_1_year'),
              short_title: 'last_1_year',
              range: [this.$moment().subtract(1, 'year'), this.$moment()],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
            {
              title: this.$t('ui.shortcuts.other_quick_ranges'),
              text: this.$t('ui.shortcuts.today'),
              short_title: 'today',
              range: [
                this.$moment().startOf('day'),
                this.$moment().endOf('day'),
              ],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
            {
              text: this.$t('ui.shortcuts.today_so_far'),
              short_title: 'today_so_far',
              range: [this.$moment().startOf('day'), this.$moment()],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
            {
              text: this.$t('ui.shortcuts.yesterday'),
              short_title: 'yesterday',
              range: [
                this.$moment()
                  .subtract(1, 'day')
                  .startOf('day'),
                this.$moment()
                  .subtract(1, 'day')
                  .endOf('day'),
              ],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
            {
              text: this.$t('ui.shortcuts.day_before_yesterday'),
              short_title: 'day_before_yesterday',
              range: [
                this.$moment()
                  .subtract(2, 'day')
                  .startOf('day'),
                this.$moment()
                  .subtract(2, 'day')
                  .endOf('day'),
              ],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
            {
              text: this.$t('ui.shortcuts.this_day_last_week'),
              short_title: 'this_day_last_week',
              range: [
                this.$moment()
                  .subtract(1, 'week')
                  .startOf('day'),
                this.$moment()
                  .subtract(1, 'week')
                  .endOf('day'),
              ],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
            {
              text: this.$t('ui.shortcuts.this_week'),
              short_title: 'this_week',
              range: [
                this.$moment()
                  .startOf('isoWeek'),
                this.$moment()
                  .endOf('isoWeek'),
              ],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
            {
              text: this.$t('ui.shortcuts.this_week_so_far'),
              short_title: 'this_week_so_far',
              range: [
                this.$moment()
                  .startOf('isoWeek'),
                this.$moment(),
              ],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
            {
              text: this.$t('ui.shortcuts.previous_week'),
              short_title: 'previous_week',
              range: [
                this.$moment()
                  .subtract(1, 'isoWeek')
                  .startOf('isoWeek'),
                this.$moment().subtract(1, 'isoWeek').endOf('isoWeek'),
              ],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
            {
              text: this.$t('ui.shortcuts.previous_month'),
              short_title: 'previous_month',
              range: [
                this.$moment()
                  .subtract(1, 'month')
                  .startOf('month'),
                this.$moment().subtract(1, 'month').endOf('month'),
              ],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
            {
              text: this.$t('ui.shortcuts.previous_year'),
              short_title: 'previous_year',
              range: [
                this.$moment()
                  .subtract(1, 'year')
                  .startOf('year'),
                this.$moment().subtract(1, 'year').endOf('year'),
              ],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
            {
              text: this.$t('ui.shortcuts.this_month'),
              short_title: 'this_month',
              range: [
                this.$moment().startOf('month'),
                this.$moment().endOf('month'),
              ],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
            {
              text: this.$t('ui.shortcuts.this_month_so_far'),
              short_title: 'this_month_so_far',
              range: [
                this.$moment().startOf('month'),
                this.$moment(),
              ],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
            {
              text: this.$t('ui.shortcuts.this_year'),
              short_title: 'this_year',
              range: [
                this.$moment().startOf('year'),
                this.$moment().endOf('year'),
              ],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
            {
              text: this.$t('ui.shortcuts.this_year_so_far'),
              short_title: 'this_year_so_far',
              range: [
                this.$moment().startOf('year'),
                this.$moment(),
              ],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
          ] || [],
      };
    },
    pickerReportOptions() {
      return {
        firstDayOfWeek: 1,
        shortcuts:
          [
            {
              text: this.$t('ui.shortcuts.this_month_so_far'),
              title: this.$t('ui.shortcuts.relative_time_ranges'),
              short_title: 'this_month_so_far',
              range: [
                this.$moment().startOf('month'),
                this.$moment().endOf('day'),
              ],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
            {
              text: this.$t('ui.shortcuts.previous_month'),
              short_title: 'previous_month',
              range: [
                this.$moment()
                  .subtract(1, 'month')
                  .startOf('month'),
                this.$moment().subtract(1, 'month').endOf('month'),
              ],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
            {
              text: this.$t('ui.shortcuts.last_30_days'),
              short_title: 'last_30_days',
              range: [this.$moment().subtract(30, 'd'), this.$moment()],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
            {
              text: this.$t('ui.shortcuts.last_90_days'),
              short_title: 'last_90_days',
              range: [this.$moment().subtract(90, 'd'), this.$moment()],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
            {
              text: this.$t('ui.shortcuts.last_6_months'),
              short_title: 'last_6_months',
              range: [this.$moment().subtract(6, 'month'), this.$moment()],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
            {
              text: this.$t('ui.shortcuts.last_1_year'),
              short_title: 'last_1_year',
              range: [this.$moment().subtract(1, 'year'), this.$moment()],
              onClick(picker) {
                const [minDate, maxDate] = this.range;
                picker.handleRangePick(
                  { minDate: new Date(minDate), maxDate: new Date(maxDate) },
                  true,
                  this.short_title,
                );
              },
            },
          ] || [],
      };
    },
  },
};
