import auth from './modules/auth';
import affiliates from './modules/affiliates';
import offers from './modules/offers';
import payments from './modules/payments';
import players from './modules/players';
import reports from './modules/reports';
import tools from './modules/tools';
import settings from './modules/settings';
import profile from './modules/profile';
import misc from './modules/misc';
import live from './modules/live';
import bookmark from './modules/bookmark';
import team from './modules/team';
import mediaBuying from './modules/mediaBuying';
import file from './modules/file';
import comment from './modules/comment';
import promocode from './modules/promocode';
import domainManagement from './modules/domainManagement';
import marketingMaterials from './modules/marketingMaterials';
import dashboards from './modules/dashboards';
import crm from './modules/crm';
import trafficSources from './modules/traffic-sources';

export default class Requests {
  constructor(affiliatesApi, miscApi) {
    Object.assign(this, { affiliatesApi, miscApi });

    const requests = {
      ...auth,
      ...affiliates,
      ...offers,
      ...payments,
      ...players,
      ...reports,
      ...tools,
      ...settings,
      ...profile,
      ...misc,
      ...live,
      ...bookmark,
      ...team,
      ...promocode,
      ...mediaBuying,
      ...file,
      ...comment,
      ...domainManagement,
      ...marketingMaterials,
      ...dashboards,
      ...crm,
      ...trafficSources,
    };

    Object.assign(this, requests);
  }
}
