export default {
  loginView: {

    auth: {
      title: 'Войти в аккаунт',
      email: {
        label: 'Email',
        placeholder: 'Ваш email',
      },
      password: {
        label: 'Пароль',
      },
      rememder: {
        label: 'Запомнить',
      },
      loginBtn: {
        text: 'Вход',
      },
    },
    tfa: {
      title: 'Двухфакторная Аутентификация',
      message: 'Введите код из приложения <span>Google Authenticator</span> в поле ниже, чтобы завершить вход.',
      message_action: 'Введите код из приложения <span>Google Authenticator</span> в поле ниже, чтобы завершить действие.',
      codeLabel: 'Код',
      trust: {
        label: 'Доверять этому браузеру',
      },
      checkBtn: {
        text: 'Проверить код',
      },
    },
    success: {
      message: 'Спасибо, двухфакторная авторизация успешно завершена!',
      enterBtn: {
        text: 'Ввести',
      },
    },
    LoginIsRequired: 'ТРЕБУЕТСЯ ЛОГИН И ПАРОЛЬ',
    codeIsRequired: 'ТРЕБУЕТСЯ КОД 2FA',
    captchaRequestFailed: 'Ваш запрос считается подозрительным. Пожалуйста, попробуйте еще раз или обратитесь в службу поддержки.',
  },
};
