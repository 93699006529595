<template>
  <transition name="fade">
    <div
      v-if="isOpen"
      :class="[type, action]"
      class="ui-confirm"
    >
      <div
        :style="{
          width: `${width}px`,
        }"
        class="wrapper"
      >
        <div class="head">
          <div class="title">
            <slot name="title" />
          </div>
          <ui-icon
            :size="19"
            lib="fa"
            substyle="fas"
            name="times"
            class="close"
            @click.native="close('krest'); $emit('cancel');"
          />
        </div>
        <div class="body">
          <slot />
        </div>
        <div class="controls">
          <div class="controls__left">
            <slot name="left-btn" />
          </div>
          <div class="controls__right">
            <slot name="right-btn" />
            <ui-button
              v-if="hasCloseBtn"
              size="big"
              color="red"
              class="btn"
              lib="fa"
              substyle="fas"
              icon="times"
              @click="close('btn')"
            >
              {{ closeActionName || $t('affiliates.cardView.toolbar.btns.close') }}
            </ui-button>
            <ui-button
              v-if="hasDontSaveBtn"
              size="big"
              color="red"
              class="btn"
              lib="fa"
              substyle="fas"
              icon="times"
              @click="dontSave"
            >
              {{ $t('affiliates.cardView.toolbar.btns.dontSave') }}
            </ui-button>
            <ui-button
              :loading="loadingAction"
              :disabled="actionDisabled"
              :color="type === 'success' ? $theme.mainColor : 'red'"
              :icon="actionFaIcon === '' ? actionIcon : actionFaIcon"
              size="big"
              filled
              lib="fa"
              substyle="fas"
              class="btn"
              @click="save"
            >
              {{ actionName }}
            </ui-button>
          </div>
        </div>
      </div>
      <div class="overlay" />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'UiConfirm',
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    loadingAction: {
      type: Boolean,
      default: false,
    },
    hasCloseBtn: {
      type: Boolean,
      default: true,
    },
    hasDontSaveBtn: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      default: 600,
    },
    action: {
      type: [String, Number],
      default: 'Save',
    },
    actionDisabled: {
      type: Boolean,
      default: false,
    },
    actionName: {
      type: String,
      default: 'Save',
    },
    actionIcon: {
      type: String,
      default: '',
    },
    actionFaIcon: {
      type: String,
      default: '',
    },
    closeActionName: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'success',
    },
    data: {
      type: [String, Boolean, Number, Object, Array],
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isOpen: false,
      value: '',
    };
  },
  watch: {
    isOpen(v) {
      if (v) {
        window.addEventListener('keyup', this.escHandler);
      } else {
        window.removeEventListener('keyup', this.escHandler);
      }
    },
  },
  created() {

  },
  methods: {
    open() {
      this.isOpen = true;
      this.$emit('open');
    },
    close(act) {
      this.isOpen = false;
      this.$emit('close', act);
    },
    save() {
      this.$emit('save', this.action, this.data);
    },
    dontSave() {
      this.$emit('dontSave', this.action, this.data);
    },
    reset() {
      this.$emit('reset');
    },
    cancel() {
      this.$emit('cancel');
    },
    escHandler(e) {
      if (e.key === 'Escape' && this.isOpen) {
        this.close('esc');
      }
    },
  },
};
</script>

<style lang="scss">
.ui-confirm{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9000;
  &.fade-enter-active, &.fade-leave-active{
    transition: all .3s;
    .wrapper{
      transition: all .3s;
    }
  }
  &.fade-enter, &.fade-leave-to{
    opacity: 0;
    .wrapper{
      transform: translateY(20px);
    }
  }

  .wrapper{
    display: flex;
    flex-direction: column;
    z-index: 902;
    top: 8vh;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;
    overflow: hidden;
    .head{
      width: initial;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 18px 24px 17px;
      margin-bottom: 0;
      border-bottom: 1px solid #eaeaea;
      .title{
        font-size: 18px;
        line-height: 19px;
        font-weight: 500;
        color: #303634;
      }
      .close{
        color: #303634;
        cursor: pointer;
        padding: 5px;
      }
    }
    .body{
      padding: 16px 24px;
      &__wrap{
        display: flex;
        align-items: center;
        .fas{
          font-size: 24px;
          padding-right: 12px;
        }
      }
      &__msg{
        font-size: 14px;
        line-height: 24px;
        &--padding{
          padding-top: 16px;
          padding-bottom: 16px;
        }
        > span {
          font-weight: 500;
          white-space: nowrap;
        }
        > b {
          font-weight: 500;
        }
      }
    }
    .controls{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 24px;
      border-top: 1px solid #eaeaea;
      &__right{
        display: flex;
      }
      .btn{
        font-size: 14px;
        font-weight: bold;
        text-transform: capitalize;
        .close-icon{
          font-size: 10px !important;
        }
        .ok-icon{
          font-size: 10px !important;
        }
        & + .btn{
          margin-left: 8px;
        }
      }
    }
  }
  .overlay{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
  }
}
</style>
