export default {
  getTempPanelToken(id) {
    return this.affiliatesApi.get(`/affiliates/${id}/temporary-access-token`);
  },
  getOldPanelLogin(id) {
    return this.affiliatesApi.get(`/affiliates/${id}/old-panel-login-url`);
  },

  // list
  getAffiliates(params) {
    return this.affiliatesApi.get('/affiliates', { params });
  },
  getAffiliatesWithStats(params) {
    return this.affiliatesApi.get('/affiliates/list-with-stats', { params });
  },
  getAffiliatesNotifications(params) {
    return this.affiliatesApi.get('/affiliate-notifications', { params });
  },
  getAffiliateNotificationsById(id, params) {
    return this.affiliatesApi.get(`/affiliate-notifications/${id}/affiliates`, { params });
  },
  postAffiliatesNotifications(payload) {
    return this.affiliatesApi.post('/affiliate-notifications', payload);
  },

  // info
  getAffiliateInfo(id) {
    return this.affiliatesApi.get(`/affiliates/${id}`);
  },
  putAffiliateInfo(id, payload) {
    return this.affiliatesApi.put(`/affiliates/${id}`, payload);
  },

  getAffiliateWallets(id) {
    return this.affiliatesApi.get(`/wallets?affiliate_id=${id}`);
  },
  getAffiliateWalletsAvailableToSwitch(id) {
    return this.affiliatesApi.get(`/wallets/switch/available-currencies?affiliate_id=${id}`);
  },
  postAffiliateSwitchWallet({ affiliate_id, currency_code }) {
    return this.affiliatesApi.post('/wallets/switch', { affiliate_id, currency_code });
  },
  getAffiliateWalletHistory(id, params) {
    return this.affiliatesApi.get(`/wallets/${id}/history`, { params });
  },
  getAffiliateWalletHistoryCount(id, params) {
    return this.affiliatesApi.get(`/wallets/${id}/history/count`, { params });
  },
  changeBalance(id, payload) {
    return this.affiliatesApi.post(`/wallets/${id}/change-balance`, payload);
  },
  resetAffiliatePassword(id, pwd) {
    return this.affiliatesApi.post(`/affiliates/${id}/update-password`, {
      new_password: pwd,
    });
  },
  updateAffiliateReferral(id, payload) {
    return this.affiliatesApi.post(`/affiliates/${id}/update-referral-program`, payload);
  },
  updateAffiliateReferralMass(id, payload) {
    return this.affiliatesApi.post(`/affiliates/${id}/mass-attach-sub-affiliates`, {
      referral_id: payload,
    });
  },
  getAffiliateMassAttach(id, params) {
    return this.affiliatesApi.get(`/affiliates/${id}/mass-attach-sub-affiliates/available-sub-affiliates`, { params });
  },
  updateAffiliateLimits(id, payload) {
    return this.affiliatesApi.post(`/affiliates/${id}/update-limits`, payload);
  },
  updateAffiliateCommissions(id, payload) {
    return this.affiliatesApi.post(`/affiliates/${id}/update-commissions`, payload);
  },
  updateAffiliatePayment(id, payload) {
    return this.affiliatesApi.post(`/affiliates/${id}/update-payment-method`, payload);
  },
  updateAffiliateNotificationsSettings(id, payload) {
    return this.affiliatesApi.post(`/affiliates/${id}/update-domain-change-notifications-settings`, payload);
  },
  getAffiliateActivity(params) {
    return this.affiliatesApi.get('/activity-log', { params });
  },
  getAffiliateActivityCount(params) {
    return this.affiliatesApi.get('/activity-log/count', { params });
  },
  updateAffiliatReportingOptions(id, payload) {
    return this.affiliatesApi.post(`/affiliates/${id}/update-reporting-settings`, payload);
  },
  updateAffiliatMediaBuyingOptions(id, payload) {
    return this.affiliatesApi.post(`/affiliates/${id}/update-media-buying-settings`, payload);
  },
  getAffiliateMultiaccounts(id) {
    return this.affiliatesApi.get(`/affiliates/${id}/multiaccounts`);
  },

  // affiliate status
  approveAffiliate(id, payload) {
    return this.affiliatesApi.post(`/affiliates/${id}/approve`, payload);
  },
  rejectAffiliate(id, payload) {
    return this.affiliatesApi.post(`/affiliates/${id}/reject?with_notification=${payload}`);
  },
  blockAffiliate(id) {
    return this.affiliatesApi.post(`/affiliates/${id}/block`);
  },
  unblockAffiliate(id) {
    return this.affiliatesApi.post(`/affiliates/${id}/unblock`);
  },
  revokeAffiliate(id) {
    return this.affiliatesApi.post(`/affiliates/${id}/revoke-approve`);
  },
  activateAffiliate(id) {
    return this.affiliatesApi.post(`/affiliates/${id}/manual-email-verification`);
  },
  createPaymentAffiliate(id) { // / new method
    return this.affiliatesApi.post(`/affiliates/${id}/create-payment`);
  },
  resetTotp(id) { // / new method
    return this.affiliatesApi.post(`/affiliates/${id}/reset-totp`);
  },
  postChangeEmail(id, email) { // / new method
    return this.affiliatesApi.post(`/affiliates/${id}/update-email`, {
      email,
    });
  },
  createPayment(id) { // / deprecated
    return this.affiliatesApi.post(`/affiliates/${id}/schedule-payment-creation`);
  },
  getPaymentsReportInfo(id, params) {
    return this.affiliatesApi.get(`/affiliates/${id}/payments-report`, { params });
  },
  getPaymentsReportCpaPlayersInfo(id, params) {
    return this.affiliatesApi.get(`/affiliates/${id}/payments-report/cpa-players`, { params });
  },

  getAffiliateNotes(id) {
    return this.affiliatesApi.get(`/affiliate-notes?affiliate_id=${id}`);
  },
  postAffiliateNote(params) {
    return this.affiliatesApi.post('/affiliate-notes', params);
  },
  putAffiliateNote(id, params) {
    return this.affiliatesApi.put(`/affiliate-notes/${id}`, params);
  },
  deleteAffiliateNote(id) {
    return this.affiliatesApi.delete(`/affiliate-notes/${id}`);
  },

  getRotationGroupsForAffiliate(id) {
    return this.affiliatesApi.get(`/affiliates/${id}/rotation-groups`);
  },

  addRotationGroupsForAffiliate(id, payload) {
    return this.affiliatesApi.post(`/affiliates/${id}/rotation-groups`, payload);
  },

  deleteRotationGroupsForAffiliate(id, groupId) {
    return this.affiliatesApi.delete(`/affiliates/${id}/rotation-groups/${groupId}`);
  },

  setPrimeryRotationGroupsForAffiliate(id, groupId) {
    return this.affiliatesApi.post(
      `/affiliates/${id}/rotation-groups/${groupId}/set-as-primary`,
    );
  },
  updateAffiliateReferralProgram(payload) {
    return this.affiliatesApi.post('/affiliates/rearrange-referral-program', payload);
  },
  updateAffiliateReferralProgramCount(payload) {
    return this.affiliatesApi.post('/affiliates/rearrange-referral-program/count', payload);
  },
  availableAffiliateReferralProgram(body, params) {
    return this.affiliatesApi.post('/affiliates/rearrange-referral-program/available-affiliates', body, { params });
  },

  getAffiliateReferralProgram(id, params) {
    return this.affiliatesApi.get(`affiliates/${id}/update-referral-program/available-affiliates`, { params });
  },
  putAffiliatePublicProfile(id, params) {
    return this.affiliatesApi.put(`/affiliates/${id}/public-profile`, params);
  },
  deleteAffiliatePublicProfile(id) {
    return this.affiliatesApi.delete(`/affiliates/${id}/public-profile`);
  },

  getAffiliateKpiBonuses(params) {
    return this.affiliatesApi.get('/affiliate-kpi-bonuses', { params });
  },
  postAffiliateKpiBonuses(payload) {
    return this.affiliatesApi.post('/affiliate-kpi-bonuses', payload);
  },
  putAffiliateKpiBonuses(id, payload) {
    return this.affiliatesApi.put(`/affiliate-kpi-bonuses/${id}`, payload);
  },
  cancelAffiliateKpiBonuses(id) {
    return this.affiliatesApi.post(`/affiliate-kpi-bonuses/${id}/cancel`);
  },
  getAffiliateKpiBonusTypes() {
    return this.affiliatesApi.get('/affiliate-kpi-bonuses/available-types');
  },
};
