// MediaBuying views
import MediaBuying from '@/views/MediaBuying.vue';
import MediaBuyingReports from '@/views/MediaBuying/MediaBuyingReports.vue';
import MediaBuyingBlacklist from '@/views/MediaBuying/MediaBuyingBlacklist.vue';
import MediaBuyingUnreportedFunds from '@/views/MediaBuying/MediaBuyingUnreportedFunds.vue';
import MediaBuyingList from '@/views/MediaBuying/MediaBuyingList.vue';
import MediaBuyingPostsList from '@/views/MediaBuying/MediaBuyingPostsList.vue';
import MediaBuyingInfo from '@/views/MediaBuying/MediaBuyingInfo.vue';
import MediaBuyingPostInfo from '@/views/MediaBuying/MediaBuyingPostInfo.vue';
import MediaBuyingBlacklistUrls from '@/views/MediaBuying/MediaBuyingBlacklistUrls.vue';

export default [
  {
    path: '/media-buying',
    name: 'media-buying',
    component: MediaBuying,
    meta: {
      requiresAuth: true,
      view: 'media-buying',
    },
    children: [
      {
        path: 'blacklist',
        component: MediaBuyingBlacklist,
        name: 'media_buying_blacklist',
        meta: {
          view: 'blacklist',
          permission: {},
        },
      },
      {
        path: 'blacklist-urls',
        component: MediaBuyingBlacklistUrls,
        name: 'media_buying_blacklist_urls',
        meta: {
          view: 'blacklist_urls',
          permission: {},
        },
      },
      {
        path: 'reports',
        component: MediaBuyingReports,
        name: 'media_buying_reports',
        meta: {
          view: 'reports',
          permission: {
            list: ['media_buying_reports_view'],
          },
        },
      },
      {
        path: 'unreported-funds',
        component: MediaBuyingUnreportedFunds,
        name: 'media_buying_overview',
        meta: {
          view: 'unreported_funds',
          permission: {
            list: ['media_buying_reports_view'],
          },
        },
      },
      {
        path: 'payments-moderation/on-review',
        component: MediaBuyingList,
        name: 'media_buying_payments_moderation',
        meta: {
          view: 'on_review',
        },
      },
      {
        path: 'payments-moderation/for-rework',
        component: MediaBuyingList,
        name: 'media_buying_payments_moderation',
        meta: {
          view: 'for_rework',
        },
      },
      {
        path: 'payments-moderation/approved',
        component: MediaBuyingList,
        name: 'media_buying_payments_moderation',
        meta: {
          view: 'approved',
        },
      },
      {
        path: 'payments-moderation/declined',
        component: MediaBuyingList,
        name: 'media_buying_payments_moderation',
        meta: {
          view: 'declined',
        },
      },
      {
        path: 'payments-payout/not-paid',
        component: MediaBuyingList,
        name: 'media_buying_payments_payout',
        meta: {
          view: 'not_paid',
        },
      },
      {
        path: 'payments-payout/for-rework',
        component: MediaBuyingList,
        name: 'media_buying_payments_payout',
        meta: {
          view: 'for_rework',
        },
      },
      {
        path: 'payments-payout/processing',
        component: MediaBuyingList,
        name: 'media_buying_payments_payout',
        meta: {
          view: 'processing',
        },
      },
      {
        path: 'payments-payout/paid',
        component: MediaBuyingList,
        name: 'media_buying_payments_payout',
        meta: {
          view: 'paid',
        },
      },
      {
        path: 'payments-payout/failed',
        component: MediaBuyingList,
        name: 'media_buying_payments_payout',
        meta: {
          view: 'failed',
        },
      },
      {
        path: 'payments/:id',
        component: MediaBuyingInfo,
        name: 'media-buying-info',
      },
      {
        path: 'posts/on-review',
        component: MediaBuyingPostsList,
        name: 'media_buying_posts',
        meta: {
          view: 'on_review',
        },
      },
      {
        path: 'posts/for-rework',
        component: MediaBuyingPostsList,
        name: 'media_buying_posts',
        meta: {
          view: 'for_rework',
        },
      },
      {
        path: 'posts/approved',
        component: MediaBuyingPostsList,
        name: 'media_buying_posts',
        meta: {
          view: 'approved',
        },
      },
      {
        path: 'posts/declined',
        component: MediaBuyingPostsList,
        name: 'media_buying_posts',
        meta: {
          view: 'declined',
        },
      },
      {
        path: 'posts/:id',
        component: MediaBuyingPostInfo,
        name: 'media-buying-post-info',
      },
      {
        path: '',
        redirect: 'payments-moderation/on-review',
      },
      {
        path: '*',
        redirect: 'payments-moderation/on-review',
      },
    ],
  },
];
