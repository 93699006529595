import store from '@/store';
import { config } from '@/config';
import { MISC_GET_SETTINGS } from '@/store/action_types/misc';

export default async function fetchMiscSettings() {
  const res = await fetch(`${config.apiBaseUrl}/misc/settings`, {
    headers: {
      'X-Customer-Id': config.customerId,
    },
  });
  const result = await res.json();

  store.commit(`misc/${MISC_GET_SETTINGS}`, result.payload);

  return result;
}
