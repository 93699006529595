<template>
  <CodeInput
    ref="code"
    class="tfa-input"
    :loading="isLoading"
    :field-width="fieldWidth"
    :field-height="fieldHeight"
    @complete="handleComplete"
  />
</template>

<script>
import CodeInput from 'vue-verification-code-input';

const CODE_LENGTH = 6;

export default {
  name: 'UiCodeInput',
  components: {
    CodeInput,
  },
  props: {
    fieldWidth: {
      type: Number,
      default: 55,
    },
    fieldHeight: {
      type: Number,
      default: 55,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    window.addEventListener('paste', this.handlePaste);

    if (this.autoFocus) {
      this.$refs.code.$refs.input_0[0].focus();
    }
  },

  beforeDestroy() {
    window.removeEventListener('paste', this.handlePaste);
  },

  methods: {
    handleComplete(val) {
      this.$emit('complete', val);
    },
    handlePaste(event) {
      this.$refs.code.$refs.input_0[0].focus();

      const pasteText = (event.clipboardData || window.clipboardData).getData('text');
      const trimmedText = pasteText.replace(/\D/g, '').slice(0, CODE_LENGTH);

      this.$refs.code.values = new Array(CODE_LENGTH).fill('').map((_, i) => trimmedText[i]);
    },
  },
};
</script>
