<template>
  <div class="ui-filter popover-filter">
    <ui-button
      ref="mainBtn"
      :filled="!!filtersOpen || hasFilters"
      icon="filter"
      lib="fa"
      substyle="fas"
      color="green"
      class="main-btn"
      @click="toggleFilters"
    >
      {{ $t('ui.filters.inner.filter') }}
    </ui-button>
    <transition name="fade">
      <div v-show="filtersOpen" ref="popup" class="pop" @keyup.enter.stop>
        <div class="arrow" />
        <div class="filter-row">
          <span class="label">{{ $t('affiliates.affiliateKpiBonuses.popup.affiliate') }}</span>
          <el-select
            ref="affiliateFilter"
            v-model="currentFilters.affiliate"
            value-key="id"
            :placeholder="$t('affiliates.affiliateKpiBonuses.popup.affiliate')"
            class="select"
            :remote-method="searchMethod"
            remote
            :loading="loading"
            filterable
            clearable
            @visible-change="handleToggleSelect"
          >
            <el-option
              v-for="item in affiliateOptions"
              :key="item.id"
              :label="item.email"
              :value="item"
            />
          </el-select>
        </div>

        <div class="filter-row">
          <span class="label">{{ $t('affiliates.affiliateKpiBonuses.popup.kpiType') }}</span>
          <el-select
            ref="typeFilter"
            v-model="currentFilters.type"
            :placeholder="$t('affiliates.affiliateKpiBonuses.popup.selectKpiType')"
            class="select"
            filterable
            clearable
          >
            <el-option
              v-for="item in kpiTypeOptions"
              :key="item.key"
              :label="item.text"
              :value="item.key"
            />
          </el-select>
        </div>
        <div class="controls">
          <ui-button
            color="red"
            lib="fa"
            substyle="fas"
            icon="times"
            class="btn"
            @click="resetFilters"
          >
            {{ $t('ui.table.reset') }}
          </ui-button>
          <ui-button
            filled
            :disabled="isDisabledGroup"
            lib="fa"
            substyle="fas"
            color="green"
            icon="check"
            class="btn"
            @click="applyFilters"
          >
            {{ $t('ui.table.apply') }}
          </ui-button>
        </div>
      </div>
    </transition>
    <span v-if="!$_.isEmpty(value)" class="reset_link" @click="resetFilters">
      {{ $t('ui.filters.inner.reset_filter') }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import hash from 'object-hash';
import errorHandleMixin from '@/views/mixins/error-hadle';

const filterFn = v => v !== undefined && v !== '' && v !== null;

export default {
  name: 'AffiliatesFilter',
  mixins: [
    errorHandleMixin,
  ],
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      currentFilters: {},
      affiliateOptions: [],
      filtersOpen: false,
      oldHash: null,
      newHash: null,
      filterValue: '',
    };
  },
  computed: {
    kpiTypeOptions() {
      return this.$store.state.affiliate.kpiBonusTypes.map(el => ({
        key: el.type,
        text: el.title,
      }));
    },
    hasFilters() {
      return !this.$_.isEmpty(this.currentFilters);
    },
    isDisabledGroup() {
      return this.oldHash === this.newHash;
    },
    ...mapGetters('misc', ['countries']),
  },
  watch: {
    currentFilters: {
      deep: true,
      handler(value) {
        const data = {
          ...this.$_.pickBy(value, filterFn),
        };

        this.newHash = hash(data, { algorithm: 'md5' });
      },
    },
  },
  destroyed() {
    document.removeEventListener('click', this.clickOutside, true);
  },
  created() {
    this.currentFilters = this.$_.cloneDeep(this.value);
  },
  mounted() {
    document.addEventListener('click', this.clickOutside, true);
    this.getAffiliates();
  },
  methods: {
    handleToggleSelect(v) {
      if (v) {
        this.loading = true;
        this.getAffiliates('').then(() => {
          this.loading = false;
        });
      }
    },
    searchMethod(v) {
      this.$_.debounce(this.getAffiliates, 800)(v);
    },
    getAffiliates(search) {
      const query = {
        limit: 100,
        offset: 0,
        search,
        account_status: ['approved'],
      };
      return new Promise((resolve, reject) => {
        this.$api.getAffiliates(query)
          .then((response) => {
            this.affiliateOptions = response.data.payload;
            resolve(response.data.payload);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    clickOutside(e) {
      if (this.$refs.mainBtn.$el?.contains(e.target)) return;
      const filters = [
        this.$refs.popup,
        this.$refs.affiliateFilter.$children[1].$el,
        this.$refs.typeFilter.$children[1].$el,
      ].filter(el => !!el);

      if (this.filtersOpen === true && !filters.some(el => e.target && el?.contains(e.target))) {
        this.toggleFilters();
      }
    },
    toggleFilters() {
      this.filtersOpen = !this.filtersOpen;
      if (this.filtersOpen) {
        this.currentFilters = this.$_.cloneDeep(this.value);

        const data = {
          ...this.$_.pickBy(this.value, filterFn),
        };

        this.oldHash = hash(data, { algorithm: 'md5' });
      }


      this.$emit('toggle', this.filtersOpen);
    },
    applyFilters() {
      const { affiliate, ...restParams } = this.currentFilters;
      this.$emit('submit', {
        ...(affiliate && { affiliate_id: affiliate.id }),
        ...restParams,
      });
      this.filtersOpen = false;
    },
    resetFilters() {
      this.currentFilters = {};
      this.$emit('submit', this.currentFilters);
      this.filtersOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-filter {
  position: relative;
  line-height: 0;

  .pop {
    position: absolute;
    width: 476px;
    z-index: 9999;
    top: 44px;
    left: -20px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 16px;
    background-color: #fff;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    user-select: none;

    &.fade-enter-active,
    &.fade-leave-active {
      transition: all 0.4s;
    }

    &.fade-enter,
    &.fade-leave-to {
      opacity: 0;
      transform: translateY(8px);
    }

    .arrow {
      position: absolute;
      top: -5px;
      left: 56px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #fff;
    }
    .filter-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 54px;
      border-bottom: 1px solid #eaeaea;

      > * {
        flex: 1;
      }

      > div {
        justify-content: flex-end;
      }

      .label {
        font-size: 14px;
        font-weight: 500;
        color: #303634;
      }

      .btn {
        margin-left: 0 !important;
      }
    }
    .controls {
      display: inline-flex;
      justify-content: flex-end;
      flex-wrap: nowrap;
      width: auto;
      margin-top: 16px;
      padding: 0;

      .btn {
        margin-left: 0 !important;
      }
      .btn + .btn {
        margin-left: 8px !important;
      }
    }
  }

  .reset_link {
    color: var(--danger-color);
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
    margin-left: 16px;
  }
}
</style>
