<template>
  <div>
    <div class="affiliate-kpi-bonus-popup">
      <div class="popup">
        <div class="head">
          <span class="name">{{ `${$t(`affiliates.affiliateKpiBonuses.popup.${action}_title`)}` }}</span>
          <ui-icon
            :size="19"
            class="close"
            lib="fa"
            substyle="fas"
            name="times"
            @click.native="close"
          />
        </div>
        <div
          v-loading="isDataLoading"
          class="form"
        >
          <div class="row">
            <div class="field">
              <span class="label">{{ $t('affiliates.affiliateKpiBonuses.popup.kpiType') }}</span>
              <el-select
                v-model="kpiType"
                :placeholder="$t('affiliates.affiliateKpiBonuses.popup.selectKpiType')"
                :class="{ error: getError('type') }"
                filterable
                class="select"
              >
                <el-option
                  v-for="(item) in kpiTypeOptions"
                  :key="item.key"
                  :label="item.text"
                  :value="item.key"
                />
              </el-select>
              <div
                v-if="getError(['type'])"
                class="field-error"
              >
                {{ getError('type') }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="field">
              <span class="label">{{ $t('affiliates.affiliateKpiBonuses.popup.setGoal') }}</span>
              <ui-input
                v-model="goal"
                type="number"
                autosize
                :error="errors.goal"
                class="edit-input"
              />
            </div>
          </div>
          <div class="row">
            <div class="field">
              <span class="label">{{ $t('affiliates.affiliateKpiBonuses.popup.setRate') }}</span>
              <el-radio-group
                v-model="rateType"
                class="ui-radio-group ui-m-md-b"
                size="medium"
              >
                <el-radio label="percent">
                  {{ $t('affiliates.affiliateKpiBonuses.popup.percent') }}
                </el-radio>
                <el-radio label="fixed">
                  {{ $t('affiliates.affiliateKpiBonuses.popup.fixed') }}
                </el-radio>
              </el-radio-group>
              <ui-input
                v-if="rateType === 'percent'"
                v-model="bonusPayoutRate"
                type="number"
                autosize
                post-icon="percent"
                :error="errors.bonus_payout_rate"
                class="edit-input"
              />
              <div v-else class="ui-d-flex field-container">
                <ui-input
                  v-model="bonusPayoutSum"
                  type="number"
                  autosize
                  :error="errors.bonus_payout_sum"
                  class="edit-input field-item"
                />
                <div>
                  <el-select
                    v-model="bonusPayoutCurrencyCode"
                    :placeholder="$t('settings.currencies.currency')"
                    :class="{ error: getError('bonus_payout_currency_code') }"
                    class="select field-item"
                    filterable
                  >
                    <el-option
                      v-for="item in customerCurrencies"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    />
                  </el-select>
                  <div
                    v-if="getError(['bonus_payout_currency_code'])"
                    class="field-error"
                  >
                    {{ getError('bonus_payout_currency_code') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="field">
              <span class="label">{{ $t('affiliates.affiliateKpiBonuses.popup.selectPeriod') }}</span>
              <el-select
                v-model="periodValue"
                :placeholder="$t('permissions.offers.not_selected')"
                class="select field-item"
                filterable
              >
                <el-option
                  v-for="item in periodOptions"
                  :key="item.key"
                  :label="item.name"
                  :value="item.key"
                />
              </el-select>
              <div class="ui-d-flex field-container ui-m-md-t">
                <div class="field-item">
                  <el-date-picker
                    v-model="period[0]"
                    :disabled="periodValue !== 'custom'"
                    :placeholder="$t('reports.other.start_date')"
                    :class="{'error': getError('period_start')}"
                    format="dd MMM yyyy"
                    size="small"
                  />
                  <div
                    v-if="getError('period_start')"
                    class="field-error period"
                  >
                    {{ getError('period_start') }}
                  </div>
                </div>
                <span class="ui-m-md-t">&ndash;</span>
                <div class="field-item">
                  <el-date-picker
                    v-model="period[1]"
                    :disabled="periodValue !== 'custom'"
                    :placeholder="$t('reports.other.end_date')"
                    :class="{'error': getError('period_end')}"
                    format="dd MMM yyyy"
                    size="small"
                  />
                  <div
                    v-if="getError('period_end')"
                    class="field-error period"
                  >
                    {{ getError('period_end') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="foot">
          <ui-button
            size="big"
            color="red"
            class="btn"
            lib="fa"
            substyle="fas"
            icon="times"
            @click.native="close"
          >
            {{ $t('crm.buttons.close') }}
          </ui-button>
          <ui-button
            filled
            size="big"
            icon="check"
            lib="fa"
            substyle="fas"
            color="green"
            @click="action === 'add' ? addKpiBonuses() : updateBonus()"
          >
            {{ action === 'add' ? $t('affiliates.affiliateKpiBonuses.popup.add_button') : $t('affiliates.affiliateKpiBonuses.popup.edit_button') }}
          </ui-button>
        </div>
      </div>
      <div class="wrapper" />
    </div>
  </div>
</template>

<script>


import { mapGetters } from 'vuex';
import errorHandleMixin from '@/views/mixins/error-hadle';

export default {
  name: 'AffiliateKpiBonusPopup',
  mixins: [errorHandleMixin],
  props: {
    userId: {
      type: String,
      default() {
        return '';
      },
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isDataLoading: false,
      id: null,
      goal: 0,
      kpiType: '',
      bonusPayoutRate: 0,
      bonusPayoutCurrencyCode: '',
      bonusPayoutSum: 0,
      periodValue: 'custom',
      rateType: 'percent',
      period: [],
      action: 'add',
      errors: {},
    };
  },
  computed: {
    ...mapGetters({
      customerCurrencies: 'misc/customerCurrencies',
    }),
    periodOptions() {
      return [
        {
          key: 'custom',
          name: this.$t('ui.shortcuts.custom_range'),
        },
        {
          key: '7',
          name: this.$t('ui.shortcuts.days_7'),
        },
        {
          key: '14',
          name: this.$t('ui.shortcuts.days_14'),
        },
        {
          key: '30',
          name: this.$t('ui.shortcuts.days_30'),
        },
      ];
    },
    kpiTypeOptions() {
      return this.$store.state.affiliate.kpiBonusTypes.map(el => ({
        key: el.type,
        text: el.title,
      }));
    },
    paramsAttach() {
      return {
        affiliate_id: this.userId || undefined,
        type: this.kpiType,
        goal: this.goal,
        period_start: this.$moment(this.period[0]).format('YYYY-MM-DD'),
        period_end: this.$moment(this.period[1]).format('YYYY-MM-DD'),
        ...this.rateParams,
      };
    },
    rateParams() {
      if (this.rateType === 'percent') {
        return {
          bonus_payout_currency_code: null,
          bonus_payout_sum: null,
          bonus_payout_rate: +this.bonusPayoutRate,
        };
      }

      return {
        bonus_payout_rate: null,
        bonus_payout_currency_code: this.bonusPayoutCurrencyCode,
        bonus_payout_sum: +this.bonusPayoutSum,
      };
    },
  },
  watch: {
    periodValue(val) {
      if (val === 'custom') {
        return;
      }

      const startDate = this.$moment().add(1, 'days');
      const endDate = this.$moment().add(+val, 'days');

      this.period = [startDate, endDate];
    },
    isOpen(e) {
      if (e) {
        this.resetForm();
        if (!this.$_.isEmpty(this.data)) {
          const {
            period_start, period_end, goal, type, bonus_payout_rate, bonus_payout_sum, bonus_payout_currency_code, id,
          } = this.data;

          this.kpiType = type;
          this.goal = goal;
          this.period = [period_start, period_end];
          this.id = id;

          if (bonus_payout_rate) {
            this.bonusPayoutRate = bonus_payout_rate;
            this.rateType = 'percent';
          } else {
            this.bonusPayoutSum = bonus_payout_sum;
            this.bonusPayoutCurrencyCode = bonus_payout_currency_code;
            this.rateType = 'fixed';
          }

          this.action = 'edit';
        } else {
          this.action = 'add';
        }
      }

      this.isDataLoading = false;
    },
  },
  methods: {
    addKpiBonuses() {
      this.$api.postAffiliateKpiBonuses(this.paramsAttach)
        .then(() => {
          this.close('reload');
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },
    updateBonus() {
      this.$api.putAffiliateKpiBonuses(this.data.id, this.paramsAttach)
        .then(() => {
          this.close('reload');
        })
        .catch((error) => {
          this.errors = this.$_.cloneDeepWith(error.data.errors);
        });
    },
    resetForm() {
      this.isDataLoading = true;

      this.id = null;
      this.goal = 0;
      this.kpiType = '';
      this.bonusPayoutRate = 0;
      this.bonusPayoutCurrencyCode = '';
      this.bonusPayoutSum = 0;
      this.periodValue = 'custom';
      this.rateType = 'percent';
      this.period = [];

      this.errors = {};
    },
    close(v, data) {
      this.$emit('close', v, data);
    },
  },
};
</script>

<style lang="scss" scoped>
.affiliate-kpi-bonus-popup {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 900;
  .popup {
    position: absolute;
    z-index: 902;
    left: calc(50% - 240px);
    top: 10%;
    width: 480px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eaeaea;
      padding: 0 24px 16px;
      margin-bottom: 16px;
      .name {
        font-size: 20px;
        font-weight: 500;
      }
      .close {
        color: #303634;
        cursor: pointer;
        padding: 5px;
      }
    }
    .form {
      padding-left: 24px;
      padding-right: 24px;
      .row {
        display: flex;
        width: 100%;
        .ui-input.medium {
          .label-text {
            font-size: 14px;
            font-weight: 500;
            color: #303634;
            margin-bottom: 8px;
          }

          /deep/ {
            input[type='number']::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }

        & + .row {
          margin-top: 16px;
        }
        .field {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex: 10 0 302px;
          width: 100%;

          .label {
            font-size: 14px;
            color: #212529;
            margin-bottom: 8px;
          }
          .select {
            margin: 0;
            width: 100%;
            flex: 0 0 auto;
          }
        }
      }
    }
    .foot {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #eaeaea;
      padding: 16px 24px 0;
      margin-top: 16px;
      .ui-button {
        margin-left: 8px;
      }
    }
  }
  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
  }

  .field-container {
    width: 100%;
    gap: 8px;

    .field-item {
      flex: 1!important;

      /deep/ {
        .el-date-editor {
          width: 100% !important;
        }
      }
    }
  }

  .ui-radio-group {
    /deep/ {
      .el-radio {
        margin-right: 0;
      }

      .el-radio__label {
        font-weight: normal;
      }
    }
  }

  .field-error {
    width: 100%;
    text-align: right;
    margin-top: 4px;
    margin-bottom: 0;
  }

}
</style>
