export default {
  // reports
  getUnreportedFunds(params) {
    return this.affiliatesApi.get('/media-buying/reports/unreported-funds', { params });
  },
  getMediaBuyingReports(params) {
    return this.affiliatesApi.get('/media-buying/reports/summary', { params });
  },
  // Campaign
  getCampaignInfo(id, params) {
    return this.affiliatesApi.get(`/media-buying/campaigns/${id}`, { params });
  },
  // Channel
  // getChannelInfo(id, params) {
  //   return this.affiliatesApi.get(`/media-buying/channels/${id}`, { params });
  // },
  // Payments
  getMediaBuyingPayments(params) {
    return this.affiliatesApi.get('/media-buying/payments', { params });
  },
  getMediaBuyingPaymentsInfo(id, params) {
    return this.affiliatesApi.get(`/media-buying/payments/${id}`, { params });
  },
  getMediaBuyingPaymentComments(id) {
    return this.affiliatesApi.get(`/media-buying/payments/${id}/comments?sort_dir=asc`);
  },
  postMediaBuyingPaymentComments(id, data) {
    return this.affiliatesApi.post(`/media-buying/payments/${id}/comments`, data);
  },
  postMediaBuyingPaymentChangeStatus(id, section, status) {
    return this.affiliatesApi.post(`/media-buying/payments/${id}/${section}/${status}`);
  },
  postMediaBuyingPaymentMarkAsRead(id) {
    return this.affiliatesApi.post(`/media-buying/payments/${id}/mark-as-read`);
  },
  postMediaBuyingPaymentMarkAsUnread(id) {
    return this.affiliatesApi.post(`/media-buying/payments/${id}/mark-as-unread`);
  },
  // Posts
  getMediaBuyingPosts(params) {
    return this.affiliatesApi.get('/media-buying/posts', { params });
  },
  getMediaBuyingPostsInfo(id, params) {
    return this.affiliatesApi.get(`/media-buying/posts/${id}`, { params });
  },
  getMediaBuyingPostsComments(id) {
    return this.affiliatesApi.get(`/media-buying/posts/${id}/comments?sort_dir=asc`);
  },
  postMediaBuyingPostsComments(id, data) {
    return this.affiliatesApi.post(`/media-buying/posts/${id}/comments`, data);
  },
  postMediaBuyingPostsChangeStatus(id, status) {
    return this.affiliatesApi.post(`/media-buying/posts/${id}/${status}`);
  },
  postMediaBuyingPostsMarkAsRead(id) {
    return this.affiliatesApi.post(`/media-buying/posts/${id}/mark-as-read`);
  },
  postMediaBuyingPostsMarkAsUnread(id) {
    return this.affiliatesApi.post(`/media-buying/posts/${id}/mark-as-unread`);
  },
  getMediaBuyingPaymentMethods() {
    return this.affiliatesApi.get('/media-buying/payment-methods');
  },
  getMediaBuyingBlacklist(params) {
    return this.affiliatesApi.get('/media-buying/payment-methods/blacklist', { params });
  },
  getBlacklistById(id) {
    return this.affiliatesApi.get(`/media-buying/payment-methods/${id}/blacklist`);
  },
  postMediaBuyingBlacklist(data) {
    return this.affiliatesApi.post('/media-buying/payment-methods/blacklist', data);
  },
  putMediaBuyingBlacklist(id, data) {
    return this.affiliatesApi.put(`/media-buying/payment-methods/blacklist/${id}`, data);
  },
  deleteMediaBuyingBlacklist(id) {
    return this.affiliatesApi.delete(`/media-buying/payment-methods/blacklist/${id}`);
  },
  getMediaBuyingUsedWalletsById(id) {
    return this.affiliatesApi.get(`/media-buying/campaigns/${id}/used-wallets`);
  },
  getMediaBuyingBlacklistUrls(params) {
    return this.affiliatesApi.get('/media-buying/url-blacklists', { params });
  },
  postMediaBuyingBlacklistUrls(data) {
    return this.affiliatesApi.post('/media-buying/url-blacklists', data);
  },
  putMediaBuyingBlacklistUrls(id, data) {
    return this.affiliatesApi.put(`/media-buying/url-blacklists/${id}`, data);
  },
  deleteMediaBuyingBlacklistUrls(id) {
    return this.affiliatesApi.delete(`/media-buying/url-blacklists/${id}`);
  },
};
