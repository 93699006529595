<template>
  <div
    v-loading="isDataLoading"
    class="affiliate-promo-codes"
  >
    <affiliate-promocode-popup
      v-show="isPopupOpen"
      :is-open="isPopupOpen"
      :user-id="userId"
      :data="promoCode"
      @close="onClosePopup"
    />
    <ui-confirm
      ref="confirm"
      :width="480"
      type="warning"
      :action-name="`${this.$t(`affiliates.affiliatePromocode.popup.delete_button`)}`"
      action="Decline"
      action-fa-icon="trash-alt"
      @save="deletePromoCode"
    >
      <div
        slot="title"
        v-html="confirm.title"
      />
      <div class="body__wrap">
        <i
          class="warning fas fa-exclamation-triangle"
        />
        <div
          class="body__msg"
          v-html="confirm.msg"
        />
      </div>
    </ui-confirm>
    <div class="wrapper">
      <div class="controls">
        <div class="section">
          <span class="title">{{ $t(`affiliates.cardView.nav.promocodes`) }}</span>
          <ui-input
            v-model.trim="searchQuery"
            :width="180"
            is-search
            @search="getPromoCodes"
          />
        </div>
        <div class="section">
          <div class="pagination">
            <ui-pagination
              :page="page"
              :page-size="limit"
              :count="count"
              show-size-select
              @page-change="pageChange"
            />
          </div>
          <ui-button
            v-if="isEditPromoCodes"
            icon="plus"
            lib="fa"
            filled
            substyle="fas"
            color="green"
            class="btn pin-button"
            @click="openPromocodePopup()"
          >
            {{ $t(`affiliates.affiliatePromocode.add_promocode`) }}
          </ui-button>
        </div>
      </div>
      <div class="info">
        <ui-table
          v-loading="isDataLoading"
          :fields="fields"
          :data="promoCodes"
          :rows-count="limit"
          :sort="{
            prop: sortProp,
            order: sortOrder,
          }"
          lazy-loading
          class="table promo-code-table"
          i18n-path="affiliates.promocodes_list"
          element-loading-custom-class="data-loader-spinner"
          @sort="handleSort"
        >
          <template
            slot="append"
          >
            <el-table-column
              v-if="isEditPromoCodes || isViewReports"
              :width="isViewReports && isEditPromoCodes ? 100 : !isViewReports ? 72 : 45"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <div
                  class="row-value actions"
                >
                  <ActionIcon
                    :tooltip="$t('affiliates.affiliatePromocode.statistics_promocode')"
                  >
                    <router-link
                      v-if="isViewReports"
                      class="action-ico"
                      :to="{
                        path: '/reports/custom',
                        query: { promo_stats: $qs.stringify(scope.row, { arrayFormat: 'repeat' }) }
                      }"
                    >
                      <ui-icon
                        name="chart-bar"
                        color="#3b94e3"
                        lib="fa"
                      />
                    </router-link>
                  </ActionIcon>
                  <ActionIcon
                    :tooltip="$t('affiliates.affiliatePromocode.edit_promocode')"
                  >
                    <div
                      v-if="isEditPromoCodes"
                      class="action-ico"
                      @click="openPromocodePopup(scope.row)"
                    >
                      <ui-icon
                        name="pen"
                        :color="$theme.accentColor"
                        lib="fa"
                      />
                    </div>
                  </ActionIcon>
                  <ActionIcon
                    :tooltip="$t('affiliates.affiliatePromocode.delete_promocode')"
                  >
                    <div
                      v-if="isEditPromoCodes"
                      class="action-ico"
                      @click="checkSensitiveAction('handleDeletePromoCode', scope.row)"
                    >
                      <ui-icon
                        name="trash-alt"
                        :color="$theme.dangerColor"
                        lib="fa"
                      />
                    </div>
                  </ActionIcon>
                </div>
              </template>
            </el-table-column>
          </template>
        </ui-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AffiliatePromocodePopup from '@/components/Popups/AffiliatePromocodePopup.vue';
import { pageSizeMixin, resolvePageSize } from '@/service/pageSize';
import updateUrl from '@/service/updateUrl';

const sizes = {
  1080: 20,
  1440: 30,
};

const viewName = 'Affiliates/promocodes/limit'; // для локалсторедж
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});
export default {
  name: 'AffiliatePromoCodes',
  components: {
    AffiliatePromocodePopup,
  },
  mixins: [pageSizeMixin],
  props: {
    permissions: {
      type: Object,
      default: () => ({

      }),
    },
  },
  data() {
    return {
      viewName,
      isPopupOpen: false,
      isDataLoading: false,
      sortProp: 'created_at',
      sortOrder: 'desc',
      searchQuery: '',
      count: 0,
      page: 1,
      limit: pageSize,
      errors: {},
      fields: [
        {
          name: 'promo_code',
          align: 'left',
          className: 'promo-title',
          headerAlign: 'left',
          sortable: 'custom',
        },
        {
          name: 'created_at',
          width: '134',
          align: 'left',
          headerAlign: 'left',
          sortable: 'custom',
          format: 'date-time',
        },
      ],
      promoCodes: [],
      promoCode: {},
      confirm: {
        title: '',
        msg: '',
      },
    };
  },
  computed: {
    isEditPromoCodes() {
      return (this.calcPermissions('edit_promo_codes') && this.currentAcl.affiliates_promo_codes_edit === 'allow') || this.adminAcl.is_superuser;
    },
    isViewReports() {
      const isUser = !this.$store.getters['auth/adminAcl'].is_superuser && this.$store.getters['auth/currentAcl'].reports_view === 'allow';
      const isSuperUser = this.$store.getters['auth/adminAcl'].is_superuser;
      return isSuperUser || isUser;
    },
    userId: {
      get() {
        return this.$route.params.id;
      },
    },

    ...mapGetters({
      currentAcl: 'auth/currentAcl',
      adminAcl: 'auth/adminAcl',
    }),
  },
  watch: {
    sortProp(newVal) {
      this.$ls.set('Affiliates/promocodes/sort_column', newVal || 'created_at');
    },
    sortOrder(newVal) {
      this.$ls.set('Affiliates/promocodes/sort_dir', newVal || 'desc');
    },
  },
  created() {
    const _urlData = updateUrl.getParseParams(this.$route.hash);

    if (!this.$_.isEmpty(_urlData)) {
      this.limit = Number(this.$_.get(_urlData, ['limit'])) || pageSize;
      this.page = Number(this.$_.get(_urlData, ['page'])) || 1;
      this.sortProp = this.$_.get(_urlData, ['sort_column']) || 'created_at';
      this.sortOrder = this.$_.get(_urlData, ['sort_dir']) || 'desc';
      this.searchQuery = this.$_.get(_urlData, ['search']) || null;
    } else {
      this.limit = +this.$ls.get('Affiliates/promocodes/limit') || pageSize;
      this.sortProp = this.$ls.get('Affiliates/promocodes/sort_column') ? this.$ls.get('Affiliates/promocodes/sort_column') : 'created_at';
      this.sortOrder = this.$ls.get('Affiliates/promocodes/sort_dir') ? this.$ls.get('Affiliates/promocodes/sort_dir') : 'desc';
    }
    if (!this.userId) {
      this.$router.push('/affiliates/list');
    }

    this.$eventBus.$on('handleDeletePromoCode', this.handleDeletePromoCode);
  },
  beforeDestroy() {
    this.$eventBus.$off('handleDeletePromoCode', this.handleDeletePromoCode);
  },
  mounted() {
    this.getPromoCodes();
  },
  methods: {
    _query() {
      return {
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
        search: this.searchQuery,
        limit: this.limit,
        offset: (this.limit * this.page) - this.limit,
        affiliate_id: this.userId,
      };
    },
    calcPermissions(item) {
      return this.permissions[item];
    },

    checkSensitiveAction(action, row) {
      this.$eventBus.$emit('checkSensitiveAction', action, row);
    },

    handleDeletePromoCode(row) {
      this.promoCode = row;
      this.confirm.title = this.$t('affiliates.affiliatePromocode.popup.delete_title');
      this.confirm.msg = this.$t('affiliates.affiliatePromocode.popup.delete_text', { name: this.promoCode.promo_code });
      this.$refs.confirm.open();
    },
    deletePromoCode() {
      this.isDataLoading = true;
      this.$api.deleteAffiliatePromoCodes(this.promoCode.id)
        .then(() => {
          this.getPromoCodes();
          this.$refs.confirm.close();
        })
        .catch((error) => {
          this.isDataLoading = false;
          this.$noty.error(`${this.$t('payments.noty.req_fail', { statusCode: error.status })}`);
        });
    },
    openPromocodePopup(row) {
      this.isPopupOpen = true;
      this.promoCode = row;
    },
    onClosePopup(v) {
      this.isPopupOpen = false;
      if (v === 'reload') {
        this.getPromoCodes('onClosePopup');
      }
    },
    getPromoCodes() {
      this.errors = {};
      this._completedFilterUrl();
      const _filter = this._query();
      this.$api
        .getAffiliatePromoCodes(_filter)
        .then((response) => {
          this.promoCodes = this.$_.cloneDeep(response.data.payload);
          this.count = this.$_.cloneDeep(response.data.misc.count);
        })
        .finally(() => {
          this.isDataLoading = false;
        });
    },
    _completedFilterUrl() {
      const _dataFilters = {
        limit: this.limit,
        page: this.page,
        sort_column: this.sortProp,
        sort_dir: this.sortOrder,
        search: this.searchQuery,
        affiliate_id: this.userId,
      };
      updateUrl.updateGetParams(_dataFilters);
    },
    handleSort({ prop, order }) {
      if (this.sortProp !== prop) {
        this.sortProp = prop;
      } else {
        this.sortOrder = order;
      }
      this.$nextTick(() => {
        this.getPromoCodes('handleSort');
      });
    },
    pageChange(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.page = page;
        this.limit = size;
        this.getPromoCodes('pageChange');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .affiliate-promo-codes {
    .wrapper {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      min-width: 1140px;
      width: 90%;
    }
  }
</style>
